/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import ReactMarkdown from 'react-markdown';
import {
    func, number, object, string, shape,
} from 'prop-types';
import { connect } from 'react-redux';
import { GraphqlSimpleProduct } from '../../../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';
import { getActiveABTests } from '../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const SubCollectionHeader = ({
    classes, subNavHeaderBgColor, subNavHeaderBorderColor, subNavHeaderFontColor, subNavProductCollection, subNavHeaderCopy, subNavHeaderPosition, subNavImgInCollection, numOfProducts, productPerRow, activeABTests,
}) => {
    const spotNumber = subNavHeaderPosition !== null && subNavHeaderPosition.toLowerCase().indexOf('spot') !== -1 ? Number(subNavHeaderPosition.split(' ').join()[5]) : null;
    const subCollectionHeaderOptimization =  activeABTests?.sub_collection_header_optimization  === 'VARIANT';
    const subNavStyle = {
        margin: 0,
        backgroundColor: subNavHeaderBgColor || '#F1F0F2',
        border: `1px solid ${subNavHeaderBorderColor || '#F1F0F2'}`,
    };
    const subNavCollectionStyle = {
        backgroundImage: `url(${subNavImgInCollection?.img}?auto=webp)`,
        backgroundSize: '85%',
        backgroundRepeat: 'no-repeat',
        color: subNavHeaderFontColor || '#000',
        backgroundPosition: 'center',
    };
    return (
        <div className={classes.subNavContainer}>
            {subCollectionHeaderOptimization && (
                <div className={classes.innerSubNavContainer}>
                    {
                        subNavHeaderPosition === 'above products'
                            ? (
                                <div
                                    className={classes.aboveProductHeroCopy}
                                    style={{
                                        color: subNavHeaderFontColor || '#000',
                                        backgroundColor: subNavHeaderBgColor || '#FFF',
                                        border: subNavHeaderBorderColor ? `3px solid ${subNavHeaderBorderColor}` : '3px solid #ececec',
                                    }}
                                >
                                    <ReactMarkdown source={subNavHeaderCopy} />
                                </div>
                            )
                            : null
                    }
                    <div
                        className={classes.subNavProductRow}
                        style={subNavStyle}
                    >
                        {
                            subNavProductCollection?.map((product, i) => {
                                const {
                                    name, image, seo: { url }, skuPriceRange, availability, partNumber, brandId,
                                } = product;
                                return (
                                // TODO: get img from CMS to render here too
                                    (i + 1 === spotNumber || i + 1 === subNavImgInCollection?.imgSpot) ? (
                                        <>
                                            <div
                                                className={`${productPerRow(numOfProducts)} ${classes.subCollectionHeader} ${classes.um0}`}
                                                style={subNavCollectionStyle}
                                            >
                                                {subNavHeaderCopy && spotNumber ? <ReactMarkdown source={subNavHeaderCopy} /> : null}
                                            </div>
                                            <div key={name} className={`${productPerRow(numOfProducts)} ${classes.um0}`}>
                                                <GraphqlSimpleProduct name={name} image={image} url={url} skuPriceRange={skuPriceRange} availability={availability} partNumber={partNumber} showSimpleProductRedesignAbTest={false} productBrand={brandId} />
                                            </div>
                                        </>
                                    )
                                        : (
                                            <div key={name} className={`${productPerRow(numOfProducts)} ${classes.um0}`}>
                                                <GraphqlSimpleProduct name={name} image={image} url={url} skuPriceRange={skuPriceRange} availability={availability} partNumber={partNumber} showSimpleProductRedesignAbTest={false}  productBrand={brandId} />
                                            </div>
                                        )
                                );
                            })
                        }
                    </div>
                </div>
            )}
            {
                subNavHeaderPosition === 'below products'
                    ? (
                        <div className={classes.belowProductHeroCopy} style={{ color: subNavHeaderFontColor || '#000' }}>
                            <ReactMarkdown source={subNavHeaderCopy} />
                        </div>
                    )
                    : null
            }
        </div>
    );
};

SubCollectionHeader.propTypes = {
    classes: object.isRequired,
    subNavHeaderPosition: string,
    subNavHeaderBorderColor: string,
    subNavHeaderCopy: string.isRequired,
    subNavHeaderFontColor: string.isRequired,
    subNavHeaderBgColor: string.isRequired,
    subNavProductCollection: object.isRequired,
    subNavImgInCollection: object,
    numOfProducts: number.isRequired,
    productPerRow: func.isRequired,
    activeABTests: shape({
        sub_collection_header_optimization: string,
    }).isRequired,
};

SubCollectionHeader.defaultProps = {
    subNavHeaderBorderColor: '',
    subNavHeaderPosition: '',
    subNavImgInCollection: {},
};
const mapStateToProps = (state) => ({
    activeABTests: getActiveABTests(state),
});

export default connect(mapStateToProps, null)(SubCollectionHeader);
