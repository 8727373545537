/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React,  { useState }  from 'react';
import {
    shape, string, func, bool,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import Grid from '@material-ui/core/Grid';
import { useUIDSeed } from 'react-uid';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles((theme) => ({
    headerBlock: ({ isMotherDayPage }) => ({
        top: isMotherDayPage ? '35%' : '50%',
        right: isMotherDayPage && '450px !important',
        transform: 'translateY(-50%)',
        position: 'absolute',
        textAlign: 'center',
        padding: '0px 60px',
        whiteSpace: 'break-spaces',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
    }),
    imocBanner: {
        position: 'relative',
        display: 'block',
    },
    heroMainImage: {
        width: '100%',
        display: 'block',
        [theme.breakpoints.down('md')]: {
            minHeight: 300,
        },
    },
    heading: ({ data }) => ({
        fontWeight: 700,
        lineHeight: '1.2',
        '& h3, h2': {
            overflow: 'hidden',
            '&::before': {
                width: '5%',
                right: '0.5em',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                marginLeft: '-50%',
                verticalAlign: 'middle',
                backgroundColor: theme.palette.cms?.subHeadercolor || '#A35497',
            },
            '&::after': {
                left: '.5em',
                width: '5%',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                marginRight: '-50%',
                verticalAlign: 'middle',
                backgroundColor: theme.palette.cms?.subHeadercolor || '#A35497',
            },
        },
        '& h2': {
            margin: 0,
            fontSize: 24,
            color: theme.palette.cms?.subHeadercolor || '#A35497',
        },
        '& h1': {
            margin: '0px',
            fontSize: '2.5em',
            lineHeight: '',
            color: data?.heading_font_color?.color || '#ffffff',
            '& strong': {
                color: theme.palette.cms?.subHeadercolor  || '#fe948c',
            },
        },
        '& p': {
            fontSize: '24px',
            margin: '7px 0 ',
        },
        [theme.breakpoints.down('md')]: {
            '& h1': {
                fontSize: 24,
            },
            '& h2': {
                fontSize: 18,
            },
        },

    }),
    subHeading: {
        '& p': {
            fontSize: '15px',
            margin: '10px auto',
            lineHeight: '1.4',
            [theme.breakpoints.down(1024)]: {
                width: '100%',
            },
        },
        '& h2': {
            margin: 0,
            overflow: 'hidden',
            fontSize: 16,
            '&::before': {
                width: '15%',
                right: '0.5em',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                marginLeft: '-50%',
                verticalAlign: 'middle',
                backgroundColor: theme.palette.cms?.black || '#000000',
            },
            '&::after': {
                left: '.5em',
                width: '15%',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                marginRight: '-50%',
                verticalAlign: 'middle',
                backgroundColor: theme.palette.cms?.black  || '#000000',
            },
        },
        [theme.breakpoints.down(1024)]: {
            left: 0,
        },
    },
    shopButton: {
        padding: '15px 35px',
        display: 'inline-block',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: '1.2px',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    listBlock: ({ listBorderColor }) => ({
        '& a': {
            textDecoration: 'none',
            display: 'block',
        },
        '& li': {
            listStyle: 'none',
            borderTop: `1px solid ${listBorderColor}`,
            textAlign: 'center',
            padding: '4px 0px',
            fontWeight: '600',
            fontSize: '16px',
        },
    }),
    mainHeroBanner: {
        position: 'relative',
    },
    mainListBlock: ({ isMotherDayPage }) => ({
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: isMotherDayPage ? 'auto' : 100,
        right: isMotherDayPage ? 160 : '',
        background: isMotherDayPage && (theme.palette.cms?.listBgColor ||  '#FFEBEA'),
        padding: isMotherDayPage && '15px 30px 10px 0px',
        [theme.breakpoints.down('md')]: {
            left: 50,
        },
        [theme.breakpoints.down(1024)]: {
            left: 0,
        },
    }),
}));

// List Block
const ListData = (classes, list, fontFamily, setHoverColor, isHoverColor) => {
    const seed = useUIDSeed();
    return (
        list?.link.length > 0 && (
            <div className={classes.listBlock} aria-hidden="true" tabIndex="-1">
                <ul>
                    {list?.link?.map((item, index) => {
                        const listFontStyle = {
                            fontFamily: fontFamily(list?.font_family) || '',
                            color: index ===  isHoverColor ? list?.font_color_hover?.color : list?.font_color?.color || '',
                        };
                        return (
                            <li
                                onFocus={() => setHoverColor(index)}
                                onBlur={() => setHoverColor('')}
                                onMouseOver={() => setHoverColor(index)}
                                onMouseLeave={() => setHoverColor('')}
                                key={seed(item)}
                            >
                                <LinkOrATag className="" indexKey={seed(item)} style={listFontStyle} href={item?.href} ariaLabel={item?.title}>
                                    {item?.title}
                                </LinkOrATag>
                            </li>
                        );
                    })}
                </ul>
            </div>
        )

    );
};

// Hero Banner Grid
const heroBlock = (classes, position, href, title, url, header, sub_header, button_copy, buttonStyleOverridess, headerStyle, subHeaderStyle, borderStyle, description) => (
    <Grid item xs={12} className={classes.imocBanner}>
        {href ?  (
            <LinkOrATag href={href}>
                <img src={`${url}?auto=webp`} alt={description || 'Desktop Hero Banner'} className={classes.heroMainImage} />
            </LinkOrATag>
        )
            : <img src={url} alt={description || 'Desktop Hero Banner'} className={classes.heroMainImage} />}
        <div className={classes.headerBlock} style={position}>
            <div style={borderStyle}>
                <div style={headerStyle}> <ReactMarkdown source={header} className={classes.heading} escapeHtml={false} /></div>
                <div style={subHeaderStyle}><ReactMarkdown source={sub_header} className={classes.subHeading} escapeHtml={false} /></div>
                { button_copy &&  <div className={classes.shopButton} style={buttonStyleOverridess}><LinkOrATag href={href}>{button_copy}</LinkOrATag></div>}
            </div>
        </div>
    </Grid>
);

const DesktopHero = ({ data, fontFamily, isMotherDayPage }) => {
    const position = {};
    if (data) {
        const  buttonPosition  = data?.heading_alignment;
        if (buttonPosition === 'left') {
            position.left = 0;
        }
        if (buttonPosition === 'right' || buttonPosition === null) { // Set default right
            position.right = 0;
        }
        if (buttonPosition === 'center') {
            position.right = 0;
            position.left = 0;
        }
    }
    const [isHoverColor, setHoverColor] = useState('');

    // CMS style override
    const styleOverride = (backgroundColor = '', color = '', fonts = '', borderInPx) => ({
        backgroundColor: backgroundColor || '',
        color: color || '',
        fontFamily: fonts || '',
        border: `${borderInPx}px solid #F1B858` || '',
    });

    const buttonStyleOverrides = styleOverride(data?.button_background_color,  data?.button_font_color);
    const headerStyle = styleOverride('',  '',  fontFamily(data?.heading_font_family));
    const subHeaderStyle = styleOverride('',  data?.sub_heading_font_color?.color,  fontFamily(data?.sub_heading_font_family));
    const borderStyle =  styleOverride('',  '',  '', data?.border_width);
    const linkList = data?.link_list;
    const listBorderColor = linkList?.font_color?.color || '';
    const heroStyle = { listBorderColor, data, isMotherDayPage };
    const classes = useStyles(heroStyle);
    return (
        <div className={classes.mainHeroBanner}>
            <div className={classes.mainImageBanner}>
                { heroBlock(classes, position, data?.link?.href, data?.link?.title, data?.image?.url, data?.header, data?.sub_header, data?.button_copy, buttonStyleOverrides, headerStyle, subHeaderStyle, borderStyle, data?.image?.description)}
            </div>
            {linkList?.link?.length > 0 && (
                <div className={classes.mainListBlock}>
                    {ListData(classes, linkList, fontFamily, setHoverColor, isHoverColor)}
                </div>
            )}
        </div>
    );
};

DesktopHero.propTypes = {
    data: shape({
        header: string.isRequired,
        sub_header: string.isRequired,
        image: shape({
            url: string.isRequired,
        }),
        link: shape({
            href: string.isRequired,
        }.isRequired),
    }).isRequired,
    fontFamily: func.isRequired,
    isMotherDayPage: bool.isRequired,
};

export default DesktopHero;
