/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';

const styles = (theme) => ({
    imageContainer: {
        width: '100%',
        display: 'flex',
        [theme.breakpoints.down('600')]: {
            display: 'block',
        },
        '& img': {
            width: '100%',
        },

    },
});

const ImageSidebanner = ({ classes, bannerData }) => (
    <DesktopMobile
        mobile={() => <div className={classes.imageContainer}> {bannerData?.mobile_image?.url && <img src={bannerData.mobile_image.url} alt={bannerData.mobile_image.description || 'Deal Of The Week Flowers Image'} />}</div>}
        desktop={() => (
            <div className={classes.imageContainer}>
                {bannerData?.desktop_image?.url && <img src={bannerData.desktop_image.url} alt={bannerData.desktop_image.description || 'Deal Of The Week Flowers Image'} />}
            </div>

        )}
        breakpoint={600}
        ignoreHover
    />
);

ImageSidebanner.propTypes = {
    classes: object.isRequired,
    bannerData: shape({
        mobile_image: shape({
            url: string.isRequired,
            title: string.isRequired,
        }),
        desktop_image: shape({
            url: string.isRequired,
            title: string.isRequired,
        }),
    }).isRequired,
};

export default (withStyles(styles)(ImageSidebanner));
