/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';

import { Grid, withStyles } from '@material-ui/core';

import {
    object, shape, arrayOf, string, func,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GraphqlGuidedNav from '../../../../GraphqlCategoryPage/Partials/GraphqlLeftMenuContainer/Partials/GraphqlGuidedNav/GuidedNav';
import GraphqlCategoryRelatedBox from  '../../../../GraphqlCategoryPage/Partials/GraphqlLeftMenuContainer/Partials/GraphqlCategoryRelatedBox';
import GraphqlSideRailBanner from  '../../../../GraphqlCategoryPage/Partials/GraphqlLeftMenuContainer/Partials/GraphqlSideRailBanner';
import { trackEvent as track } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    leftMenuComponent: {
        display: 'none',
        [theme.breakpoints.up(769)]: {
            display: 'block',
            width: '100%',
            marginBottom: '15px',
        },
    },
    gnavLeftMenuContainer: {
        width: '100%',
        marginBottom: '15px',
    },
});

const SubNavLeftMenuContainer = ({
    classes, LeftMenuData, guidedNavLocationOverride, guidedNavFormData, trackEvent,
}) => {
    const subNavigation = (block) => {
        if (!block) {
            return null;
        }
        const leftMenuKey = Object.keys(block);
        const blockKey = leftMenuKey?.[0];
        const blockObj = block[blockKey];
        const seed = useUIDSeed();
        const subNavCategoryRelatedBoxBlock = (
            <Grid
                className={classes.leftMenuComponent}
                key={seed(blockObj)}
            >
                <GraphqlCategoryRelatedBox relatedData={blockObj} trackEvent={trackEvent} />
            </Grid>
        );
        switch (blockKey) {
            case 'related_searches':
                return (
                    subNavCategoryRelatedBoxBlock
                );
            case 'related_articles':
                return (
                    subNavCategoryRelatedBoxBlock
                );
            case 'side_rail_banner':
                return (
                    <Grid
                        className={classes.leftMenuComponent}
                        key={seed(blockObj)}
                    >
                        <GraphqlSideRailBanner bannerData={blockObj} />
                    </Grid>
                );
            default:
                return null;
        }
    };

    return (
        <Grid container direction="column" className={classes.leftMenuOuterGrid}>
            {guidedNavFormData &&   (
                <Grid className={classes.gnavLeftMenuContainer}>
                    <GraphqlGuidedNav
                        guidedNavFormData={guidedNavFormData}
                        guidedNavLocationOverride={guidedNavLocationOverride}
                    />
                </Grid>
            )            }

            {typeof LeftMenuData.page_blocks !== 'undefined' ? LeftMenuData.page_blocks.map((block) => subNavigation(block)) : null}
        </Grid>
    );
};

SubNavLeftMenuContainer.propTypes = {
    classes: object.isRequired,
    LeftMenuData: shape({
        page_blocks: arrayOf(shape({
            side_rail_banner: (shape({
                href: string,
                url: string,
            }).isRequired),
            related_articles: (shape({
                title: string,
                href: string,
            }).isRequired),
            related_searches: (shape({
                title: string,
                href: string,
            }).isRequired),
        }).isRequired),
    }).isRequired,
    guidedNavLocationOverride: object.isRequired,
    guidedNavFormData: shape({
        address_field_label: string,
        form_label: string,
        submit_button_label: string,
        location_type_label: string,
    }).isRequired,
    trackEvent: func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});
export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(SubNavLeftMenuContainer));
