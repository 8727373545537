/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { object } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

// Render Wyng content on the page
//
// Wyng is used to inject social media content into a page.
// It can manage signup forms for events and social-sourced images.
//
// passing the wyng_id and wyng_token is all that is needed.
// the script that injects the Wyng content resides in Tealium
//
// Floral brands handle the script from cms NOT Tealium

const useStyles = makeStyles(() => ({
    wyngMainContainer: {
        width: '100%',
        margin: '0 auto',
        '& .slider': {
            height: 'auto !important',
        },
    },

}));
const Wyng = ({ data: blockData }) => {
    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
    });
    const classes = useStyles();
    /*
        We have been experiencing issues on direct load where the wyng content does not load on direct load.
        This appears to be caused by some order of operations problem or possibly a dependency problem within the wyng script.
        Delaying render until the client has been the best solution we have found thus far to this problem.
    */
    if (typeof window === 'undefined') {
        return null;
    }

    if (loading) {
        return (
            <DesktopMobile
                desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
            />
        );
    }
    const dispatch = useDispatch();

    useEffect(() => {
        if (typeof document !== 'undefined' && document.getElementById('wyng-experience')) {
            const track =   data?.reference?.[0]?.tracking_event;
            const rootElement = document.getElementById('wyng-experience');
            rootElement.addEventListener('click', (event) =>  {
                const targetElement = event.target;
                const selector = 'button';
                if (targetElement?.matches(selector) && typeof window !== 'undefined') {
                    window.wyng[data?.reference[0].wyng_id].addEventListener('form_submit_success', () => {
                        dispatch(trackEvent(
                            {
                                eventCategory: track?.tracking_event_category || '',
                                eventAction: track?.tracking_event_action || '',
                                eventLabel: track?.tracking_event_label || '',
                                eventName: 'sign_up_form_submit',
                            },
                        ));
                    });
                }
            }, true);
        }
        return () => document.querySelectorAll('script[class="wyng-script"]')?.[0]?.remove();
    }, []);

    if (data?.reference?.[0]?.wyng_id && data?.reference?.[0]?.wyng_token) {
        return (
            <>
                {data?.reference?.[0]?.heading && <h1>{data.reference[0].heading}</h1>}
                <div className={classes.wyngMainContainer} id="wyng-experience" data-wyng-id={data.reference[0].wyng_id} data-wyng-token={data.reference[0].wyng_token} />
                <Helmet>
                    <script src={
                        // eslint-disable-next-line no-nested-ternary
                        data?.wyng_script_source ? data.wyng_script_source : data?.reference?.[0]?.wyng_script_source ? data.reference[0].wyng_script_source : null
                    }
                    />
                </Helmet>

            </>
        );
    }
    mbpLogger.logDebug({
        appName: process.env.npm_package_name,
        message: `Wyng module called but did not pass valid data: ${data}`,
    });
    return <p> This Wyng content ({data?.reference?.[0]?.title}) cannot be loaded. Please double-check the id and token values. </p>;
};

Wyng.propTypes = {
    data: object.isRequired,
};

export default Wyng;
