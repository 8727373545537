/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const booleanGetCutoffTimes = () => {
    const today = new Date();
    const currTime = `${today.getHours()}${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}`;
    const dayOfWeek = today.getDay();

    // saturday 1:00PM || sunday 11:30AM || monday-friday 2:30PM
    if ((dayOfWeek === 6 && currTime > 1300) || (dayOfWeek === 0 && currTime > 1130) || currTime > 1430) {
        return true;
    }
    return false;
};

export default booleanGetCutoffTimes;
