/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    object, shape, arrayOf, string, func,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import ProductsFallbackContainer from '../../../../GraphqlCommonComponents/GraphqlProductsFallbackContainer/ProductsFallbackContainer';
import ChooseBlock from './ChooseBlock';
import InfoBlock from './InfoBlock';
import DisclaimerBlock from './DisclaimerBlock';
import SubscriptionBlock from './SubscriptionBlock';
import OurFavoritesBlock from './OurFavoritesBlock';
import TemplateMultiSubMainBanner from './TemplateMultiSubMainBanner';
import WyngModule from '../../../../../ContentTypeComponents/Wyng/Wyng';
import scrollToRef from './scrollToRef';
import { trackEvent as trackEventActions } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import GraphqlImoc from '../../../../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlImoc/GraphqlImoc';
import FaqBlock from '../../../../GraphqlCategoryPage/Partials/FaqBlock/FaqBlock';
import GraphqlMobileCategory from '../../../../GraphqlHomePage/Partials/GraphqlMobileCategory/GraphqlMobileCategory';
import GraphqlBreadcrumbs from '../../../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import GenericBanner from './GenericBanner';
import ProductsList from './ProductsList';

const styles = (theme)  => ({
    subBanner: {
        '& img': {
            width: '100%',
        },
    },
    bottomBannerInner: {
        padding: 10,
        '& img': {
            width: '100%',
        },
    },
    bottomBanner: {
        '& h2': {
            textAlign: 'center',
            width: '100%',
            fontSize: 26,
            fontFamily: 'PlayfairDisplayRegular, serif',
            fontWeight: 'normal',
            [theme.breakpoints.down(600)]: {
                margin: '10px 0 0 0',
                fontSize: '16.8px',
            },
        },

    },
    breadCrumbs: {
        marginLeft: '20px',
        paddingBottom: '8px',
    },
    subCollection: {
        '& h2, & h4, & h5, & a': {
            textAlign: 'center',
            width: '100%',
            margin: '7px 0',
        },
        '& h5': {
            fontWeight: 'normal',
            fontSize: 14,
        },
        '& a': {
            fontSize: 13,
            fontWeight: 'bold',
            color: theme.palette?.common?.black || '#000',
            margin: 0,
        },
        [theme.breakpoints.down(600)]: {
            padding: 10,
        },
    },
    productContainer: {
        padding: 15,
        textAlign: 'center',
        '& img': {
            width: '100%',
        },
        '& p': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            verticalAlign: 'bottom',
            textAlign: 'left',
            margin: 0,
            color: theme.palette?.common?.black || '#000',
        },
        '& a': {
            textDecoration: 'none',
        },
        [theme.breakpoints.down(600)]: {
            padding: 7,
        },
    },
    productLink: {
        marginTop: 20,
        '& a': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
            textDecoration: 'none',
            padding: '10px',
            borderRadius: '4px',
            textTransform: 'uppercase',
            fontSize: 14,
        },
    },
    productName: {
        fontWeight: 'normal',
    },

    title: {
        fontFamily: 'PlayfairDisplayRegular, serif',
        fontSize: '28px',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
        marginTop: '10px',
        '&::before': {
            content: '""',
            background: '#000',
            width: '100%',
            height: '1px',
            position: 'absolute',
            right: 0,
            left: 0,
            top: '50%',
            zIndex: 1,
        },
        '& h2': {
            fontSize: '28px',
            fontWeight: 'normal',
            position: 'relative',
            display: 'inline-block',
            zIndex: 9,
            background: '#FFF',
            padding: '0 30px',
            marginBottom: 0,
            marginTop: 0,
            textAlign: 'center',
            [theme.breakpoints.down(600)]: {
                fontSize: '16.8px',
                display: 'block',
                padding: 0,
            },
        },
    },
    imocBanner: {
        margin: '0px 10px',
    },
    genericBanner: {
        margin: '0px auto',
    },
    faqContainer: {
        margin: '35px 10px 25px',
    },
});

const MobileFareWell = ({
    classes, templateData, trackEvent, presentationFamily,
}) => {
    const breadCrumbArray = templateData?.content?.entries?.[0]?.seo?.breadcrumb || [];
    const categoryBlocks = templateData?.content?.entries?.[0]?.category_blocks || [];
    const subCollectionsData = templateData?.subCollections || [];
    const disclaimerData = templateData?.content?.entries?.[0]?.disclaimer || '';
    const seed = useUIDSeed();
    const disclaimerRef = useRef(seed('disclaimer-ref'));
    const scrollToDisclaimer = () => scrollToRef(disclaimerRef);
    const pageTrackingProps = templateData?.content?.entries?.[0]?.tracking || {};
    if (categoryBlocks) {
        return (
            <>
                <div className={classes.breadCrumbs}>
                    <GraphqlBreadcrumbs breadCrumbArray={breadCrumbArray} />
                </div>
                {
                    categoryBlocks?.map((item) => {
                        const blockKey = Object.keys(item)[0];
                        const chooseBlock = item?.you_choose_block || {};
                        const infoBlockData = item?.info_block || {};
                        const ourFavoritesBlockData = item?.our_favorites_block || {};
                        switch (blockKey) {
                            case 'generic_banner':
                            {
                                return <div className={classes.genericBanner}><GenericBanner data={item?.generic_banner} /></div>;
                            }
                            case 'mobile_tiles':
                                return <GraphqlMobileCategory data={item?.mobile_tiles} presentationFamily={presentationFamily} />;
                            case 'main_banner':
                            {
                                return <TemplateMultiSubMainBanner item={item} tracking={pageTrackingProps} />;
                            }
                            case 'imoc':
                            {
                                return (
                                    <div className={classes.imocBanner}>
                                        <GraphqlImoc
                                            imocBlock={item?.imoc}
                                            brand="flower"
                                        />
                                    </div>
                                );
                            }
                            case 'faq':
                            {
                                return <div className={`${classes.imocBanner} ${classes.faqContainer}`}> <FaqBlock faqBlock={item} /> </div>;
                            }
                            case 'you_choose_block':
                            {
                                return <ChooseBlock chooseBlock={chooseBlock} />;
                            }
                            case 'info_block':
                            {
                                return <InfoBlock infoBlockData={infoBlockData} />;
                            }
                            case 'subscription_block':
                            {
                                return <SubscriptionBlock item={item} scrollToDisclaimer={scrollToDisclaimer} />;
                            }
                            case 'our_favorites_block':
                            {
                                return <OurFavoritesBlock ourFavoritesBlockData={ourFavoritesBlockData} tracking={pageTrackingProps} />;
                            }
                            case 'sub_banner':
                            {
                                const { sub_banner = {} } = item;
                                return (
                                    <Grid key={seed(item)} item xs={12} className={classes.subBanner}>
                                        <img src={sub_banner?.mobile_image?.url} alt={sub_banner?.mobile_image?.description}  />
                                    </Grid>
                                );
                            }
                            case 'sub_collection_section': {
                                const { sub_collection_section } = item;
                                const enableToggle = sub_collection_section?.enable_toggle;
                                return (
                                    <Grid key={seed(item)} container className={classes.subCollection}>
                                        {
                                            sub_collection_section?.subcollections?.map((data) => (
                                                <>
                                                    <Grid container>
                                                        <div className={classes.title}><h2>{data?.title}</h2></div>
                                                        {data?.sub_text && <h4>{data?.sub_text}</h4>}
                                                        {data?.link?.title && <Link to={data?.link?.href}>{data.link.title}</Link>}
                                                    </Grid>
                                                    <ProductsList
                                                        classes={classes}
                                                        subCollectionsData={subCollectionsData}
                                                        id={data?.sub_code}
                                                        seed={seed}
                                                        enableToggle={enableToggle}
                                                        trackEvent={trackEvent}
                                                        banners={data?.incollection_banner}
                                                    />
                                                </>
                                            ))
                                        }
                                    </Grid>
                                );
                            }
                            case 'bottom_banners':
                            {
                                const { bottom_banners } = item;
                                return (
                                    <Grid key={seed(item)} container className={classes.bottomBanner}>
                                        <Grid container><h2>{bottom_banners?.title}</h2>
                                        </Grid>
                                        {
                                            bottom_banners?.banners?.map((data) => {
                                                const href = data?.link?.href || '';
                                                const url = data?.mobile_image?.url || '';
                                                const title = data?.mobile_image?.title || '';
                                                return (
                                                    <Grid key={seed(data)} item xs={12}>
                                                        <div className={classes.bottomBannerInner}>
                                                            {href !== ''
                                                                ?  <a href={href}><img src={url} alt={title} /></a>
                                                                : <img src={url} alt={title} />}
                                                        </div>
                                                    </Grid>
                                                );
                                            })
                                        }
                                    </Grid>
                                );
                            }
                            case 'wyng':
                                return <WyngModule data={item.wyng} />;
                            default:
                                return null;
                        }
                    })
                }

                <DisclaimerBlock disclaimer={disclaimerData} disclaimerRef={disclaimerRef} />

            </>
        );
    }
    return <ProductsFallbackContainer />;
};

MobileFareWell.propTypes = {
    classes: object.isRequired,
    templateData: shape({
        category_blocks: arrayOf(
            shape({
                main_banner: shape({
                    link: shape({
                        href: string.isRequired,
                        title: string.isRequired,
                    }),
                    mobile_image: shape({
                        url: string.isRequired,
                    }),
                }),
                sub_banner: shape({
                    mobile_image: shape({
                        url: string.isRequired,
                        title: string.isRequired,
                    }),
                }),
                bottom_banners: shape({
                    title: string.isRequired,
                    banners: arrayOf({
                        link: shape({
                            href: string.isRequired,
                        }),
                        mobile_image: shape({
                            url: string.isRequired,
                            title: string.isRequired,
                        }),
                    }),
                }),
            }),
        ),

    }).isRequired,
    trackEvent: func.isRequired,
    presentationFamily: string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(trackEventActions, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(MobileFareWell);
