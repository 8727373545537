/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { object, string } from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';

const styles = (theme) => ({
    mobileImocContainer: {
        boxShadow: '2px 2px 6px rgba(0,0,0,0.1)',
        padding: '0 15px',
    },
    innerContainer: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        lineHeight: '50px',
        width: '100%',
        '& h1': {
            margin: 0,
            fontSize: '16px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '& button': {
            outline: 'transparent',
            fontSize: '15px',
            padding: 0,
            margin: 0,
            border: 'none',
            background: 'transparent',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            whiteSpace: 'nowrap',
        },
    },
    imocReadMoreCopy: {
        paddingBottom: '20px',
        '& p': {
            fontSize: '15px',
            margin: 0,
            padding: 0,
            textAlign: 'justify',
        },
    },
    imocWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        padding: '10px 0',
        margin: '0 auto',
        border: '0',
        alignItems: 'center',
        [theme.breakpoints.up(769)]: {
            flexDirection: 'row',
            margin: '0 auto',
            padding: '25px 0',
        },
    },
    header: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '24px',
        whiteSpace: 'nowrap',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        paddingRight: '40px',
        margin: '0',
        lineHeight: '40px',
        alignSelf: 'flex-start',
        [theme.breakpoints.up(769)]: {
            borderRight: `1px solid ${theme.palette.colorNeutral60}`,
            alignSelf: 'center',
            margin: '0 40px 0 0',
        },
    },
    imocCopyContainer: {
        display: 'block',
    },
    copy: {
        display: 'inline',
        '& p': {
            margin: 0,
            lineHeight: '23px',
            fontSize: '14px',
            display: 'inline',
        },
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
});

const ImocBanner = ({ headerSubCopy, header, classes }) => {
    // set state
    const [toggle, setToggle] = useState(false);

    return (
        <DesktopMobile
            mobile={() => (
                <div className={classes.mobileImocContainer}>
                    <div className={classes.innerContainer}>
                        <h1>{header}</h1>
                        <button type="button" onClick={() => setToggle(!toggle)}>{toggle ? 'close' : 'read more'}</button>
                    </div>
                    <div style={{ height: toggle ? 'auto' : 0, overflow: 'hidden' }}>
                        <ReactMarkdown
                            source={headerSubCopy}
                            skipHtml
                            className={classes.imocReadMoreCopy}
                        />
                    </div>
                </div>
            )}
            desktop={() => (
                <div className={classes.imocWrapper}>
                    <h1 className={classes.header}> {header}</h1>
                    <div className={classes.imocCopyContainer}>
                        <ReactMarkdown
                            source={headerSubCopy}
                            skipHtml
                            className={classes.copy}
                        />
                    </div>
                </div>
            )}
            breakpoint={600}
            ignoreHover
        />
    );
};
ImocBanner.propTypes = {
    classes: object.isRequired,
    headerSubCopy: string.isRequired,
    header: string.isRequired,
};

export default (withStyles(styles)(ImocBanner));
