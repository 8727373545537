/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, string } from 'prop-types';
import ReactMarkdown from 'react-markdown';

const SubCollectionBar = ({
    classes, subCollectionBarBg, subCollectionBarFont, subCollectionCopy,
}) => (
    <div className={classes.subCollectionBar} style={{ backgroundColor: `${subCollectionBarBg}` }}>
        <p style={{ color: `${subCollectionBarFont}` }}>
            <ReactMarkdown source={subCollectionCopy} />
        </p>
    </div>
);

SubCollectionBar.propTypes = {
    classes: object.isRequired,
    subCollectionBarBg: string.isRequired,
    subCollectionBarFont: string.isRequired,
    subCollectionCopy: string.isRequired,
};

export default SubCollectionBar;
