/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, bool, shape, string, func,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { useHistory, useLocation } from 'react-router-dom';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import { handleOnClick } from '../../../../../ContentTypeComponents/utils/pathUtils';

const styles = (theme) => ({
    bannerBlock: {
        position: 'relative',
        width: '100%',
        margin: 0,
        '& img': {
            width: '100%',
            display: 'block',
        },
    },
    bannerBackgroundImage: {
        backgroundPosition: 'top',
        width: '100%',
        minHeight: 590,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down(1162)]: {
            minHeight: 470,
        },
        [theme.breakpoints.down(769)]: {
            minHeight: 650,
        },
        [theme.breakpoints.down(600)]: {
            minHeight: 550,
        },
        [theme.breakpoints.down(482)]: {
            minHeight: 370,
        },
    },
    rightBlock: {
        width: '80%',
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    bannerHeading: {
        fontSize: 60,
        textAlign: 'right',
        lineHeight: 1,
        paddingTop: 20,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 15,
            fontSize: 40,
        },
    },
    bannerSubHeading: {
        fontSize: 16,
        '& h1, h2, h3, h4, h5, h6, p': {
            margin: 0,
        },
    },
    bannerImage: {
        width: '100%',
        paddingRight: 60,
        '& img': {
            width: '100%',
        },
    },
    leftBlock: {
        width: '80%',
        paddingTop: 20,
        position: 'absolute',
        top: 0,
        right: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    leftBannerHeading: {
        fontSize: 60,
        position: 'absolute',
        top: -22,
        [theme.breakpoints.down('sm')]: {
            top: 0,
            left: 7,
            fontSize: 40,
        },
    },
    leftBannerImage: {
        padding: '28px 0px 0px 60px',
        '& img': {
            width: '100%',
        },
    },
    leftBannerSubHeading: {
        fontSize: 16,
        '& h1, h2, h3, h4, h5, h6, p': {
            margin: 0,
        },
    },
    leftMainHeadImage: {
        position: 'relative',
    },
    shopBtn: {
        color: theme.palette.cms?.white || '#fff',
        border: '1px solid',
        padding: '4px 8px',
        display: 'block',
        width: '150px',
        textAlign: 'center',
        marginTop: '12px',
    },
    bottomBlock: {
        textAlign: 'left',
        padding: '14px 60px 0px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 60px 0px 30px',
        },
    },
    leftBottomBlock: {
        textAlign: 'right',
        padding: '14px 40px 0px 60px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 30px 0px 60px',
        },
    },
    leftBlockBtn: {
        color: theme.palette.cms?.white || '#fff',
        border: '1px solid',
        padding: '4px 8px',
        display: 'block',
        width: '130px',
        textAlign: 'center',
        margin: '12px 0px 0px auto',
    },
    bannerHeadingBottom: {
        fontSize: 40,
        lineHeight: 1,
        paddingTop: 20,
        [theme.breakpoints.down('sm')]: {
            padding: '12px 0px',
            fontSize: 30,
        },
    },
    rightBlockBottom: {
        width: '70%',
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'initial',
            minHeight: 'unset !important',
        },
    },
    bottomInnerBlock: {
        textAlign: 'left',
        padding: '0px 60px 10px 40px',
        background: 'rgba(255, 255, 255, 0.5)',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 30px 10px 30px',
            background: 'transparent',
        },
    },
    bannerImageBottom: {
        width: '100%',
        '& img': {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    shopBtnBottom: {
        display: 'inline-block',
        margin: '5px 0px',
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            fontWeight: 'bold',
        },
    },
    leftBannerHeadingBottom: {
        fontSize: 40,
    },
    leftBlockBottom: {
        width: '70%',
        paddingTop: 20,
        position: 'absolute',
        bottom: 0,
        right: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'initial',
            padding: 0,
            '& img': {
                display: 'none',
            },
            minHeight: 'unset !important',
        },
    },
    leftBottomInnerBlock: {
        background: 'rgba(255, 255, 255, 0.5)',
        textAlign: 'right',
        padding: '14px 40px 0px 60px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 30px 10px 30px',
            background: 'transparent',
            fontSize: 30,
            textAlign: 'left',
        },
    },
    birthdayGiftsMain: {
        '& $leftBannerHeading': {
            position: 'initial',
            lineHeight: 1,
        },
        '& $leftBannerImage': {
            padding: '0px 0px 0px 60px',
        },
    },
});

const CategoryTileBanner = ({
    classes, bannerData, isEven, fontFamily,
}) => {
    const heading = bannerData?.heading || '';
    const subHeading = bannerData?.sub_heading || '';
    const imageBanner = bannerData?.framed_image?.url || '';
    const title = bannerData?.cta_button?.title || '';
    const href = bannerData?.cta_button?.href || '';
    const bannerPosition = bannerData?.banner_position || 'top';
    const history = useHistory();
    const headingStyle = {
        color: bannerData?.heading_color?.color || '#ffffff',
        fontFamily: fontFamily(bannerData?.heading_font_family) || '',
    };
    const subHeadingStyle = {
        color: bannerData?.sub_heading_font_color?.color || '#000000',
        fontFamily: fontFamily(bannerData?.sub_heading_font_family) || '',
    };
    const imageQuality = { desktop: 'quality=100', tablet: 'quality=100', mobile: 'quality=100' };
    const backgroundImageStyle = {
        backgroundImage: `url(${bannerData?.background_image?.url}?auto=webp)`,
    };
    const ariaLabelFromTextContent = () => ((heading || subHeading || title) ? `${heading} ${subHeading} ${title}` : 'Shop Now');

    // Add Class Based on URL
    const location = useLocation();
    const loadCssUrlBase = () => {
        switch (location?.pathname) {
            case '/birthdaygifts':
                return classes.birthdayGiftsMain;
            default:
                return null;
        }
    };
    // Render Right Banner Block Bassed On Botttom Position Selected From CMS
    const rightBottomPositionGrid = () => (
        <div className={classes.rightBlockBottom}>
            {imageBanner && (
                <div className={classes.bannerImageBottom}>
                    <ResponsiveImage path={imageBanner} alt={subHeading} params={imageQuality} />
                </div>
            )}
            <div className={classes.bottomInnerBlock}>
                <h4 className={classes.bannerHeadingBottom} style={headingStyle}>
                    {heading}
                </h4>
                <div className={classes.bannerSubHeading} style={subHeadingStyle}>
                    <ReactMarkdown
                        source={subHeading}
                        escapeHtml={false}
                    />
                </div>
                {title && <div role="presentation" style={subHeadingStyle} className={classes.shopBtnBottom} onClick={(e) => { handleOnClick(e, href, history); }}>{`${title } >`}</div>}
            </div>
        </div>
    );

    // Render Right Banner Block Bassed On Top Position Selected From CMS
    const rightTopPositionGrid = () => (
        <div className={classes.rightBlock}>
            <div className={classes.bannerHeading} style={headingStyle}>
                {heading}
            </div>
            {imageBanner && (
                <div className={classes.bannerImage}>
                    <ResponsiveImage path={imageBanner} alt={subHeading} params={imageQuality} />
                </div>
            )}
            <div className={classes.bottomBlock}>
                <div className={classes.bannerSubHeading} style={subHeadingStyle}>
                    <ReactMarkdown
                        source={subHeading}
                        escapeHtml={false}
                    />
                </div>
                {title && <div role="presentation" style={subHeadingStyle} className={classes.shopBtn} onClick={(e) => { handleOnClick(e, href, history); }}>{title }</div>}
            </div>
        </div>
    );

    // Render Left Banner Block Bassed On Top Position Selected From CMS
    const leftTopPositionGrid = () => (
        <div className={classes.leftBlock}>
            <div className={classes.leftMainHeadImage}>
                <div className={classes.leftBannerHeading} style={headingStyle}>
                    {heading}
                </div>
                {imageBanner
                    && (
                        <div className={classes.leftBannerImage}>
                            <ResponsiveImage path={imageBanner} alt={subHeading} params={imageQuality} />
                        </div>
                    )}
                <div className={classes.leftBottomBlock}>
                    <div className={classes.leftBannerSubHeading} style={subHeadingStyle}>
                        <ReactMarkdown
                            source={subHeading}
                            escapeHtml={false}
                        />
                    </div>
                    {title && <div role="presentation" style={subHeadingStyle} className={classes.leftBlockBtn} onClick={(e) => { handleOnClick(e, href, history); }}>{title}</div>}
                </div>
            </div>
        </div>
    );

    // Render Left Banner Block Bassed On Botttom Position Selected From CMS
    const leftBottomPositionGrid = () => (
        <div className={classes.leftBlockBottom}>
            <div className={classes.leftMainHeadImage}>
                {imageBanner
                    && (
                        <div>
                            <ResponsiveImage path={imageBanner} alt={subHeading} params={imageQuality} />
                        </div>
                    )}
                <div className={classes.leftBottomInnerBlock}>
                    <div className={classes.leftBannerHeadingBottom} style={headingStyle}>
                        {heading}
                    </div>
                    <div className={classes.leftBannerSubHeading} style={subHeadingStyle}>
                        <ReactMarkdown
                            source={subHeading}
                            escapeHtml={false}
                        />
                    </div>
                    {title && <div role="presentation" style={subHeadingStyle} className={classes.shopBtnBottom} onClick={(e) => { handleOnClick(e, href, history); }}>{`${title } >`}</div>}
                </div>
            </div>
        </div>
    );

    const renderBlock = () => {
        if (isEven) {
            if (bannerPosition === 'bottom') {
                return rightBottomPositionGrid();
            }
            return rightTopPositionGrid();
        }
        if (bannerPosition === 'bottom') {
            return leftBottomPositionGrid();
        }
        return leftTopPositionGrid();
    };
    return (
        <Grid container>
            <div className={`${classes.bannerBlock} ${loadCssUrlBase()}`}>
                {href
                    ? (
                        <LinkOrATag href={href} ariaLabel={ariaLabelFromTextContent()}>
                            <div style={backgroundImageStyle} className={classes.bannerBackgroundImage}>
                                {renderBlock()}
                            </div>
                        </LinkOrATag>
                    )
                    :              (
                        <>
                            <div style={backgroundImageStyle} className={classes.bannerBackgroundImage}>
                                {renderBlock()}
                            </div>
                        </>
                    )}

            </div>
        </Grid>

    );
};
CategoryTileBanner.propTypes = {
    classes: object.isRequired,
    bannerData: shape({
        background_image: shape({
            url: string,
        }),
        heading: string,
        sub_heading: string,
    }).isRequired,
    isEven: bool.isRequired,
    fontFamily: func.isRequired,
};

export default withStyles(styles)(CategoryTileBanner);
