/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { withStyles } from '@material-ui/core/styles';
// components
import ExpandLess from '@material-ui/icons/Remove';
import ExpandMore from '@material-ui/icons/Add';
import { useHistory } from 'react-router';
import GraphqlDisclaimer from '../GraphqlDisclaimer/GraphqlDisclaimer';
import FaqBlock from '../FaqBlock/FaqBlock';
import LinkOrATag from '../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const styles = (theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        padding: '0 10px',
        alignItems: 'center',
        height: '40px',
        outline: 'none',
        cursor: 'pointer',
    },
    border: {
        border: `1px solid ${theme.palette?.gfMobileLabelTextColor}`,
    },
    wrapperChild: {
        flex: 1,
        minWidth: 0,
    },
    headerText: {
        fontSize: 16,
        fontWeight: 700,
        textTransform: 'uppercase',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    toggle: {
        whiteSpace: 'nowrap',
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: '23px',
            height: '23px',
        },
    },
    linkText: {
        display: 'flex',
        width: '100%',
        '& ul': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            margin: '0 10px 16px 10px',
            paddingLeft: 0,
            listStyle: 'none',
            lineHeight: '1.3',
            '& li': {
                marginTop: 6,
                '& a': {
                    color: 'inherit',
                    textDecoration: 'underline',
                },
            },
        },
    },
    expandedFalse: {
        height: 40,
        width: '100%',
    },
    expandedTrue: {
        height: 'auto',
        width: '100%',
        transition: 'height 0.2s ease-in-out 0s',
    },
    faqBlock: {
        padding: '0 10px',
    },
});

const renderBlock = (classes, blockName, data, link, seed) => {
    switch (blockName)   {
        case 'disclaimer':
            return <GraphqlDisclaimer disclaimerBlock={data} />;
        case 'faq':
        {
            // To avoid duplicate title on moblie for faq
            const faqList = data?.faqs;
            const languageAttr = data?.language_attribute;
            const faqBlock  = {
                faqs: faqList,
                title: '',
                language_attribute: languageAttr,
            };
            return <div className={classes.faqBlock}> <FaqBlock faqBlock={faqBlock} /> </div>;
        }
        default:
            return (
                link?.[0]?.href
                && (
                    <div className={classes.linkText}>
                        <ul>
                            {link?.map((line) => {
                                if (line.href) {
                                    return (
                                        <li key={seed(line)}>
                                            <LinkOrATag href={link?.[0]?.href}>
                                                {line.title}
                                            </LinkOrATag>
                                        </li>
                                    );
                                } return null;
                            })}
                        </ul>
                    </div>
                )
            );
    }
};
const RailCategoryRelatedBox = ({
    classes, data, blockName, pageTitle,
}) => {
    // This hook is used to control whether the  menus are expanded or not.
    const history = useHistory();
    const [expanded, setExpanded] = useState(false);
    const seed = useUIDSeed();
    const { title = '', link = [], faqs = [] } = data;
    useEffect(() => {
        if (history?.location?.hash === '#disclaimer') {
            setExpanded(!expanded);
        }
    }, []);

    if (link.length > 0 || data?.copy  || faqs.length > 0) {
        return (
            <div
                className={`${classes.border} ${expanded ? classes.expandedTrue : classes.expandedFalse}`}
            >
                <div
                    className={`${classes.root}`}
                    onClick={() => setExpanded(!expanded)}
                    onKeyDown={() => setExpanded(!expanded)}
                    role="button"
                    tabIndex={-1}
                >
                    <div className={classes.wrapperChild}>
                        <p className={classes.headerText}>
                            {title || `More About ${pageTitle}`}
                        </p>
                    </div>
                    {/* if the  menu is expanded we change the icon to '-' instead of '+' */}
                    <div className={classes.toggle}>{expanded ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
                <div>

                    {/* renders collection footer else related searches/articles in explandable components */}
                    {renderBlock(classes, blockName, data, link, seed)}
                </div>
            </div>
        );
    }
    return null;
};

RailCategoryRelatedBox.propTypes = {
    classes: object.isRequired,
    data: shape({
        title: string,
        link: arrayOf(shape({
            title: string.isRequired,
            href: string.isRequired,
        })),
        copy: string,
    }).isRequired,
    blockName: string.isRequired,
    pageTitle: string.isRequired,
};

export default withStyles(styles)(RailCategoryRelatedBox);
