/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, string, arrayOf, bool,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useUIDSeed } from 'react-uid';
import ReactMarkdown from 'react-markdown';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const styles = (theme) => ({
    block: {
        border: `1px solid ${theme.palette.grey7}`,
        margin: '10px 0',
        '& img': {
            width: '100%',
            display: 'block',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '80%',
            margin: '10px auto',
        },
    },
    article: {
        margin: 'auto',
        width: '100%',
        textAlign: 'center',
        '& a': {
            textDecoration: 'none',
            color: theme.palette.common?.black,
        },
        [theme.breakpoints.down(600)]: {
            '& h1': {
                margin: 26,
                fontSize: 16,
            },
        },
    },
    articleBox: {
        padding: '0px 25px 10px',
        '& img': {
            width: '100%',
            display: 'block',
            maxHeight: 170,
            minHeight: 170,
            borderRadius: 10,
        },
        [theme.breakpoints.down(600)]: {
            '& img': {
                borderRadius: 5,
            },
            padding: '0px 30px 10px',
        },
        '& p': {
            margin: '5px 0px',
        },
    },
    fullWidthBlock: {
        border: `1px solid ${theme.palette.grey7}`,
        margin: 'auto',
        maxWidth: 1440,
        '& img': {
            width: '100%',
            display: 'block',
        },
        [theme.breakpoints.down(1440)]: {
            maxWidth: '95%',
            margin: '10px auto',
        },
    },
    headerTitle: {
        '&:before': {
            borderBottom: `1px solid ${theme.palette.cms?.black || '#000'}`,
            position: 'relative',
            width: '100%',
            display: 'block',
            content: '""',
            top: '50%',
        },
        '& p': {
            fontFamily: 'Times New Roman',
            fontSize: 26,
            position: 'relative',
            minWidth: 512,
            background: theme.palette.cms?.white || '#fff',
            width: '43%',
            margin: '10px auto',
        },
        [theme.breakpoints.down(600)]: {
            '&:before': {
                display: 'none',
            },
            '& p': {
                width: '90%',
                minWidth: 'initial',
            },
        },
    },
    articleItems: {
        maxWidth: 1080,
        margin: '0 auto',
    },
});
const returnBlogLink = (ctaName, href, url, title, brand) => ((href.includes('/blog') || href?.includes('/articles'))
    ? (
        <a href={`https://www.${brand}.com${href}`}>
            <img src={url} alt={title} />
            <p>{title}</p>
        </a>
    ) : (
        <LinkOrATag href={href}>
            <img src={url} alt={title} />
            <p>{title}</p>
            <strong>{ctaName}</strong>
        </LinkOrATag>
    ));
const articleLink = (ctaName, href, url, title, brand) => {
    if (href) {
        return (
            returnBlogLink(ctaName, href, url, title, brand)
        );
    }
    return (
        <>
            <img src={url} alt={title} />
            <p>{title}</p>
            <strong>{ctaName}</strong>
        </>
    );
};
const JasonwuConnectArticleBlock = ({
    classes, data, brand, fullWidth,
}) => {
    const seed = useUIDSeed();
    return (
        <>
            <Grid item xs={12} className={fullWidth ? classes.fullWidthBlock : classes.block}>
                <Grid container className={classes.article}>
                    <Grid item xs={12} className={classes.headerTitle}><ReactMarkdown source={data?.header} /></Grid>
                    <Grid container className={classes.articleItems}>
                        {
                            data?.article?.map((art) => {
                                const image = art?.image;
                                const link = art?.link;
                                const ctaName = art?.cta_name;
                                return (
                                    <Grid key={seed(art)} item xs={12} sm={4}>
                                        <div className={classes.articleBox}>
                                            {articleLink(ctaName, link?.href, image?.url, link?.title, brand)}
                                        </div>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

JasonwuConnectArticleBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        header: string,
        article: arrayOf(shape({
            image: shape({
                url: string.isRequired,
                filename: string.isRequired,
            }).isRequired,
            link: shape({
                title: string.isRequired,
                href: string.isRequired,
            }).isRequired,
        })),
    }).isRequired,
    brand: string.isRequired,
    fullWidth: bool,
};
JasonwuConnectArticleBlock.defaultProps = {
    fullWidth: false,
};

export default withStyles(styles)(JasonwuConnectArticleBlock);
