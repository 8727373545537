/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, shape, string, arrayOf, func,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useUIDSeed } from 'react-uid';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const styles = ()  => ({
    tileBlock: {
        maxWidth: 980,
        width: '100%',
        margin: '15px auto',
    },
    tileImage: {
        position: 'relative',
        padding: '0 10px',
        '& img': {
            width: '100%',
        },
    },
    tileTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    tileText: {
        textAlign: 'center',
        fontSize: 13,
    },
    textGrid: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        width: 130,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
    },
});
const TileBlock = (classes, image, link, text, textStyle, block, numberOfTiles) => {
    const seed = useUIDSeed();
    return (
        <Grid key={seed(block)} item xs={12} md={numberOfTiles} lg={numberOfTiles} className={classes.tileImage}>
            <LinkOrATag className={classes.link} style={textStyle} href={link?.href}>
                <img src={image?.url} alt={link?.title} />
                <div className={classes.textGrid}>
                    <div>
                        <div className={classes.tileTitle}>{link?.title}</div>
                        <div className={classes.tileText}>{text}</div>
                    </div>
                </div>
            </LinkOrATag>
        </Grid>
    );
};
const SimpleTile = ({ classes, data, fontFamily }) => {
    const simpleTileBlock = data?.simple_tile_row?.tile || [];
    const fontColor  = data?.simple_tile_row?.styles?.font_color?.color || '#ffffff';
    const textStyle = {
        color: fontColor,
        fontFamily: fontFamily(data?.simple_tile_row?.styles?.font_family) || '',
    };
    const numberOfTiles = simpleTileBlock?.length === 3 ? 4 : 3;
    return (
        <div className={classes.tileBlock}>
            <Grid container>
                {simpleTileBlock.map((block) => TileBlock(classes, block?.image, block?.link, block?.text, textStyle, block, numberOfTiles))}
            </Grid>
        </div>
    );
};
SimpleTile.propTypes = {
    classes: object.isRequired,
    data: shape({
        simple_tile_row: shape({
            styles: shape({
                font_color: shape({
                    color: string,
                }),
            }),
        }),
        tile: arrayOf(shape({
            image: shape({
                url: string,
            }),
            link: shape({
                title: string,
                href: string,
            }),
            text: string,
        })),
    }).isRequired,
    fontFamily: func.isRequired,
};

export default withStyles(styles)(SimpleTile);
