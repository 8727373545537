/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import {
    string, shape, arrayOf,
} from 'prop-types';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import parseStyles from '../../../../../helpers/contentstack/parseStyles';
import RenderVideoThumbnail from './Partials/RenderVideoThumbnail';
import ResponsiveImage from '../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';

const useStyles = makeStyles((theme) => ({
    headerBlock: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    headerSection: (jwuSliderStyles) => ({
        textAlign: jwuSliderStyles.copySubCopyAlign || 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    }),
    heading: (jwuSliderStyles) => ({
        margin: 0,
        fontSize: '45px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
        '& p': {
            margin: 0,
            fontFamily: jwuSliderStyles.copyFont,
        },
    }),
    subHeading: (jwuSliderStyles) => ({
        '& p': {
            fontFamily: jwuSliderStyles.subCopyFont,
        },
    }),
    sliderSection: {
        width: '90%',
        minHeight: '425px',
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minHeight: 'auto',
        },
        '& img': {
            width: '100%',
        },
    },
    outerHeader: {
        width: '30%',
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    thumbSection: {
        height: '100%',
        cursor: 'pointer',
        padding: '8px',
        opacity: '0.25',
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block',
            width: '25%',
            height: 'auto',
        },
        '& img': {
            width: 135,
            maxHeight: 83,
            display: 'block',
            objectFit: 'cover',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                minHeight: 83,
            },
        },
        '& video': {
            width: 135,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        '& canvas': {
            display: 'none',
        },
    },
    headingImage: {
        margin: '0',
        justifyContent: 'flex-end',
        '& img': {
            width: '80%',
        },
    },
    thumbSelected: {
        opacity: 1,
    },

    thumbContainer: {
        width: 'calc(100% + 16px)',
        margin: '16px -8px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            width: '100%',
        },
        [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
        },
    },
    headingMobileImage: {
        margin: '0',
        justifyContent: 'flex-end',
        '& img': {
            width: 'auto',
        },
    },
}));
const TemplateJasonwuSlider = ({ sliderBlock }) => {
    const headerData = sliderBlock?.header || {};
    const copySubCopyAlign = headerData?.align;

    const copyFont = { font_family: headerData?.copy_font_family };
    const parsedCopyFont = parseStyles(copyFont);

    const subCopyFont = { font_family: headerData?.sub_copy_font_family };
    const parsedSubCopyFont = parseStyles(subCopyFont);

    const jwuSliderStyles = {
        copyFont: parsedCopyFont?.fontFamily,
        subCopyFont: parsedSubCopyFont?.fontFamily,
        copySubCopyAlign,
    };
    const classes = useStyles(jwuSliderStyles);

    const sliders = sliderBlock?.slider?.slider_images;

    const [sliderData, loadSlider] = useState({
        selectedIndex: 0,
        changeRailImage: true,
    });

    let rightRailImage = '';
    if (headerData) {
        rightRailImage = headerData.right_rail_image?.url;
    }
    const seed = useUIDSeed();
    const { selectedIndex, changeRailImage } = sliderData;

    const ssrDeviceType = useSelector(getSSRDeviceType);
    const topDesktopMobileImage = () => (
        ssrDeviceType === 'mobile' ? (
            <div className={classes.headingMobileImage}>
                <ResponsiveImage path={headerData?.image_mobile?.url} alt="mobile banner heading" />
            </div>
        ) : (
            <div className={classes.headingImage}>
                <ResponsiveImage path={headerData?.image?.url} alt="banner heading" />
            </div>
        )
    );

    const rightRailImageMobile = headerData?.right_rail_mobile_image?.url;
    const rightDesktopMobileImage = () => (
        ssrDeviceType === 'mobile' ? (
            <ResponsiveImage path={changeRailImage && rightRailImageMobile ? rightRailImageMobile : sliders?.[selectedIndex]?.url} alt="mobile slider" className={classes.sliderImage} />
        ) : (
            <ResponsiveImage path={changeRailImage && rightRailImage ? rightRailImage : sliders?.[selectedIndex]?.url} alt="banner heading" className={classes.sliderImage} />
        )
    );

    return (
        <Grid item xs={12} sm={12}>
            <div className={classes.headerBlock}>
                <Grid item lg={4} md={4} sm={12} xs={12} className={classes.outerHeader}>
                    <div className={classes.headerSection}>
                        {headerData?.image?.url && (
                            <>
                                {topDesktopMobileImage()}
                            </>
                        )}
                        {headerData?.copy && <div className={classes.heading}><ReactMarkdown source={headerData?.copy} /></div>}
                        <ReactMarkdown source={headerData?.sub_copy} escapeHtml={false} className={classes.subHeading} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={8} md={8}>
                    <div className={classes.sliderSection}>
                        {sliders?.[selectedIndex]?.content_type === 'video/mp4' ? (
                            <video autoPlay controls width="100%">
                                <source src={sliders?.[selectedIndex]?.url} type="video/mp4" preload="metadata" />
                                <track kind="captions" />
                            </video>
                        ) : (
                            <>
                                {rightDesktopMobileImage()}
                            </>
                        )}
                    </div>
                </Grid>
            </div>

            <div className={classes.thumbContainer}>
                {
                    sliders?.map((value, index) => (
                        <div
                            key={seed(value)}
                            onClick={() => loadSlider({
                                selectedIndex: index,
                                changeRailImage: false,
                            })}
                            role="presentation"
                            className={selectedIndex === index ? `${classes.thumbSection} ${classes.thumbSelected}` : classes.thumbSection}
                        >
                            {value?.content_type === 'video/mp4'
                                ? <RenderVideoThumbnail url={value?.url} />
                                : <img src={value?.url} alt={value.description || `thumbnail image - ${index}`} className={classes.headerImage} />}
                        </div>
                    ))
                }
            </div>
        </Grid>
    );
};

TemplateJasonwuSlider.propTypes = {
    sliderBlock: shape({
        header: shape({
            image: shape({
                url: string,
                content_type: string,
            }),
            image_mobile: shape({
                url: string,
                content_type: string,
            }),
            copy: string,
            sub_copy: string,
        }),
        slider: shape({
            slider_images: arrayOf({
                url: string,
                content_type: string,
            }),
        }),
    }),
};

TemplateJasonwuSlider.defaultProps = {
    sliderBlock: {},
};

export default TemplateJasonwuSlider;
