/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
    object, shape, string, arrayOf, number,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';

const categoryRow = (classes, categoryPerRow) => {
    if (categoryPerRow === 4) {
        return  classes.simpleProduct4row;
    }
    if (categoryPerRow === 5) {
        return  classes.simpleProduct5row;
    }
    if (categoryPerRow === 6) {
        return  classes.simpleProduct6row;
    }
    return classes.simpleProduct;
};

const linkRender = (classes, product, categoryPerRow) => {
    if (!product) return null;
    const href  = product.link?.href || '';
    const url = product.image?.url || '';
    const imageAlt = product?.image?.title || '';
    if (href && url) {
        return ((href.indexOf('http://') === 0 || href.indexOf('https://') === 0)
            ?  (
                <a href={href} className={`${classes.imageBlock} ${classes.productImage}`}>
                    <img src={url} alt={imageAlt} />
                    {categoryPerRow === 6 && product && <div className={classes.productTitle}>{product.link?.title}</div> }
                </a>
            )
            :  (
                <Link to={href} className={`${classes.imageBlock} ${classes.productImage}`}>
                    <img src={url} alt={imageAlt} />
                    {categoryPerRow === 6 && product && <div className={classes.productTitle}>{product.link?.title}</div>}
                </Link>
            )
        );
    } return  (
        url && <img src={url} alt={imageAlt} />
    );
};

const CategoryGiftGuides = ({
    classes, categories,
}) => {
    const category = categories?.category || [];
    const categoryPerRow = categories?.categories_per_row || 4;
    return (
        category.map((product) => {
            const {
                logo_image,
            } = product;
            const seed = useUIDSeed();
            return (
                <Grid
                    key={seed(product)}
                    item
                    className={`${classes.productBlock} ${categoryRow(classes, categoryPerRow)}`}
                >
                    {linkRender(classes, product, categoryPerRow)}
                    {logo_image && categoryPerRow < 6
                        ? (
                            <div className={classes.logoImage}>
                                <img
                                    src={product?.logo_image?.url}
                                    alt={product?.logo_image?.title}
                                />
                            </div>
                        ) : null}
                    {categoryPerRow < 6 && <Typography className={classes.productName}>{ReactHtmlParser(product?.link?.title)}</Typography> }
                </Grid>
            );
        }));
};
CategoryGiftGuides.propTypes = {
    classes: object.isRequired,
    categories: shape({
        categories_per_row: number.isRequired,
        product: arrayOf(
            shape({
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }),

                image: shape({
                    url: string.isRequired,
                }),
                logo: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
            }),
        ),
    }),
};

export default CategoryGiftGuides;
