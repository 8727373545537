/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';

export default class RenderVideoThumbnail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            snapshot: null,
        };
        this.video  = React.createRef();
        this.canvas  = React.createRef();
    }

    getSnapShot = () => {
        try {
            const video = this.video;
            const canvas = this.canvas;
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            canvas.getContext('2d').drawImage(video, 0, 0);
            const thumbnail = canvas.toDataURL('image/png');
            video.src = '';
            video.remove();
            canvas.remove();
            this.setState({
                snapshot: thumbnail,
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { url } = this.props;
        const { snapshot } = this.state;
        if (!snapshot) {
            return (
                <div className="react-thumbnail-generator">
                    <canvas className="snapshot-generator" ref={this.canvas} />
                    <video
                        muted
                        className="snapshot-generator"
                        ref={this.video}
                        src={url}
                    />
                </div>
            );
        }
        return (<img src={snapshot} alt="video-thumb" />);
    }
}
RenderVideoThumbnail.propTypes = {
    url: PropTypes.string.isRequired,
};
