/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    object, shape, arrayOf, string, bool, func,
} from 'prop-types';
import loadable from '@loadable/component';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import ProductsFallbackContainer from '../../../GraphqlCommonComponents/GraphqlProductsFallbackContainer/ProductsFallbackContainer';
import TemplateMultiSubMainBanner from './Partials/TemplateMultiSubMainBanner';
import ChooseBlock from './Partials/ChooseBlock';
import InfoBlock from './Partials/InfoBlock';
import DisclaimerBlock from './Partials/DisclaimerBlock';
import SubscriptionBlock from './Partials/SubscriptionBlock';
import OurFavoritesBlock from './Partials/OurFavoritesBlock';
import WyngModule from '../../../../ContentTypeComponents/Wyng/Wyng';

// helpers
import scrollToRef from './Partials/scrollToRef';
import { trackEvent as trackEventActions } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import GraphqlImoc from '../../../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlImoc/GraphqlImoc';
import FaqBlock from '../../../GraphqlCategoryPage/Partials/FaqBlock/FaqBlock';
import GraphqlBreadcrumbs from '../../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import GenericBanner from './Partials/GenericBanner';
import ProductsList from './Partials/ProductsList';

const SeoBlock = loadable(() => import(/* webpackChunkName: "SeoBlock" */ '../../../../ContentTypeComponents/SeoBlock/SeoBlock'));

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        '& [class*="GraphqlBreadcrumbs-breadCrumb-"]': {
            padding: '10px 0',
        },
    },
    subBanner: {
        margin: '0 auto',
        maxWidth: 1400,
        padding: '30px 0',
        '& img': {
            width: '100%',
        },
    },
    bottomBannerInner: {
        padding: 10,
        textAlign: 'center',
        '& img': {
            width: '100%',
        },
    },
    bottomBanner: {
        margin: '0 auto',
        maxWidth: 1400,
        justifyContent: 'center',
        width: '100%',
    },
    title: {
        fontSize: '26px',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
        marginTop: '10px',
        '&::before': {
            content: '""',
            background: theme.palette?.colorNeutral60 || '#9E9E9E',
            width: '100%',
            height: '1px',
            position: 'absolute',
            right: 0,
            left: 0,
            top: '50%',
            zIndex: 1,
            [theme.breakpoints.down(600)]: {
                background: 'transparent',
            },
        },
        '& h2 ,& p': {
            fontSize: '26px',
            fontWeight: 'normal',
            position: 'relative',
            display: 'inline-block',
            zIndex: 9,
            background: '#FFF',
            padding: '0 30px',
            margin: '0 auto',
            fontFamily: 'PlayfairDisplayRegular, serif',
            textAlign: 'center',
        },
        '& a': {
            position: 'absolute',
            right: 0,
            top: 10,
            zIndex: 1,
        },
    },
    subCollection: {
        maxWidth: '1400px',
        margin: '20px auto',
        '& h4, & a': {
            width: '100%',
        },
        '& h4': {
            fontWeight: 'normal',
        },
        '& a': {
            fontSize: 16,
            fontWeight: 'bold',
            width: 'fit-content',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    productContainer: {
        padding: '15px',
        '& img': {
            display: 'block',
            width: '100%',
            marginBottom: '5px',
        },
        '& p': {
            fontWeight: 'normal',
            overflow: 'visible',
            textOverflow: 'ellipsis',
            verticalAlign: 'bottom',
            margin: 0,
            color: theme.palette.common.black,
        },
        '& a': {
            textDecoration: 'none',
        },
    },
    productLink: {
        marginTop: 20,
        '& a': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
            textDecoration: 'none',
            padding: '10px',
            borderRadius: '4px',
            textTransform: 'uppercase',
            fontSize: 14,
        },
    },
    viewAllLink: {
        textAlign: 'right',
        color: theme.palette.cms?.white || theme.palette.common.white,
        textDecoration: 'none',
        '& span': {
            background: theme.palette.cms?.green || 'green',
            color: theme.palette.cms?.white || theme.palette.common.white,
            padding: '7px 14px',
            borderRadius: 50,
        },
    },
    imocBanner: {
        margin: '0 auto',
        maxWidth: 1400,
    },
    genericBanner: {
        margin: '15px auto',
        maxWidth: 1400,
        width: '100%',
    },
    faqContainer: {
        margin: '0px 25px',
    },
    TemplateFareMain: {
        margin: '0px auto',
        maxWidth: 1400,
    },
});

const TemplateFareWell = ({
    classes, templateData, trackEvent, presentationFamily,
}) => {
    const categoryBlocks = templateData?.content?.entries?.[0]?.category_blocks || [];
    const subCollectionsData = templateData?.subCollections || [];
    const disclaimerData = templateData?.content?.entries?.[0]?.disclaimer || '';
    const seed = useUIDSeed();
    const disclaimerRef = useRef(seed('disclaimer-ref'));
    const scrollToDisclaimer = () => scrollToRef(disclaimerRef);
    const gridKey = (key = 1) => (key <= 6 ? 12 / key : 2);
    const showBreadcrumbs = templateData?.content?.entries?.[0]?.seo?.breadcrumb?.length > 0;
    const pageTrackingProps = templateData?.content?.entries?.[0]?.tracking || {};
    if (categoryBlocks.length) {
        return (
            <>
                <div className={classes.TemplateFareMain}>
                    <Grid container className={classes.container}>
                        {showBreadcrumbs && (<GraphqlBreadcrumbs breadCrumbArray={templateData.content.entries[0].seo.breadcrumb} />)}
                        {
                            categoryBlocks?.map((item) => {
                                const blockKey = Object.keys(item)[0];
                                const chooseBlock = item?.you_choose_block || {};
                                const infoBlockData = item?.info_block || {};
                                const ourFavoritesBlockData = item?.our_favorites_block || {};
                                switch (blockKey) {
                                    case 'generic_banner':
                                    {
                                        return <div className={classes.genericBanner}><GenericBanner data={item?.generic_banner} /></div>;
                                    }
                                    case 'main_banner':
                                    {
                                        return <TemplateMultiSubMainBanner item={item} tracking={pageTrackingProps} />;
                                    }
                                    case 'faq':
                                    {
                                        return <div className={`${classes.imocBanner} ${classes.faqContainer}`}> <FaqBlock faqBlock={item} /> </div>;
                                    }
                                    case 'imoc':
                                    {
                                        return (
                                            <div className={classes.imocBanner}>
                                                <GraphqlImoc
                                                    imocBlock={item?.imoc}
                                                    brand={presentationFamily}
                                                />
                                            </div>
                                        );
                                    }
                                    case 'you_choose_block':
                                    {
                                        return <ChooseBlock chooseBlock={chooseBlock} />;
                                    }
                                    case 'info_block':
                                    {
                                        return <InfoBlock infoBlockData={infoBlockData} />;
                                    }
                                    case 'subscription_block':
                                    {
                                        return <SubscriptionBlock item={item} scrollToDisclaimer={scrollToDisclaimer} />;
                                    }
                                    case 'our_favorites_block':
                                    {
                                        return <OurFavoritesBlock ourFavoritesBlockData={ourFavoritesBlockData} tracking={pageTrackingProps} />;
                                    }
                                    case 'sub_banner':
                                    {
                                        const { sub_banner = {} } = item;
                                        return (
                                            <Grid key={seed(item)} item xs={12} className={classes.subBanner}>
                                                <img src={sub_banner?.desktop_image?.url} alt={sub_banner?.desktop_image?.description || sub_banner?.mobile_image?.description || 'Fare Well Template'} />
                                            </Grid>
                                        );
                                    }
                                    case 'sub_collection_section':
                                    {
                                        const { sub_collection_section } = item;
                                        const enableToggle = sub_collection_section?.enable_toggle;
                                        return (
                                            <Grid key={seed(item)} container className={classes.subCollection}>
                                                {sub_collection_section?.subcollections?.map((data) => (
                                                    <>
                                                        <Grid container>
                                                            <div className={classes.title}>
                                                                <p>{data?.title}</p>
                                                                {data?.link?.title && <Link className={classes.viewAllLink} to={data?.link?.href}><span>{data.link.title}</span></Link>}
                                                            </div>
                                                            {data?.sub_text && <h4>{data?.sub_text}</h4>}
                                                        </Grid>
                                                        <ProductsList
                                                            classes={classes}
                                                            subCollectionsData={subCollectionsData}
                                                            id={data?.sub_code}
                                                            seed={seed}
                                                            enableToggle={enableToggle}
                                                            trackEvent={trackEvent}
                                                            banners={data?.incollection_banner}
                                                        />
                                                    </>
                                                ))}
                                            </Grid>
                                        );
                                    }
                                    case 'bottom_banners':
                                    {
                                        const { bottom_banners } = item;
                                        return (
                                            <Grid key={seed(item)} container className={classes.bottomBanner}>
                                                {bottom_banners?.title && (
                                                    <Grid container className={classes.title}><p>{bottom_banners?.title}</p>
                                                    </Grid>
                                                )}
                                                {
                                                    bottom_banners?.banners?.map((data) => {
                                                        const href = data?.link?.href || '';
                                                        const url = data?.desktop_image?.url || '';
                                                        const title = data?.desktop_image?.title || '';
                                                        return (
                                                            <Grid key={seed(data)} item xs={gridKey(bottom_banners?.banners.length || 1)}>
                                                                <div className={classes.bottomBannerInner}>
                                                                    {href !== ''
                                                                        ? <a href={href}><img src={url} alt={title} /></a>
                                                                        : <img src={url} alt={title} />}
                                                                </div>
                                                            </Grid>
                                                        );
                                                    })
                                                }
                                            </Grid>
                                        );
                                    }
                                    case 'seo_copy':
                                        return <SeoBlock data={item.seo_copy} />;
                                    case 'wyng':
                                        return <WyngModule data={item.wyng} />;
                                    default:
                                        return null;
                                }
                            })
                        }
                        <DisclaimerBlock disclaimer={disclaimerData} disclaimerRef={disclaimerRef}  />
                    </Grid>
                </div>
            </>
        );
    }
    return <ProductsFallbackContainer />;
};

TemplateFareWell.propTypes = {
    classes: object.isRequired,
    templateData: shape({
        seo: shape({
            breadcrumb: arrayOf(
                shape({
                    title: string.isRequired,
                    href: string.isRequired,
                }).isRequired,
            ),
            keywords: string,
            meta_description: string,
            seo_searchable: bool.isRequired,
            page_title: string,
        }).isRequired,
        category_blocks: arrayOf(
            shape({
                main_banner: shape({
                    link: shape({
                        href: string.isRequired,
                        title: string.isRequired,
                    }),
                    desktop_image: shape({
                        url: string.isRequired,
                    }),
                }),
                sub_banner: shape({
                    desktop_image: shape({
                        url: string.isRequired,
                        title: string.isRequired,
                    }),
                }),
                bottom_banners: shape({
                    title: string.isRequired,
                    banners: arrayOf({
                        link: shape({
                            href: string.isRequired,
                        }),
                        desktop_image: shape({
                            url: string.isRequired,
                            title: string.isRequired,
                        }),
                    }),
                }),
            }),
        ),
    }).isRequired,
    trackEvent: func.isRequired,
    presentationFamily: string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(trackEventActions, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(TemplateFareWell);
