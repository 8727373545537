/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object, shape, array } from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const styles = (theme) => ({
    innerSubBanner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        maxWidth: '60%',
        margin: 'auto',
        textAlign: 'center',
        lineHeight: 1.5,
        [theme.breakpoints.down('600')]: {
            maxWidth: '95%',
        },
    },
    subBanner: {
        padding: '10px 0 10px',
        '&:nth-child(odd)': {
            paddingRight: '10px',

        },
        '&:nth-child(even)': {
            paddingLeft: '10px',
        },
        [theme.breakpoints.down('600')]: {
            '&:nth-child(odd)': {
                paddingRight: '0',

            },
            '&:nth-child(even)': {
                paddingLeft: '0',
            },
        },
    },
    name: {
        fontSize: '20px',
        fontWeight: 800,
        margin: 0,
        [theme.breakpoints.down('600')]: {
            fontSize: '16px',
        },
    },
    desc: {
        fontSize: '18px',
        margin: '25px 0',
        [theme.breakpoints.down('600')]: {
            fontSize: '14px',
        },

    },
    shopNow: {
        display: 'flex',
        fontSize: '20px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        [theme.breakpoints.down('600')]: {
            fontSize: '16px',
        },
    },
    minHeight: {
        boxShadow: '2px 2px 8px rgba(0,0,0,0.05)',
        minHeight: '420px',
        '& img': {
            width: '100%',
        },
        [theme.breakpoints.down('600')]: {
            minHeight: 'auto',
            '& img': {
                display: 'block',
            },
        },
    },
    subBannerLink: {
        textDecoration: 'none',
    },
    imageSection: {
        height: '100%',
        [theme.breakpoints.down('600')]: {
            height: 'auto',
        },
    },
    arrowIcon: {
        [theme.breakpoints.down('600')]: {
            height: '22px',
            width: '20px',
        },
    },
});
class SubnavImageSubCollectionBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showImage: false,
        };
    }

    rolloverImageHoverOff(showImageCheck)  {
        if (showImageCheck) {
            this.setState({
                showImage: false,
            });
        }
    }

    rolloverImageHoverOn(showImageCheck) {
        if (showImageCheck) {
            this.setState({
                showImage: true,
            });
        }
    }

    render() {
        const { classes, subCollectionBanners: { banner_format = [] } = {} } = this.props;
        const { showImage } = this.state;
        return (
            <>
                <Grid container>
                    {banner_format ? banner_format.map((data) => (
                        <Grid
                            key={data.name}
                            item
                            xs={12}
                            sm={6}
                            className={classes.subBanner}
                            onMouseEnter={() => this.rolloverImageHoverOn(data?.rollover_image !== null)}
                            onMouseLeave={() => this.rolloverImageHoverOff(data?.rollover_image !== null)}
                        >
                            <Link className={classes.subBannerLink} to={data?.link?.href ? data.link.href : '/'}>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    {
                                        data?.rollover_image !== null && showImage
                                            ? (
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <div>
                                                            <img src={data?.rollover_image?.url} alt="rolloverImage" style={{ width: '100%', display: 'block' }} />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )
                                            : (
                                                <Grid container className={classes.minHeight}>
                                                    <Grid style={{ background: `${data?.background_color}`,  color: `${data?.product_info_font_color}` }} item xs={6}>
                                                        <div className={classes.innerSubBanner}>
                                                            <p className={classes.name}>{data?.name}</p>
                                                            <p className={classes.desc}>{data?.additional_copy}</p>
                                                            {data?.price ? <p>no data</p> : null}
                                                            <div className={classes.shopNow}>
                                                                {data?.shop_now_copy}
                                                                <PlayArrowIcon className={classes.arrowIcon} />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <img classes={classes.imageSection} src={data?.image?.url} alt="subCollection" />
                                                    </Grid>
                                                </Grid>
                                            )
                                    }
                                </Grid>
                            </Link>
                        </Grid>
                    )) : null}
                </Grid>
                <hr />
            </>
        );
    }
}

SubnavImageSubCollectionBanner.propTypes = {
    classes: object.isRequired,
    subCollectionBanners: shape({
        banner_format: array.isRequired,
    }),
};
SubnavImageSubCollectionBanner.defaultProps = {
    subCollectionBanners: {},
};

export default withStyles(styles)(SubnavImageSubCollectionBanner);
