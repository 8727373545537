/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    object, shape, arrayOf, string, array, func, bool,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import mbpLogger from 'mbp-logger';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// components
import MobileBanners from './MobileBanners';
import MobileBannersFF from './MobileBannersFF';
import { trackEvent as track } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import SEMTemplateDropdown from '../../../../../GraphqlCommonComponents/GraphqlSEMTemplateDropdown/SEMTemplateDropdown';
// helpers
import booleanGetCutoffTimes from '../../../../../../../helpers/booleanGetCutoffTimes';
import GiftBlockBottom from '../DesktopGiftBlock/Partials/GiftBlockBottom';

const styles = (theme) => ({
    mainContainer: {
        width: '100%',
        maxWidth: '1440px',
        margin: 'auto',
    },
    topLogo: {
        '& h1': {
            color: theme.palette.flowerGift.topLogoText.color,
            fontSize: '18px',
            textAlign: 'center',
            padding: '5px 20px',
        },
    },
    bannerContainer: {
        margin: '0 8px 18px 8px',
    },
    bottomContainer: {
        width: '80%',
        margin: '0 auto',
        '& img': {
            height: '100%',
        },
    },
});

/* eslint-disable react/prop-types */ // TODO refactor as part of PWA-2848, render f(x) pattern doesnt like proptypes
const MobileGiftBlock = ({
    classes, block, SEMsubcollections, trackEvent, isMobile, featureFlags,
}) => {
    const [tileIndex, setTileIndex] = useState(-1);
    const tileHandler = (idx = -1) => {
        if (idx >= 0) {
            setTileIndex(idx);
        } else if (idx < 0) {
            setTileIndex(-1);
        }
    };

    const sameDayAndBestSellerTileHelper = (cutoff_banner, idx) => (idx === 0 && booleanGetCutoffTimes() && cutoff_banner?.image?.url);

    const key = Object.keys(block)[0];
    const renderHeader = () => {
        // Retrive header data for mobile
        const { header: { copy = '' } } = block;
        if (!copy) {
            mbpLogger.logDebug({
                message: `No banner_group returned for Mobile Gift Block Headers block \n copy data ${copy}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
            return null;
        }
        return (
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.topLogo}>
                        <h1>
                            {copy}
                        </h1>
                    </div>
                </Grid>
            </Grid>
        );
    };

    const renderBanners = () => {
        const { banners: { banner = [] } = {} } = block;
        // TODO PWA-2848 comes with mobile & desktop, should convert query to using viewport to avoid
        const [banner_group = ''] = banner?.filter((viewport) => viewport?.name === 'Mobile Banners');
        const cutoff_banner = banner_group?.cuttoff_image;
        if (!banner_group) {
            mbpLogger.logDebug({
                message: `No banner_group returned for Mobile Gift Block Banners block \n banner_group data ${banner_group}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
            return null;
        }

        if (featureFlags['is-sem-template-dropdown-enabled']) {
            const mobileBanners = banner_group?.banner_group.map((tile, i) => {
                const {
                    link: { title = '', href = ''  } = '', image: { url = '' } = '',
                } = tile;
                if (!title || !href || !url) {
                    return null;
                }

                if (sameDayAndBestSellerTileHelper(cutoff_banner, i)) {
                    return (
                        <MobileBannersFF
                            key="mobile-sem-cutoff-img"
                            title={cutoff_banner.link?.title}
                            imgUrl={cutoff_banner.image?.url}
                            imgTitle={cutoff_banner.link?.title}
                            index={i}
                            tileHandler={tileHandler}
                            showOverlay={tileIndex === i}
                            trackEvent={trackEvent}
                        />
                    );
                }
                if (i % 2 === 0) {
                    return (
                        <MobileBannersFF
                            key={`${title}`}
                            title={title}
                            imgUrl={url}
                            imgTitle={title}
                            index={i}
                            tileHandler={tileHandler}
                            showOverlay={tileIndex === i}
                            trackEvent={trackEvent}
                        />
                    );
                }
                if (i % 2 !== 0) {
                    return (
                        <>
                            <MobileBannersFF
                                key={`${title}`}
                                title={title}
                                imgUrl={url}
                                imgTitle={title}
                                index={i}
                                tileHandler={tileHandler}
                                showOverlay={tileIndex === i}
                                trackEvent={trackEvent}
                            />
                            <SEMTemplateDropdown
                                twoSEMsubcollections={{
                                    subcollection1: SEMsubcollections[i - 1],
                                    subcollection2: SEMsubcollections[i],
                                }}
                                tileIndex={tileIndex}
                                tileHandler={tileHandler}
                                trackEvent={trackEvent}
                                amount={3}
                                isMobile={isMobile}
                            />
                        </>
                    );
                }

                return null;
            });

            return (
                <div className={classes.bannerContainer}>
                    <Grid container>
                        {mobileBanners}
                    </Grid>
                </div>
            );
        }

        return (
            <div className={classes.bannerContainer}>
                <Grid container>
                    {banner_group?.banner_group?.map((bannerList, i) => {
                        const { link: { title, href  }, image: { url }  } = bannerList;
                        if (i === 0 && booleanGetCutoffTimes() && cutoff_banner?.image?.url) {
                            return <MobileBanners key="mobile-sem-cutoff-img" title={cutoff_banner.link?.title} link={cutoff_banner.link?.href} imgUrl={cutoff_banner.image?.url} imgTitle={cutoff_banner.link?.title} />;
                        }
                        return (
                            <MobileBanners key={`${title}`} title={title} link={href} imgUrl={url} imgTitle={title} />
                        );
                    }) }
                </Grid>
            </div>
        );
    };

    const renderBottomBanner = () => {
        const { bottom: { bottom_banner = [] } = {} } = block;
        const { image = '' } = bottom_banner?.[1];
        if (!image) {
            mbpLogger.logDebug({
                message: `No bottom_banner returned for Mobile Gift Block Bottom block \n image data ${image}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
            return null;
        }
        return (
            <div className={classes.bottomContainer}>
                <Grid container>
                    <GiftBlockBottom image={image} />
                </Grid>
            </div>
        );
    };

    switch (key) {
        case 'header':
            return renderHeader();
        case 'banners':
            return renderBanners();
        case 'bottom':
            return renderBottomBanner();

        default:
            mbpLogger.logError({
                message: `Unrecognized page_block for Template Collection Sem Page Mobile Gift Block. \n Please remove from ContentStack \n Block name key = ${key}, Block Data = ${block}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
            return null;
    }
};

MobileGiftBlock.propTypes = {
    classes: object.isRequired,
    block: shape({
        header: shape({
            copy: string,
        }),
        banners: shape({
            banner: arrayOf(shape({
                banner_group: array,
            })),
        }),
    }).isRequired,
    SEMsubcollections: array.isRequired,
    trackEvent: func.isRequired,
    isMobile: bool.isRequired,
    featureFlags: object.isRequired,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(MobileGiftBlock));
