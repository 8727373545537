/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
    object, shape, arrayOf, string,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import replaceSpecialChar from '../../../../../../helpers/replaceSpecialChar';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            margin: '0 auto',
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0 auto',
            width: '100%',
        },
    },
    containerBorder: {
        borderTop: `1px solid ${theme.palette?.colorNeutral60 || '#9E9E9E'}`,
        borderBottom: `1px solid ${theme.palette?.colorNeutral60 || '#9E9E9E'}`,
        [theme.breakpoints.down('md')]: {
            borderTop: 0,
        },
    },
    mainInfoBlock: {
        display: 'flex',
        justifyContent: 'space-around',
        textAlign: 'center',
        margin: '20px 0px',
        '& div': {
            justifyContent: 'center',
        },
        [theme.breakpoints.down(769)]: {
            display: 'block',
            margin: '0 60px 20px 60px',
        },
        [theme.breakpoints.down(600)]: {
            display: 'block',
            margin: '0px 20px',
        },
    },
    mainInfoBlockContainer: {
        borderRight: `1px solid ${theme.palette?.colorNeutral60 || '#9E9E9E'}`,
        '&:last-child': {
            borderRight: 0,
        },
        [theme.breakpoints.up('md')]: {
            '&:first-child': {
                flexBasis: '63%',
                maxWidth: '63%',
            },
            '&:last-child': {
                borderRight: 0,
                flexBasis: '37%',
                maxWidth: '37%',
            },
        },
        [theme.breakpoints.down(769)]: {
            borderRight: 0,
            borderBottom: '1px solid #000',
            padding: '30px',
            '&:last-child': {
                borderBottom: 0,
            },
        },
        [theme.breakpoints.down(600)]: {
            padding: '5px 0px',
            borderBottom: 0,
            borderTop: `1px dotted ${theme.palette.cms?.black || '#000'}`,
            '&:nth-child(2)': {
                borderTop: 0,
            },
            '&:first-child': {
                borderTop: 0,
            },
            '&:nth-child( odd )': {
                borderRight: 0,
            },
            '&:last-child': {
                borderRight: 0,
            },
        },
    },
    mainInfoBlockTiles: {
        textAlign: 'center',
        '& img': {
            margin: '0 auto 0px auto',
            display: 'block',
            width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            '& img': {
                width: 'initial',
            },
        },
        [theme.breakpoints.down(768)]: {
            marginBottom: 0,
            padding: '0 40px',
        },
        [theme.breakpoints.down(600)]: {
            padding: 0,
            '& img': {
                width: '100%',
                maxWidth: 320,
                margin: '0 auto 10px auto',
            },
        },
    },
    communityLogoWidth: {
        '& img': {
            width: 'auto',
        },
    },
    tilesTitle: {
        fontSize: '19px',
        lineHeight: '28px',
        fontWeight: 900,
        [theme.breakpoints.up(600)]: {
            fontFamily: 'Roboto Slab, san-serif',
            lineHeight: '36px',
        },
    },
    tilesSubHeading: {
        fontSize: '17.2px',
        fontFamily: 'Roboto Slab, san-serif',
        lineHeight: '20px',
        [theme.breakpoints.down(768)]: {
            fontSize: 14,
            fontFamily: 'LatoMedium, sans-serif',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '320px',
            margin: '0 auto',
            fontSize: '13.7px',
            fontWeight: 900,
        },
        '& p': {
            margin: 0,
        },
        '& a': {
            textDecoration: 'none',
        },
        '& strong': {
            color: theme.palette.cms?.primary || theme.palette?.colorPrimary,
        },
    },
    title: {
        '& h1, h2, & h3, & h4, & h5, & h6': {
            fontWeight: 'normal',
            fontFamily: 'PlayfairDisplayRegular, serif',
            textAlign: 'center',
            fontSize: '26px',
            [theme.breakpoints.down(769)]: {
                marginBottom: 0,
            },
            [theme.breakpoints.down(600)]: {
                marginBottom: 15,
                fontSize: '16.8px',
            },
        },
    },
    infoBlockCtaBtn: {
        color: theme.palette.cms?.black || '#000',
        margin: '5px 0px 20px',
        display: 'block',
        fontWeight: 'bold',
        [theme.breakpoints.down(600)]: {
            margin: '0px 0px 15px',
            fontSize: '14px',
        },
    },
    infoBlockTileImage: {
        [theme.breakpoints.down(600)]: {
            '& img': {
                width: '85px',
            },
        },
    },
    mainInfoBlockSection: {
        width: '100%',
        maxWidth: '1190px',
        display: 'flex',
        justifyContent: 'space-around',
        textAlign: 'center',
        margin: '30px auto',
        [theme.breakpoints.down(769)]: {
            display: 'block',
            width: '100%',
        },
        '& div': {
            justifyContent: 'center',
            flexWrap: 'nowrap',
            [theme.breakpoints.down(769)]: {
                display: 'block',
                width: '100%',
                margin: '0 auto',
                maxWidth: '85%',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '275px',
            },
        },
    },
    iconDivider: {
        [theme.breakpoints.up(769)]: {
            borderRight: `1px solid ${theme?.palette?.cms?.grey || '#909090'}`,
            '&:last-child': {
                borderRight: 'none',
            },
        },
        [theme.breakpoints.down(769)]: {
            borderBottom: `3px solid ${theme?.palette?.cms?.grey || '#909090'}`,
            '&:last-child': {
                borderBottom: 'none',
            },
        },
    },
    iconWidth: {
        textAlign: 'center',
        padding: '0 8px',
        '& img': {
            width: '100px',
            display: 'block',
            margin: '0 auto',
        },
        [theme.breakpoints.down(769)]: {
            padding: '25px 0px',
        },
    },
});
const InfoBlock = ({
    classes, infoBlockData,
}) => {
    if (!infoBlockData) {
        return null;
    }
    let getSubscriptionUrl = false;
    if (typeof window !== 'undefined') {
        getSubscriptionUrl = window?.location?.pathname.includes('/subscriptions');
    }
    const mainInfoBlockRender = getSubscriptionUrl ? classes.mainInfoBlockSection : classes.mainInfoBlock;
    const seed = useUIDSeed();
    const infoTilesData = infoBlockData?.tile || [];
    const tilesToDisplay = infoBlockData?.tiles_to_display;
    const gridKey = (key = 1) => (key <= 6 ? 12 / key : 2);
    return (
        <Grid container className={tilesToDisplay === 2 ? `${classes.container} ${classes.containerBorder}` : classes.container}>
            <Grid item xs={12}>
                {infoBlockData?.title && <div className={classes.title}><h2>{infoBlockData.title}</h2></div>}
                <div className={mainInfoBlockRender}>
                    <Grid container>
                        {infoTilesData?.map((tileItem) => {
                            const tileBlockStyle = {
                                backgroundColor: tileItem?.tile_background_color || '#FFFFFF',
                                color: tileItem?.text_color || '#000000',
                            };
                            // for Banner Button
                            const ctaButton = tileItem?.cta_button?.title || '';
                            const ctaButtonURL = tileItem?.cta_button?.href || '';
                            const isCommunityLogo = ctaButtonURL.includes('/connection-communities');
                            return (
                                <Grid key={seed(tileItem)} item xs={tilesToDisplay === 2 ? 12 : 4} md={gridKey(tilesToDisplay)} lg={gridKey(tilesToDisplay)} className={`${tileItem?.enable_border && classes.mainInfoBlockContainer} ${classes.mainInfoBlockPadding} ${getSubscriptionUrl && classes.iconDivider}`}>
                                    <div className={getSubscriptionUrl ? classes.iconWidth : `${classes.mainInfoBlockTiles} ${isCommunityLogo ? classes.communityLogoWidth : ''}`}>
                                        {tileItem?.desktop_image?.url && (
                                            <div className={tilesToDisplay !== 2 && classes.infoBlockTileImage}>
                                                {(ctaButton === '' && ctaButtonURL) ? (
                                                    <Link to={ctaButtonURL}>
                                                        <img src={tileItem?.desktop_image?.url} alt="Desktop" />
                                                    </Link>
                                                ) : (
                                                    <img src={tileItem?.desktop_image?.url} alt="Desktop" />
                                                )}
                                            </div>
                                        )}
                                        {tileItem?.header && <h3 className={classes.tilesTitle}>{tileItem?.header}</h3>}
                                        {tileItem?.sub_header && <ReactMarkdown className={classes.tilesSubHeading} escapeHtml={false} source={replaceSpecialChar(tileItem?.sub_header)} />}
                                        {ctaButtonURL && ctaButton && (
                                            <Link
                                                style={tileBlockStyle}
                                                className={classes.infoBlockCtaBtn}
                                                to={ctaButtonURL}
                                            >
                                                {ctaButton}
                                            </Link>
                                        )}
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};
InfoBlock.propTypes = {
    classes: object,
    infoBlockData: shape({
        tile: arrayOf(
            shape({
                header: string,
                sub_header: string,
                text_color: string,
                tile_background_color: string,
            }),
        ),
    }),
};
InfoBlock.defaultProps = {
    classes: {},
    infoBlockData: {},
};
export default (withStyles(styles)(InfoBlock));
