/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
    bool,
    number, object, shape, string,
} from 'prop-types';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles((theme) => ({
    tileImage: {
        padding: '0 0.625rem',
        margin: '0.438rem 0',
        '& img': {
            width: '100%',
            display: 'block',
        },
    },
    tileTitle: {
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: '600',
        textTransform: 'uppercase',
        padding: '0.5rem 0.25rem',
        color: theme.palette.cms?.black || '#000000',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.813rem',
        },
    },
}));

const TileBlock = ({
    block, titleStyle, noOfTiles, headingDecoration, location, isBirthdayLPLayout,
}) => {
    const classes = useStyles();

    let gridKey = 6;
    if (noOfTiles === 6) {
        gridKey = 2;
    }
    if (noOfTiles === 3) {
        gridKey = 4;
    }
    if (noOfTiles === 4) {
        gridKey = 3;
    }

    const image = block?.image || {};
    const link = block?.link || {};
    const title = block?.title || '';
    const eventAction = block?.tracking_event_action || '';
    const eventCategory = block?.tracking_event_category || '';
    const eventLabel = block?.tracking_event_label || '';

    const mobileGrid = (headingDecoration && location?.pathname !== '/get-well-gift-ideas') || !isBirthdayLPLayout ? 6 : 12;

    return (
        <Grid item xs={mobileGrid} md={gridKey} lg={gridKey} sm={gridKey} className={classes.tileImage}>
            {link?.href ? (
                <LinkOrATag
                    trackingEventAction={eventAction}
                    trackingEventCategory={eventCategory}
                    trackingEventLabel={eventLabel}
                    isTrackEventEnabled
                    href={link.href}
                >
                    <img src={image?.url} alt={link.title} />
                    {title && <div style={titleStyle} className={classes.tileTitle}>{title}</div>}
                </LinkOrATag>
            )
                : (
                    <>
                        <img src={image?.url} alt={link.title} />
                        {title && <div style={titleStyle} className={classes.tileTitle}>{title}</div>}
                    </>
                )}
        </Grid>
    );
};

TileBlock.propTypes = {
    block: shape({
        image: object.isRequired,
        link: object.isRequired,
        title: string.isRequired,
    }).isRequired,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    titleStyle: object.isRequired,
    noOfTiles: number.isRequired,
    headingDecoration: bool.isRequired,
    isBirthdayLPLayout: bool.isRequired,
};

export default TileBlock;
