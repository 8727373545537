/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, CardContent } from '@material-ui/core';
import { object, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GraphqlMobileCategoryImage from './Partials/GraphqlMobileCategoryImage';
import CUSTOM_BREAKPOINTS from '../../../../../helpers/customBreakpoints';

const uniqueKeys = ['mobileCategory1', 'mobileCategory2', 'mobileCategory3'];

const styles = (theme) => ({
    root: {
        boxShadow: 'none',
        margin: '0 0 10px 0',
        borderRadius: '0px',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            display: 'none',
        },
    },
    cardCompPadding: {
        paddingTop: '7px',
        '&:last-child': {
            paddingBottom: '10px',
        },
    },
    posRel: {
        position: 'relative',
    },
    gridMarginRight: {
        '&:not(:last-child)': {
            marginRight: '10px',
        },
    },
});

const GraphqlMobileCategory = ({
    classes,
    data: { mobile_tiles_group },
    presentationFamily,
}) => (
    <Card className={classes.root}>
        <CardContent className={classes.cardCompPadding}>
            <Grid container item xs={12}>
                {mobile_tiles_group.map((d, i) => (
                    <Grid key={uniqueKeys[i]} className={`${classes.gridMarginRight} ${classes.posRel}`} item xs>
                        <GraphqlMobileCategoryImage
                            href={d.link.href}
                            imgSrc={d.mobile_image.url}
                            title={d.link.title}
                            presentationFamily={presentationFamily}
                            trackingEventAction={d.tracking_event_action}
                            trackingEventCategory={d.tracking_event_category}
                            trackingEventLabel={d.tracking_event_label}
                        />
                    </Grid>
                ))}
            </Grid>
        </CardContent>
    </Card>
);

GraphqlMobileCategory.propTypes = {
    data: object.isRequired,
    classes: object.isRequired,
    presentationFamily: string.isRequired,
};

export default withStyles(styles)(GraphqlMobileCategory);
