/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, func, bool,
} from 'prop-types';
import DesktopMobile from '../../../../../../helpers/DesktopMobile/DesktopMobile';
import DesktopHero from './DesktopHero';
import MobileHero from './MobileHero';

const MainHero = ({ data, fontFamily, isMotherDayPage }) => (
    <DesktopMobile
        mobile={() => (
            <MobileHero data={data} fontFamily={fontFamily} isMotherDayPage={isMotherDayPage} />
        )}
        desktop={() => (
            <DesktopHero data={data} fontFamily={fontFamily} isMotherDayPage={isMotherDayPage} />
        )}
        breakpoint={600}
        ignoreHover
    />
);
MainHero.propTypes = {
    data: shape({
        header: string.isRequired,
        sub_header: string.isRequired,
        mobile_header: string.isRequired,
        mobile_sub_header: string.isRequired,
        image: shape({
            url: string.isRequired,
        }),
        mobile_image: shape({
            url: string.isRequired,
        }.isRequired),
        link: shape({
            href: string.isRequired,
        }.isRequired),
    }).isRequired,
    fontFamily: func.isRequired,
    isMotherDayPage: bool.isRequired,
};

export default MainHero;
