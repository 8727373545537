/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object, string, number, func,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    bannerInner: {
        padding: '0 20px 40px 20px',
    },
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: '22px',
        },
    },
    subCopyText: {
        fontSize: 16,
        lineHeight: 1.2,
        padding: '5px 8px',
    },
    innerCopy: {
        width: '100%',
        position: 'absolute',
        top: '0',
        minHeight: 280,
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.cms?.white || '#ffffff',
        flexDirection: 'column',
        lineHeight: '30px',
        textAlign: 'center',
        textShadow: '1px 1px 6px rgba(0,0,0,0.1)',
    },
    bannerInnerImg: {
        position: 'relative',
    },
    bannerImg: {
        display: 'block',
        width: '100%',
    },
    shopBtn: {
        display: 'block',
        padding: '4px 10px',
        border: '2px solid',
        width: '150px',
        margin: '0 auto',
        marginTop: '15px',
        fontSize: '14px',
        textTransform: 'uppercase',
    },
});

const GiftBlockBanner = ({
    classes, index, title, link, imgUrl, imgTitle, subCopy, subCopyStyle, titleFontStyle, buttonFontStyle, buttonHoverStyle, isHoverColor, setHoverColor,
}) => (
    <Grid item xs={6}>
        <div className={classes.bannerInner}>
            <div className={classes.bannerInnerImg}>
                <Link to={link}>
                    <img src={imgUrl} alt={imgTitle} className={classes.bannerImg} />
                    <div className={classes.innerCopy}>
                        <div style={titleFontStyle} className={classes.title}>{title}</div>
                        <div style={subCopyStyle} className={classes.subCopyText}>{subCopy}</div>
                        <span
                            onFocus={() => setHoverColor(index)}
                            onBlur={() => setHoverColor('')}
                            onMouseOver={() => setHoverColor(index)}
                            onMouseLeave={() => setHoverColor('')}
                            style={isHoverColor === index ? buttonHoverStyle : buttonFontStyle}
                            className={classes.shopBtn}
                        >
                            Shop Now
                        </span>
                    </div>
                </Link>
            </div>
        </div>
    </Grid>
);

GiftBlockBanner.propTypes = {
    classes: object.isRequired,
    title: string.isRequired,
    index: number.isRequired,
    link: string.isRequired,
    imgUrl: string.isRequired,
    subCopy: string.isRequired,
    titleFontStyle: object,
    subCopyStyle: object,
    buttonFontStyle: object,
    imgTitle: string.isRequired,
    isHoverColor: number.isRequired,
    setHoverColor: func.isRequired,
    buttonHoverStyle: object,
};
GiftBlockBanner.defaultProps = {
    titleFontStyle: {},
    subCopyStyle: {},
    buttonFontStyle: {},
    buttonHoverStyle: {},
};
export default withStyles(styles)(GiftBlockBanner);
