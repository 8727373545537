/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core';
import { string, object } from 'prop-types';

const styles = (theme) => ({
    heroContainer: {
        width: '75%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    topLogoImg: {
        '& img': {
            maxWidth: '65px',
            display: 'block',
            marginRight: '10px',
        },
        [theme.breakpoints.down('md')]: {
            width: '28%',
            margin: 'auto',
            padding: '20px 10px 10px 50px',
        },
    },
    topContent: {
        '& h1': {
            color: theme.palette.flowerGift.topLogoText.color,
            width: '100%',
            fontSize: '23px',
            fontWeight: 'normal',
            textAlign: 'center',
            padding: '20px 0',
            [theme.breakpoints.down('md')]: {
                width: '80%',
                padding: '20px 0',
                fontSize: '20px',
            },
        },
    },
});

const GiftBlockHeader = ({
    classes, url, title, copy,
}) => (
    <div className={classes.heroContainer}>
        <div className={classes.topLogoImg}>
            <img src={url} alt={title} />
        </div>
        <div className={classes.topContent}>
            <h1>
                {copy}
            </h1>
        </div>
    </div>
);

GiftBlockHeader.propTypes = {
    classes: object.isRequired,
    url: string.isRequired,
    title: string.isRequired,
    copy: string.isRequired,
};

export default withStyles(styles)(GiftBlockHeader);
