/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import mbpLogger from 'mbp-logger';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, string, array, func,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { useSelector } from 'react-redux';
import customBreakpoints from '../../../../../../../helpers/customBreakpoints';
import ImageSidebanner from '../../../../../GraphqlTemplatePage/Partials/TemplatePageSales/ImageSidebanner';
import SnipeRenderQuery from '../../../../../GraphqlProductPage/Partials/common/SnipeRenderQuery';
import PriceBreakDownUi from '../GraphqlSimpleProduct/DesktopSimpleProduct/PriceBreakDownUi';
import { getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getEnterpriseId } from '../../../../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import getPriceFromEngine from '../../../../../../../../apis/product-apis/priceEngine';
import { withAuth } from '../../../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import PriceRange from '../GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import { getPriceArrays } from '../../../../../../../helpers/DynamicPricing/getPriceArrays';
import { getIsAuthenticatedStatus, getIsPassportUserLoggedIn } from '../../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPassportData } from '../../../../../../../../state/ducks/Passport/Passport-Selectors';
import validatePassport from '../../../../../../../helpers/validatePassport';
import { getPassportSubscriptionStatus } from '../../../../../../../../state/ducks/Common/Common-Selectors';

const styles = (theme) => ({
    topProduct: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width: '96%',
            margin: '0 auto',
        },
    },
    hero: {
        width: '100%',
        marginBottom: '2em',
        [theme.breakpoints.up(1025)]: {
            display: 'none',
        },
    },
    topProductContainer: {
        width: '100%',
        margin: '10px auto',
        border: `1px solid ${theme.palette.mobileMenuBG?.child}`,
        boxShadow: `0 5px 5px ${theme.palette.imageCardBoxShadow}`,
        [theme.breakpoints.down(600)]: {
            margin: '10px',
        },

    },
    dealOfWeek: {
        maxWidth: '50%',
        textAlign: 'center',
        padding: '0 25px',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            width: '46%',
            margin: '0',
        },
        [theme.breakpoints.down(600)]: {
            padding: '0 10px',
            width: '50%',
        },
    },
    dealWeekheading: {
        textAlign: 'center',
    },
    dealWeekInner: {
        fontSize: '25px',
        fontWeight: 500,
        [theme.breakpoints.down(600)]: {
            fontSize: 15,
        },
    },
    productName: {
        fontWeight: 'bold',
        fontSize: '13px',
        color: theme.palette.black,
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            fontSize: '.95rem',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '13px',
        },
    },
    productPrice: {
        color: theme.palette.black,
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            fontSize: '0.9rem',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: 14,
        },
    },
    crossedOutRetailPrice: {
        textDecoration: 'line-through',
        color: theme.palette.colorAlert,
        fontWeight: '400',
    },
    salePrice: {
        color: theme.palette.pdp.salePrice || '#a1001a',
    },
    shopButton: {
        textAlign: 'center',
        fontSize: 16,
        textTransform: 'uppercase',
        [theme.breakpoints.down(600)]: {
            fontSize: 14,
        },
    },
    shopButtonDiv: {
        textAlign: 'center',
        marginTop: 15,
        padding: '10px 0px',
        maxWidth: 150,
        margin: '5px auto',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            width: '90%',
            marginTop: '.5em',
        },
        [theme.breakpoints.down(600)]: {
            padding: '3px 0px',
            width: '100%',
        },
    },

    productLink: {
        textDecoration: 'none',
    },
    featuredImgContainer: {
        position: 'relative',
        maxWidth: '120%',
        '& img': {
            width: '100%',
        },
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            marginRight: 0,
            maxWidth: '50%',
        },
    },
    snipe: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
    },
    productWrapper: {
        display: 'flex',
        padding: '0 25px',
        [theme.breakpoints.down(600)]: {
            padding: '15px 10px',
        },
    },
    divider: {
        backgroundColor: theme.palette.grey8,
        border: 'none',
        height: '1px',
        width: '35%',

    },
});
let saleEntryData = {};
const getBlock = (block) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    switch (blockKey) {
        case 'sale_entry':
            saleEntryData = blockObj;
            return <ImageSidebanner bannerData={saleEntryData} />;
        default:
            return null;
    }
};
const GraphqlDealOfWeek = ({
    classes, firstProduct: {
        seo: { url = '' } = {},
        partNumber = '',
        image: {
            name: imageName = '', altText = '', path = '', snipe = '',
        } = {}, name = '', skuPriceRange = {},
    } = {}, pageBlocks,
}) => {
    const seed = useUIDSeed();
    // Dynamic price
    const enterpriseId = useSelector(getEnterpriseId);
    const featureFlags = useSelector(getFeatureFlags);
    const [newPriceData, setNewPriceData] = useState(null);
    const isDynamicPriceEnabled = featureFlags['is-dynamic-pricing-enabled'];
    const [dynamicPriceLoading, setDynamicPriceLoading] = useState(!!isDynamicPriceEnabled);
    const isPassportUser = useSelector(getIsPassportUserLoggedIn);

    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const passportData = useSelector(getPassportData);
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const whichCollectionPageRestructure = featureFlags['which-collection-page-restructure'];
    const collectionRestructureVariantB = whichCollectionPageRestructure?.toLowerCase() === 'variantb';

    const isPassportAdded = useSelector(getPassportSubscriptionStatus);
    let customerTypeValue = [];
    if ((isPassportMember && isPassportUser) || isPassportAdded) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Passport',
        }];
    } else if (isAuthenticated) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Registered',
        }];
    }
    if (!whichCollectionPageRestructure) {
        return <></>;
    }
    useEffect(() => {
        async function fetchPriceFromEngine() {
            if (!isDynamicPriceEnabled) {
                setDynamicPriceLoading(false);
                return;
            }

            if  (!enterpriseId) {
                setDynamicPriceLoading(false);
                return;
            }

            const payload = {
                enterpriseId,
                products: [{
                    partNumber,
                }],
                variants: customerTypeValue,
            };

            getPriceFromEngine({}, null, payload, featureFlags['is-price-engine-batching-enabled'])
                .then(({ data }) => {
                    setDynamicPriceLoading(false);
                    setNewPriceData(data?.products);
                })
                .catch((ex) => {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        jsError: ex,
                        message: `DesktopSimpleProduct: fetch prices failed...${ex.message}`,
                        enterpriseId,
                    });
                    setDynamicPriceLoading(false);
                });
        }

        fetchPriceFromEngine();
    }, [
        featureFlags,
        setNewPriceData,
        enterpriseId,
        isPassportAdded,
    ]);

    const getPrices = (priceData) => {
        const priceArrays = getPriceArrays(priceData);
        if (!priceArrays?.sale?.length && !priceArrays?.retail?.length) {
            return null;
        }
        return priceArrays;
    };

    return (
        <div className={classes.topProductContainer}>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    { pageBlocks.map((block) => <div key={seed(block)}> {getBlock(block)} </div>)}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.productWrapper}>
                    <div className={classes.topProduct}>
                        <Link title={name} to={url} className={classes.productLink}>
                            <div className={classes.featuredImgContainer}>
                                <img
                                    src={`${path}${imageName}x.jpg`}
                                    alt={altText || 'deal of the week'}
                                />
                                {snipe ? ( // if snipe data exsist display snipe img
                                    <img
                                        className={classes.snipe}
                                        src={`${path}${snipe}_z.gif`}
                                        alt={altText}
                                    />
                                ) : null}
                                {partNumber && <SnipeRenderQuery partNumber={partNumber} />}
                            </div>
                        </Link>
                        <div className={classes.dealOfWeek}>
                            <div className={classes.dealWeekheading}>
                                <div className={classes.dealWeekInner} style={{ color: saleEntryData?.main_image_text_color }}>
                                    {saleEntryData?.main_image_text}
                                </div>
                                <hr className={classes.divider} />
                            </div>
                            <div
                                className={classes.botttomContainer}
                            >
                                <div className={classes.botttomContainerInner}>
                                    <h3 className={classes.productName}>{name}</h3>
                                    <div className={classes.productPrice}>
                                        <PriceRange
                                            skuPriceRange={isDynamicPriceEnabled && (newPriceData || []).find((item) => item.partNumber === partNumber)?.prices ? getPrices((newPriceData || []).find((item) => item.partNumber === partNumber)?.prices) : skuPriceRange}
                                            displayPrice={!dynamicPriceLoading}
                                            colorMatchTitle
                                            noFromText
                                            collectionRestructureEnabled={collectionRestructureVariantB}
                                        />
                                    </div>
                                    <PriceBreakDownUi dynamicPriceData={(newPriceData || []).find((item) => item.partNumber === partNumber)} />
                                    <Link title={name} to={url} className={classes.productLink}>
                                        <div className={classes.shopButtonDiv} style={{  backgroundColor: saleEntryData?.button_background_color?.color, color: saleEntryData?.font_color?.color }}>
                                            <span
                                                className={classes.shopButton}
                                            > <strong>Shop now</strong>
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
GraphqlDealOfWeek.propTypes = {
    classes: object,
    pageBlocks: array.isRequired,
    firstProduct: shape({
        image: shape({
            name: string,
            altText: string,
        }),
        name: string,
        seo: shape({
            url: string,
        }),
    }),
};
GraphqlDealOfWeek.defaultProps = {
    classes: {},
    firstProduct: {},
};

export default withStyles(styles)(withAuth(GraphqlDealOfWeek));
