/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import {
    object, shape, string, func,
} from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { trackEvent as trackEventActions } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import DesktopMobile from '../../../../../../helpers/DesktopMobile/DesktopMobile';

const styles = (theme) => ({
    mainBanner: {
        width: '100%',
    },
    mainImageBanner: {
        position: 'relative',
        width: '100%',
        margin: '0 auto',
        display: 'block',
        '& img': {
            display: 'block',
            width: '100%',
            margin: '0 auto',
        },
    },
    mainBannerContentContainer: {
        display: 'flex',
        height: 'inherit',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 60px 0 60px',
        [theme.breakpoints.down(769)]: {
            marginLeft: '20px',
            marginRight: '20px',
            justifyContent: 'center !important', // need some way to over-write cms inline style
        },
        [theme.breakpoints.down(600)]: {
            paddingTop: '20px',
            alignItems: 'flex-start',
        },
    },
    mainBannerContent: {
        width: '600px',
        padding: '20px',
        backgroundColor: '#FFF',
    },
    innerBannerBorder: {
        border: '1px solid #000',
        padding: '30px 40px 40px 40px',
        [theme.breakpoints.down(769)]: {
            padding: '20px',
        },
    },
    innerBanner: {
        padding: '30px 40px 40px 40px',
        [theme.breakpoints.down(769)]: {
            padding: '20px',
        },
    },
    title: {
        position: 'relative',
        textAlign: 'center',
        '&::before': {
            content: '""',
            background: '#000',
            width: '100%',
            height: '1px',
            position: 'absolute',
            right: 0,
            left: 0,
            top: '50%',
            zIndex: 1,
        },
        '& h1, h2, & h3, & h4, & h5, & h6': {
            fontSize: '16px',
            textTransform: 'uppercase',
            lineHeight: '16px',
            letterSpacing: '1.47px',
            position: 'relative',
            display: 'inline-block',
            zIndex: 9,
            background: '#FFF',
            padding: '0 10px',
            [theme.breakpoints.down(600)]: {
                fontSize: '14px',
                marginBottom: '7px',
            },
        },
    },
    bannerHeadline: {
        marginBottom: '7px',
        '& p,& h1,& h2,& h3,& h4,& h5,& h6': {
            margin: '0',
            fontFamily: 'PlayfairDisplay, serif',
            fontSize: '36px',
            lineHeight: '46px',
            textAlign: 'center',
            [theme.breakpoints.down(769)]: {
                lineHeight: '42px',
            },
        },
    },
    bannerSubHeadline: {
        textAlign: 'center',
        marginBottom: '20px',
        '& h1,& h2,& h3,& h4,& h5,& h6': {
            margin: 0,
            fontSize: '16px',
            textTransform: 'uppercase',
            letterSpacing: '1.47px',
            lineHeight: '18px',
            marginBottom: '15px',
            [theme.breakpoints.down(600)]: {
                lineHeight: '18px',
                fontSize: '14px',
                marginBottom: '20px',
            },
        },
        '& p': {
            fontSize: '16px',
            lineHeight: '20px',
            margin: 0,
        },
    },
    bannerCtaBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    bannerCtaBtn: {
        textAlign: 'center',
        padding: '6px 25px',
        fontSize: '16px',
        letterSpacing: '1.47px',
        lineHeight: '35px',
        fontWeight: 'bold',
        textDecoration: 'none',
        display: 'inline-block',
        borderRadius: '3px',
        [theme.breakpoints.down(600)]: {
            lineHeight: '42px',
            fontSize: '12px',
            marginBottom: '20px',
            padding: '0 25px',
        },
        [theme.breakpoints.down(400)]: {
            fontSize: '10px',
            padding: '0 20px',
        },
    },
    mainBannerDesktop: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '400px',
        width: '100%',
        margin: '0 auto 30px',
        [theme.breakpoints.down(1440)]: {
            height: '370px',
        },
    },
});
const TemplateMultiSubMainBanner = ({
    classes, item, trackEvent, tracking,
}) => {
    if (!item) {
        return null;
    }
    const seed = useUIDSeed();
    const mainBannerData = item?.main_banner;
    const link = mainBannerData?.link?.href || '';
    const url = mainBannerData?.desktop_image?.url || '';
    const mobileUrl = mainBannerData?.mobile_image?.url || '';
    const description = mainBannerData?.desktop_image?.description || 'John Tesh Banner';
    const bannerTitle = mainBannerData?.banner_content?.title || '';
    const bannerHeadline = mainBannerData?.banner_content?.headline || '';
    const bannerSubHeadline = mainBannerData?.banner_content?.sub_headline || '';

    // show border line on content
    const showBorder = mainBannerData?.banner_content?.box_border;

    // for Banner Button
    const ctaButton = mainBannerData?.banner_content?.cta_button?.title || '';
    const ctaButtonURL = mainBannerData?.banner_content?.cta_button?.href || '';

    // for styling bannerHeadline
    const headerbannerStyle = {
        color: mainBannerData?.banner_content?.headline_font_color || '#000000',
    };
    // for styling bannerSubHeadline
    const headerSubHeadlineStyle = {
        color: mainBannerData?.banner_content?.sub_headline_font_color || '#000000',
    };
    //  for styling bannerCTAButton
    const headerBannerCTAButton = {
        color: mainBannerData?.banner_content?.cta_button_text_color || '#FFFFFF',
        backgroundColor: mainBannerData?.banner_content?.cta_button_background_color || '#228B22',
    };

    const flexJustifyDirection = (direction) => {
        switch (direction) {
            case 'left':
                return 'flex-start';

            case 'center':
                return 'center';

            case 'right':
                return 'flex-end';

            default:
                return 'center';
        }
    };

    const bannerHtmlContent = () => (
        <div className={classes.mainBannerContentContainer} style={{ justifyContent: flexJustifyDirection(mainBannerData?.banner_content?.copy_alignment) }}>
            <div className={classes.mainBannerContent}>
                <div className={showBorder ? classes.innerBannerBorder : classes.innerBanner}>
                    {bannerTitle && <div className={classes.title}><h2><strong>{bannerTitle}</strong></h2></div>}
                    <div style={headerbannerStyle}>
                        <ReactMarkdown className={classes.bannerHeadline} source={bannerHeadline} />
                    </div>
                    <div style={headerSubHeadlineStyle}>
                        <ReactMarkdown className={classes.bannerSubHeadline} source={bannerSubHeadline} />
                    </div>
                    {ctaButtonURL && (
                        <div className={classes.bannerCtaBtnContainer}>
                            <Link
                                style={headerBannerCTAButton}
                                className={classes.bannerCtaBtn}
                                to={ctaButtonURL}
                                onClick={() => trackEvent({
                                    eventCategory: tracking?.tracking_event_category || 'Subscriptions',
                                    eventAction: tracking?.tracking_event_action || 'Hero',
                                    eventLabel: ctaButton,
                                })}
                            >
                                {ctaButton}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <>
            {
                // if Main Banner block has no href/url and has no headline display an image
                !link && (!bannerHeadline) && (
                    <DesktopMobile
                        breakpoint={600}
                        desktop={() => <img src={url} alt={description} className={classes.mainImageBanner} key={seed(item)} />}
                        mobile={() => <img src={mobileUrl} alt={description} className={classes.mainImageBanner} key={seed(item)} />}
                    />
                )
            }
            {
                // if Main Banner block has an href/url make the image a link
                link && (
                    <Link key={seed(item)} to={link} className={classes.mainImageBanner}>
                        <DesktopMobile
                            breakpoint={600}
                            desktop={() => <img src={url} alt={description} className={classes.mainImageBanner} />}
                            mobile={() => <img src={mobileUrl} alt={description} className={classes.mainImageBanner} />}
                        />
                    </Link>
                )
            }

            {   // if Main Banner block has a title or headline use hero image and content block
                (bannerTitle || bannerHeadline) && (
                    <DesktopMobile
                        breakpoint={600}
                        desktop={() => (
                            <div
                                key={seed(item)}
                                className={classes.mainBannerDesktop}
                                style={{
                                    backgroundImage: `url('${url}')`,
                                }}
                            >
                                {bannerHtmlContent()}
                            </div>
                        )}
                        mobile={() => (
                            <div
                                key={seed(item)}
                                className={classes.mainBanner}
                                style={{
                                    backgroundImage: `url('${mobileUrl}')`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    height: '700px',
                                }}
                            >
                                {bannerHtmlContent()}
                            </div>
                        )}
                    />
                )
            }
        </>
    );
};
TemplateMultiSubMainBanner.propTypes = {
    classes: object,
    item: shape({
        banner_content: shape({
            cta_button_background_color: string,
            cta_button_text_color: string,
            headline: string,
            headline_font_color: string,
            sub_headline: string,
            sub_headline_font_color: string,
        }),
        desktop_image: shape({
            title: string,
            url: string,
        }),
        link: shape({
            title: string,
            href: string,
        }),
    }),
    trackEvent: func.isRequired,
    tracking: shape({
        tracking_event_action: string,
        tracking_event_category: string,
        tracking_event_label: string,
    }),

};
TemplateMultiSubMainBanner.defaultProps = {
    classes: {},
    item: {},
    tracking: {},
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(trackEventActions, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(TemplateMultiSubMainBanner);
