/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { arrayOf, object, shape } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { uid } from 'react-uid';
import LinkOrATag from '../../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 -15px 0 0',
    },
    groupItemsContainer: {
        display: 'flex',
        overflow: 'scroll',
        padding: '5px 0 0 ',
    },
    navItemLink: {
        margin: '0 10px 0 5px',
        textDecoration: 'none',
    },
    navImageWraper: {
        width: 90,
        height: 90,
        borderRadius: '50%',
        overflow: 'hidden',
        boxShadow: 'rgb(0 0 0 / 52%) 1px 2px 5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: 70,
        },
    },
    navItemLabel: {
        color: theme.palette?.black || '#000000',
        textAlign: 'center',
        margin: '10px 0 5px 0',
        fontSize: 14,
    },
    blockHeading: {
        margin: '0 0 10px 0',
        textAlign: 'center',
        fontFamily: 'PlayfairDisplayBold, serif',
        fontSize: 20,
    },
}));

const GraphqlSubnavV2 = ({
    subnavBlock,
}) => {
    const classes = useStyles();
    const subNavGroupItems = subnavBlock?.sub_navigation_group || [];

    if (!subNavGroupItems?.length) {
        return null;
    }

    return (
        <div className={classes.root}>
            {subnavBlock?.singtitlele_line_textbox && <p className={classes.blockHeading}>{subnavBlock.singtitlele_line_textbox}</p>}
            <div className={classes.groupItemsContainer}>
                {subNavGroupItems?.map((navLink, index) => (
                    navLink?.link?.href !== '' && (
                        <LinkOrATag key={uid(navLink, index)} className={classes.navItemLink} href={navLink?.link?.href}>
                            <div className={classes.navImageWraper}>
                                <img src={navLink?.mobile_image?.url} alt={navLink?.link?.title} />
                            </div>
                            { navLink.link?.title && <p className={classes.navItemLabel}>{navLink.link.title}</p>}
                        </LinkOrATag>
                    )
                ))}
            </div>
        </div>
    );
};

GraphqlSubnavV2.propTypes = {
    subnavBlock: shape({
        sub_navigation_group: arrayOf({
            link: object.isRequired,
        }),
    }).isRequired,
};

export default GraphqlSubnavV2;
