/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { func, object, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { trackEvent as track } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    imageAspectRatio: {
        paddingBottom: '110%', // HEIGHT / WIDTH IN THIS CASE
    },
    mobileCatLink: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    img: {
        display: 'block',
        width: '100%',
        height: 'auto',
        boxShadow: theme.palette.uiShadow,
    },
    overlay: {
        position: 'absolute',
        letterSpacing: '0.42px',
        width: '100%',
        fontSize: '13px',
        textAlign: 'center',
        left: 0,
        bottom: 0,
        right: 0,
        fontWeight: '200',
        backgroundColor: theme.palette.bgTextOverlay1,
        color: theme.palette.white,
        lineHeight: '25px',
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.white,
            color: theme.palette.common?.black,
            fontWeight: 500,
            borderRadius: '5px',
            borderTopRightRadius: '0',
            borderTopLeftRadius: '0',

        },
    },
    imageBlock: {
        [theme.breakpoints.down('sm')]: {
            borderRadius: '5px',
            boxShadow: theme.palette.uiShadow,
        },
    },
});

const GraphqlMobileCategoryImage = ({
    classes, href, imgSrc, title, presentationFamily, trackEvent, trackingEventCategory, trackingEventAction, trackingEventLabel,
}) => (
    <>
        <div className={classes.imageAspectRatio} /> {/*  DONT REMOVE */}
        <Link
            to={href}
            className={classes.mobileCatLink}
            onClick={() => {
                trackEvent({
                    eventCategory: trackingEventCategory || '',
                    eventAction: trackingEventAction || '',
                    eventLabel: trackingEventLabel || '',
                });
            }}
        >
            <ResponsiveImage path={imgSrc} params={{ mobile: 'quality=100' }} className={presentationFamily === 'flower' ? `${classes.imageBlock} ${classes.img}` : classes.img} alt={title} />
            <div className={presentationFamily === 'flower' ? `${classes.title} ${classes.overlay}` : classes.overlay}>
                {title}
            </div>
        </Link>
    </>
);

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

GraphqlMobileCategoryImage.defaultProps = {
    trackingEventAction: '',
    trackingEventCategory: '',
    trackingEventLabel: '',
};

GraphqlMobileCategoryImage.propTypes = {
    classes: object.isRequired,
    href: string.isRequired,
    imgSrc: string.isRequired,
    title: string.isRequired,
    presentationFamily: string.isRequired,
    trackEvent: func.isRequired,
    trackingEventAction: string,
    trackingEventCategory: string,
    trackingEventLabel: string,
};

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(GraphqlMobileCategoryImage));
