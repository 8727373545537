/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, array,  number, shape, string, arrayOf,
} from 'prop-types';
import { connect } from 'react-redux';
import { GraphqlSimpleProduct } from '../../../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';
import GraphqlIcBanner from '../../../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlIcBanner';
import { getPresentationFamily } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const SubCollectionProducts = ({
    classes, subCollectionProducts, numOfProducts, showMovieData, entries,  presentationFamily,
}) => {
    const icBannerBlock = {};
    if (entries?.page_blocks) {
        entries.page_blocks.forEach((block) => {
            if (block) {
                if (block?.in_collection_banners?.show_after_product_number) {
                    icBannerBlock[block.in_collection_banners.show_after_product_number] = block?.in_collection_banners;
                }
            }
        });
    }
    const returnProductRow = (rowNumber) => {
        switch (rowNumber) {
            case 4:
                return classes.simpleProduct4Row;
            case 3:
                return classes.simpleProduct3Row;
            case 2:
                return classes.simpleProduct2Row;
            default:
                return  classes.simpleProduct3Row;
        }
    };
    return (
        <div className={classes.subNavProductRow} style={{ paddingTop: 0 }}>
            {subCollectionProducts?.map((product, i) => {
                const {
                    name, image, seo, skuPriceRange, availability, partNumber, brandId,
                } = product;
                if (icBannerBlock[i]) {
                    return (
                        <>
                            <div key={icBannerBlock[i]?.link?.href} item className={returnProductRow(numOfProducts)}>
                                <GraphqlIcBanner icBannerBlock={icBannerBlock[i]} presentation_family={presentationFamily} />
                            </div>
                            <div key={name} className={returnProductRow(numOfProducts)}>
                                <GraphqlSimpleProduct showMovieUpsell={showMovieData || false} name={name} image={image} url={seo?.url} skuPriceRange={skuPriceRange} availability={availability} partNumber={partNumber} showSimpleProductRedesignAbTest={false} productBrand={brandId} />
                            </div>
                        </>
                    );
                }
                return (
                    <div key={name} className={returnProductRow(numOfProducts)}>
                        <GraphqlSimpleProduct showMovieUpsell={showMovieData || false} name={name} image={image} url={seo?.url} skuPriceRange={skuPriceRange} availability={availability} partNumber={partNumber} showSimpleProductRedesignAbTest={false} productBrand={brandId} />
                    </div>
                );
            })}
        </div>
    );
};

SubCollectionProducts.propTypes = {
    classes: object.isRequired,
    subCollectionProducts: array.isRequired,
    numOfProducts: number.isRequired,
    showMovieData: shape({
        icon: shape({
            url: string,
        }),
        products: arrayOf(shape({
            sku: string,
            sku_description: string,
            sku_price: string,
        })),
        title: string.isRequired,
    }).isRequired,
    entries: object.isRequired,
    presentationFamily: string.isRequired,

};

const mapStateToProps = (state) => ({
    presentationFamily: getPresentationFamily(state),
});

export default connect(mapStateToProps, null)(SubCollectionProducts);
