/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { bool, object, func } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import DesktopMobile from '../../../../../../../helpers/DesktopMobile/DesktopMobile';
import LinkOrATag from '../../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import { getPresentationFamily } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTest } from '../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import GraphqlSubnavV2 from './GraphqlSubnavV2';

const styles = (theme) => ({
    tabbedCollectionDesktop: {
        width: '100%',
        maxWidth: '1440px',
        margin: '0 auto 20px auto',
        height: '32px',
        display: 'flex',
        boxSizing: 'border-box',
        [theme?.breakpoints?.up(1400)]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '@media screen and (min-width: 768px) and (max-width: 1300px)': {
            flexWrap: 'wrap',
            height: 'auto',
        },
    },
    tabbedCollectionTitleDesktop: {
        textDecoration: 'none',
        flex: '1 1 auto',
        height: '100%',
        boxSizing: 'border-box',
        fontSize: '16px',
        lineHeight: '32px',
        fontWeight: 'bold',
        textAlign: 'center',
        backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        color: theme?.palette?.colorPrimaryContrast,
        transition: 'background-color 0.23s ease-out',
        border: `1px solid ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
        marginRight: 2,
        '&:hover, &:focus': {
            backgroundColor: theme?.palette?.colorPrimaryContrast,
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            border: `1px solid ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
        },
        '@media screen and (min-width: 768px) and (max-width: 1300px)': {
            flex: '0 0 32.7%',
            margin: '0 3px 2px 0',
        },
    },
    tabbedCollectionMobile: {
        width: '100%',
        margin: '0 auto 15px auto',
    },
    tabbedCollectionTitleMobile: {
        display: 'block',
        backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        color: theme?.palette?.colorPrimaryContrast,
        border: `1px solid ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
        borderRadius: '2px',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '32px',
        textAlign: 'center',
        textDecoration: 'none',
        [theme?.breakpoints?.down(580)]: {
            minHeight: '62px',
            fontSize: '13px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '20px',
            fontWeight: 'normal',
        },
    },
    sliderOuterContainer: {
        width: '100%',
        display: 'inline-block',
        flexWrap: 'wrap',
        margin: '0 auto 15px auto',
        '& a': {
            minHeight: 40,
            justifyContent: 'space-evenly',
        },
    },
    sliderContainer: {
        position: 'relative',
        maxWidth: '1400px',
        margin: 'auto',
        '& .slick-list': {
            overflow: 'hidden',
        },
        '& .slick-slide': {
            display: 'inline-block',
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
        '& .slick-dots': {
            listStyle: 'none',
            display: 'flex !important',
            padding: '0',
            justifyContent: 'center',
            margin: '0',
            top: '50%',
            left: 0,
            right: 0,
            position: 'absolute',
            '& li': {
                lineHeight: 1,
            },
            '& .slick-active': {
                '& div': {
                    background: theme.palette.cms?.black || '#000000',
                },
            },
            '& .slick-current': {
                '& div': {
                    paddingRight: 5,
                },
            },
        },
    },
    tabbedCollectionImageMobile: {
        width: 35,
        height: 35,
    },
    tabbedCollectionTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontSize: 13,
        margin: '10px 0px',
    },
    sliderInner: {
        padding: '0px 3px',
    },
});

const GraphqlSubnav = ({
    classes,
    subnavBlock,
    featureFlagIsMobileCollectionTabbed,
    trackEvent,
}) => {
    useEffect(() => {
        if (featureFlagIsMobileCollectionTabbed) {
            trackEvent({
                eventCategory: 'Test Impression',
                eventAction: 'Mobile Tabbed Variant',
            });
        }
    }, [featureFlagIsMobileCollectionTabbed, trackEvent]);

    const shortenTitle = (collectionTitle) => {
        let title = collectionTitle;
        if (collectionTitle.includes('Same Day')) {
            title = 'Same Day';
        } else if (collectionTitle.includes('Best Selling')) {
            title = 'Best Sellers';
        }
        return title;
    };
    const presentationFamily = useSelector(getPresentationFamily);
    const imocSubNavHide = useSelector(getActiveABTest('imocSubNavHide'));
    const isSubNavV2Enabled = useSelector(getActiveABTest('subnav_v2_enabled')) === 'VARIANT';
    const location = useLocation();
    const collectionUrl = location?.pathname === '/flowers-same-day-delivery';
    const isImocSubNavHide = imocSubNavHide && presentationFamily === 'flower' && collectionUrl;
    const getBlock = (navLink, linkStyle = {}) => (
        <LinkOrATag
            id={navLink.link?.title.split(' ').join('-').toLowerCase()}
            dataTest="collection-sub-nav-mobile"
            name="collectionSubNavMenuMobile"
            className={classes.tabbedCollectionTitleMobile}
            href={navLink?.link?.href}
            key={navLink?.link?.title}
            style={linkStyle}
            trackingEventAction={navLink?.tracking_event_action}
            trackingEventCategory={navLink?.tracking_event_category}
            trackingEventLabel={navLink?.tracking_event_label}
            isTrackEventEnabled
        >
            {(presentationFamily === 'flower' && navLink?.mobile_image?.url) &&  <img  className={classes.tabbedCollectionImageMobile} src={navLink?.mobile_image?.url} alt={navLink?.link?.title} />}
            {shortenTitle(navLink.link?.title)}
        </LinkOrATag>
    );
    if (subnavBlock && subnavBlock?.sub_navigation_group && !isImocSubNavHide) {
        return (
            <DesktopMobile
                desktop={() => (
                    <div className={classes.tabbedCollectionDesktop}>
                        {subnavBlock?.sub_navigation_group?.map((navLink) => (
                            <LinkOrATag
                                id={navLink.link?.title.split(' ').join('-').toLowerCase()}
                                dataTest="collection-sub-nav-desktop"
                                name="collectionSubNavMenuDesktop"
                                className={classes.tabbedCollectionTitleDesktop}
                                href={navLink?.link?.href}
                                key={navLink?.link?.title}
                                style={{
                                    backgroundColor: `${navLink?.background_color}`,
                                    color: `${navLink?.text_color}`,
                                }}
                                trackingEventAction={navLink?.tracking_event_action}
                                trackingEventCategory={navLink?.tracking_event_category}
                                trackingEventLabel={navLink?.tracking_event_label}
                                isTrackEventEnabled
                            >
                                {navLink.link?.title}
                            </LinkOrATag>
                        ))}
                    </div>
                )}
                mobile={() => {
                    if (featureFlagIsMobileCollectionTabbed) {
                        if (isSubNavV2Enabled) {
                            return (
                                <GraphqlSubnavV2 subnavBlock={subnavBlock} />
                            );
                        }
                        return (
                            <Grid container spacing={1} className={presentationFamily === 'flower' ? classes.sliderOuterContainer : classes.tabbedCollectionMobile}>
                                {presentationFamily === 'flower'
                                    ? (
                                        <>
                                            {subnavBlock?.title && <div className={classes.tabbedCollectionTitle}>{subnavBlock.title}</div>}
                                            <Slider
                                                infinite={false}
                                                speed={500}
                                                slidesToShow={2.5}
                                                slidesToScroll={1}
                                                arrows={false}
                                                dots={false}
                                                dotsClass="slick-dots slick-thumb"
                                                className={`carousel-container ${classes.sliderContainer}`}
                                            >
                                                {subnavBlock?.sub_navigation_group?.map((navLink) => {
                                                    const linkStyle =   {
                                                        backgroundColor: '#ffffff',
                                                        color: '#000000',
                                                    };
                                                    return (navLink?.link?.href !== '' && (
                                                        <>
                                                            <div className={classes.sliderInner}>
                                                                {getBlock(navLink, linkStyle)}
                                                            </div>
                                                        </>
                                                    )
                                                    );
                                                })}
                                            </Slider>
                                        </>
                                    )
                                    :    subnavBlock?.sub_navigation_group?.map((navLink) => {
                                        const linkStyle =   {
                                            backgroundColor: `${navLink?.background_color}`,
                                            color: `${navLink?.text_color}`,
                                        };
                                        return navLink?.link?.href !== '' && (
                                            <Grid item xs={4} id="ashish2">
                                                {getBlock(navLink, linkStyle)}
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        );
                    }

                    return null;
                }}
                breakpoint={769}
            />
        );
    }

    return null;
};

GraphqlSubnav.propTypes = {
    subnavBlock: object.isRequired,
    classes: object.isRequired,
    featureFlagIsMobileCollectionTabbed: bool,
    trackEvent: func,
};

GraphqlSubnav.defaultProps = {
    featureFlagIsMobileCollectionTabbed: false,
    trackEvent: null,
};

export default withStyles(styles)(GraphqlSubnav);
