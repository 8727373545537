/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import {
    func, object, string, arrayOf, shape,
} from 'prop-types';
import { compose } from 'recompose';
import track from 'react-tracking';
import { connect } from 'react-redux';
import mbpLogger from 'mbp-logger';
import ReactInfiniteScroller from 'react-infinite-scroller';
import {  getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { trackPage } from '../../../helpers/tracking/common/commonTrackingHelpers';
import TemplateCollectionSem from './Partials/TemplateCollectionSem/TemplateCollectionSem';
import TemplateCollectionSubnavBanner from './Partials/TemplateCollectionSubnavBanner/TemplateCollectionSubnavBanner';
import TemplateCollectionSubnavImageBanner from './Partials/TemplateCollectionSubnavImageBanner/TemplateCollectionSubnavImageBanner';
import TemplateCollectionGiftGuides from './Partials/TemplateCollectionGiftGuides/TemplateCollectionGiftGuides';
import TemplatePageSales from './Partials/TemplatePageSales/TemplatePageSales';
import TemplateJasonwuConnect from './Partials/TemplateJasonwuConnect/TemplateJasonwuConnect';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import ProductsFallbackContainer from '../GraphqlCommonComponents/GraphqlProductsFallbackContainer/ProductsFallbackContainer';
import TemplateFareWellPage from './Partials/TemplateFareWell/TemplateFareWellPage';

class GraphqlTemplateBuilder extends Component {
    componentDidCatch(jsError, reactInfo) {
        const { templateData } = this.props;
        mbpLogger.logError({
            message: `Unhandled Error in Grapqhl Template Builder \n jsError = ${jsError} \n reactInfo = ${reactInfo} \n templateData = ${templateData}`,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
        });
    }

renderTemplate = (contentType, templateData, isMobile, presentation_family, productsData, onLoadMore, brand, page) => {
    switch (contentType) {
        case 'template_collections_sem':
            return <TemplateCollectionSem templateData={templateData} isMobile={isMobile} />;
        case 'template_collections_subnav_banner': {
            return (
                <ReactInfiniteScroller initialLoad={false} threshold={350} hasMore loadMore={() => { onLoadMore(); }}>
                    <TemplateCollectionSubnavBanner templateData={templateData} isMobile={isMobile} productsData={productsData} />
                </ReactInfiniteScroller>
            );
        }
        case 'template_collection_subnav_image_banner': {
            return (
                <ReactInfiniteScroller initialLoad={false} threshold={350} hasMore loadMore={() => { onLoadMore(); }}>
                    <TemplateCollectionSubnavImageBanner templateData={templateData} isMobile={isMobile} productsData={productsData} />;
                </ReactInfiniteScroller>
            );
        }
        case 'template_collection_gift_guides':
            return <TemplateCollectionGiftGuides templateData={templateData} isMobile={isMobile} />;
        case 'template_collection_multi_sub_collections':
            return <TemplateFareWellPage templateData={templateData} presentationFamily={presentation_family} />;
        case 'template_page_sales': {
            return (
                <ReactInfiniteScroller initialLoad={false} threshold={350} hasMore loadMore={() => { onLoadMore(); }}>
                    <TemplatePageSales templateData={templateData} productsData={productsData} presentationFamily={presentation_family} brand={brand} page={page} isMobile={isMobile} />
                </ReactInfiniteScroller>
            );
        }
        case 'template_page_partner_image_carousels':
            return <TemplateJasonwuConnect templateData={templateData} isMobile={isMobile} />;
        default:
            return <ProductsFallbackContainer />;
    }
}

    @track({
        action: 'track_template_page_data',
    })
render() {
    const {
        page, templateData, ssrDeviceType, presentation_family, onLoadMore, productsData, brand,
    } = this.props;
    const isMobile = ssrDeviceType === 'mobile';
    return (
        <>
            {this.renderTemplate(page?.contentType, templateData, isMobile, presentation_family, productsData, onLoadMore,  brand, page)}
        </>
    );
}
}

GraphqlTemplateBuilder.propTypes = {
    page: shape({
        contentType: string.isRequired,
    }).isRequired,
    templateData: object.isRequired,
    ssrDeviceType: string.isRequired,
    presentation_family: string.isRequired,
    onLoadMore: func.isRequired,
    productsData: arrayOf(
        shape({
            brand: string.isRequired,
            name: string.isRequired,
            partNumber: string.isRequired,
            id: string.isRequired,
        }),
    ),
    brand: object.isRequired,
};
GraphqlTemplateBuilder.defaultProps = {
    productsData: [],
};
const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
    presentation_family: getPresentationFamily(state),
});
const enhance = compose(
    trackPage(),
    connect(mapStateToProps),
);

export default enhance(GraphqlTemplateBuilder);
