/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import {
    Container, Typography, Card, CardContent, CardMedia,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { shape, string } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    containerRoot: {
        margin: '2em 0',
        width: '100%',
        display: 'flex',

        height: '165px',

        [theme.breakpoints.down(1075)]: {
            height: '250px',
        },
    },

    backgroundVariant: {
        height: '165px',
        width: '100%',
        position: 'absolute',
        left: '0',
        zIndex: '-99',

        [theme.breakpoints.down(1075)]: {
            height: '250px',
        },
    },

    containerMaxWidthLg: {
        maxWidth: '100%',
    },

    cardRoot: {
        width: '100%',
        display: 'flex',
        boxShadow: 'none',
        background: 'transparent',

        [theme.breakpoints.down(1075)]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    cardContentLeftRoot: {
        width: '45%', // shared with cardContentRightRoot

        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        paddingLeft: '20%',

        [theme.breakpoints.down(1075)]: {
            width: '100%',
            paddingLeft: '16px', // back to default
        },
    },

    cardContentHeaderH4: {
        width: '100%',
        textTransform: 'uppercase',
        letterSpacing: '-1px',
        fontWeight: 'bold',
        fontSize: '2rem',
    },

    cardContentBody: {
        width: '100%',
        fontSize: '.9rem',
    },

    cardContentRightRoot: {
        width: '55%', // shared with cardContentLeftRoot
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        '&:last-child': {
            paddingBottom: '16px', // match root padding
        },

        [theme.breakpoints.down(1075)]: {
            width: '100%', // back to default
        },
    },

    cardMediaRoot: {
        width: '70%',

        [theme.breakpoints.down(1075)]: {
            width: '100%',
        },
    },
}));

const SpotifyWidget = ({ data }) => {
    const classes = useStyles();

    return (
        <Container
            classes={{
                root: classes.containerRoot,
                maxWidthLg: classes.containerMaxWidthLg,
            }}
        >
            <div
                className={classes.backgroundVariant}
                style={{
                    backgroundColor: data.primary_color,
                    backgroundImage: `-webkit-linear-gradient(30deg, ${data.primary_color} 50%, ${data.secondary_color} 50%)`,
                }}
            />
            <Card
                classes={{
                    root: classes.cardRoot,
                }}
            >
                <CardContent
                    classes={{
                        root: classes.cardContentLeftRoot,
                    }}
                >
                    <Typography
                        variant="h4"
                        classes={{
                            h4: classes.cardContentHeaderH4,
                        }}
                    >
                        {data.title}
                    </Typography>
                    <Typography
                        classes={{
                            body1: classes.cardContentBody,
                        }}
                    >
                        {ReactHTMLParser(data.markdown)}
                    </Typography>
                </CardContent>
                <CardContent
                    classes={{
                        root: classes.cardContentRightRoot,
                    }}
                >

                    <CardMedia
                        classes={{
                            root: classes.cardMediaRoot,
                        }}
                        component="iframe"
                        title="spotify-playlist"
                        src={data.iframe_source}
                        width="400"
                        height="80"
                        frameBorder="0"
                        allowtransparency="true"
                        allow="encrypted-media"
                    />
                </CardContent>

            </Card>
        </Container>
    );
};

SpotifyWidget.propTypes = {
    data: shape({
        iframe_source: string.isRequired,
        title: string.isRequired,
        markdown: string.isRequired,
        primary_color: string,
        secondary_color: string,
    }),
};

SpotifyWidget.defaultProps = {
    data: {
        primary_color: '#ED5D80',
        secondary_color: '#FBC0C6',
    },
};

export default SpotifyWidget;
