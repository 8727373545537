/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    shape, string, bool, arrayOf, number, func, object,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import mbpLogger from 'mbp-logger';
import ReactHTMLParser from 'react-html-parser';
// queries
import findURL from '../../../../../gql/queries/findURL';
import findProductPageByPartNumber from '../../../../../gql/queries/findProductPageByPartNumber';
import customBreakpoints from '../../../../../helpers/customBreakpoints';
import ResponsiveImage from '../../ResponsiveImage/ResponsiveImage';
import PriceRange from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import SnipeRender from '../../../GraphqlProductPage/Partials/common/SnipeRender';
import SnipeRenderQuery from '../../../GraphqlProductPage/Partials/common/SnipeRenderQuery';

// helpers
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getActiveABTest } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import noop from '../../../../../helpers/noop';

const useStyles = makeStyles((theme) => ({
    root: ({ trendingProductsGrid }) => ({
        position: 'relative',
        display: 'inline-block',
        margin: '0 10px',
        cursor: 'pointer',
        textDecoration: 'none',

        // image container shrinks
        width: trendingProductsGrid ? '40%' : '145px',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            width: trendingProductsGrid ? '40%' : '110px',
        },

        // the actual image should match container
        '& img': {
            width: '100%',
            minWidth: '145px',
            [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
                width: trendingProductsGrid ? '100%' : '110px',
                minWidth: trendingProductsGrid ? '40%' : '110px',
            },
        },
    }),
    productCopy: {
        margin: '0',
        color: '#212121',
        display: 'block',
        fontSize: '.9em',
        letterSpacing: '0.5px',
        fontWeight: '400',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    productPrice: {
        '&--retail': {
            fontWeight: '800',
        },
        '&--sale': {
            fontWeight: '800',
            color: theme.palette.product?.salePrice || '#a1001a',
        },
    },
    wcsStyle: {
        minWidth: 255,
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            minWidth: 110,
        },
    },
    snipeImage: {
        position: 'absolute',
        left: '0',
        top: '0',
    },
    snipeContainer: {
        position: 'relative',
    },

    boldPriceRange: {
        fontWeight: 600,
    },
    productPosAbs: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    priceContainer: {
        margin: '0',
        color: theme.palette.cms?.black || '#2f2f2f',
        display: 'block',
        fontSize: '0.9em',
        letterSpacing: '0.5px',
        fontWeight: '400',
    },

    imageContainer: {
        position: 'relative',
    },
}));

const ProductUI = ({
    brand,
    productData: {
        id,
        image,
        name,
        skuPriceRange,
        seo: {
            url,
        },
        baseCode,
        partNumber,
    },
    isMobile,
    wcsStyle,
    demandType,
    isFlowerBrand,
    productPosition,
    trendingProductsGrid,
    productPaddingBottom,
    priceTitle,
    productClickCallback,
    salesforceResponse,
}) => {
    const classes = useStyles({ trendingProductsGrid });
    const abTestTrendingProducts = useSelector(getActiveABTest('trendingProducts'));
    // passed value of id or partNumber to productPartNumber according to its existance, so in case any one key (id, partNumber) is returned as empty.
    const productPartNumber = id || partNumber;

    // @intention: compares retail price to sale price
    // @params:
    // - sku:{obj}
    // @return: {JSX}
    const renderSkuPrice = (sku) => {
        const retailPriceOne = sku.retail?.[0]?.value || null;
        const retailPriceTwo = sku.retail?.[1]?.value || null;
        const salePriceOne = sku.sale?.[0]?.value || null;
        const salePriceTwo = sku.sale?.[1]?.value || null;
        if (retailPriceOne && salePriceOne && retailPriceTwo && salePriceTwo && salePriceOne < retailPriceOne) {
            return (
                <span className={`${classes.productPrice}--sale`}>
                    {`${priceTitle} $${salePriceOne}`}
                </span>
            );
        }
        if (retailPriceOne === retailPriceTwo) {
            return (
                <span className={`${classes.productPrice}--retail`}>
                    {`${priceTitle} $${retailPriceOne}`}
                </span>
            );
        }
        return (
            <span className={`${classes.productPrice}--retail`}>
                {`${priceTitle} $${retailPriceOne}`}
            </span>
        );
    };
    const dispatch = useDispatch();

    // @intention: pre-fetching PDP on mouseHover
    // query creation
    const FIND_URL_QUERY = findURL(brand, `/${url}`);
    const PDP_QUERY = findProductPageByPartNumber(brand, productPartNumber);
    // functions to pre-fetch on mouseOver
    const [loadURL, { errorURL }] = useLazyQuery(FIND_URL_QUERY);
    const [loadPDP, { errorPDP }] = useLazyQuery(PDP_QUERY);
    if (errorPDP || errorURL) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            message: 'TrendingProducts -> ProductUI.js - Failed to pre-fetch PDP using Apollo',
            errorPDP,
            errorURL,
        });
    }
    let productUrl = url;
    if (demandType && typeof productUrl === 'string') {
        productUrl = productUrl.indexOf('?') >= 0 ? `${productUrl}&demandType=${demandType}` : `${productUrl}?demandType=${demandType}`;
    }

    return (
        <Link
            className={`${classes.root} ${(!isMobile && wcsStyle) && classes.wcsStyle} ${(isFlowerBrand && image?.snipe) && classes.snipeContainer}`}
            to={productUrl}
            // pre-fetch
            onMouseEnter={() => {
                loadURL();
                loadPDP();
            }}
            onClick={() => {
                // Salesforce Tracking
                if (salesforceResponse?.campaign) {
                    productClickCallback(partNumber);
                }
                if (isFlowerBrand) {
                    if (abTestTrendingProducts) {
                        dispatch(trackEvent({
                            eventCategory: 'Trending gifts HP',
                            eventAction: 'Interaction',
                            eventLabel: name,
                        }));
                    } else {
                        dispatch(trackEvent({
                            eventCategory: 'trending',
                            eventAction: 'click',
                            eventLabel: `${baseCode} | ${image?.path}${image?.name}z.jpg | ${productPosition + 1}`,
                            eventValue: '1',
                            nonInteraction: false,
                        }));
                    }
                }
            }}
        >
            {productPaddingBottom && <div style={{ paddingBottom: `${productPaddingBottom}%` }} />}
            <div className={classes.imageContainer}>
                <LazyLoad>
                    {/* Docs: https://1800flowersinc.atlassian.net/wiki/spaces/RP/pages/709853228/Responsive+Image+Component */}
                    <ResponsiveImage
                        className={`${productPaddingBottom && classes.productPosAbs}`}
                        path={`${image?.path}${image?.name}z.jpg`}
                        alt={name}
                        param={{ mobile: 'quality=100' }}
                    />
                    {image?.snipe && isFlowerBrand && (
                        <SnipeRender
                            styleProp={classes.snipeImage}
                            src={`${image?.snipeImagePath}${image.snipe}_z.gif`}
                            altText={image.snipe}
                        />
                    )}
                </LazyLoad>
                <SnipeRenderQuery partNumber={productPartNumber} />
            </div>
            <p className={classes.productCopy}>{ReactHTMLParser(name)}
            </p>
            <p className={`${priceTitle ? classes.priceContainer : classes.productCopy} ${trendingProductsGrid && classes.boldPriceRange}`} aria-hidden="true">
                {isFlowerBrand && !trendingProductsGrid ? renderSkuPrice(skuPriceRange) : (
                    <PriceRange
                        presentationFamily="food"
                        skuPriceRange={skuPriceRange}
                        isMobile={false}
                    />
                )}
            </p>
        </Link>
    );
};

ProductUI.propTypes = {
    brand: string.isRequired,
    productData: shape({
        id: string,
        partNumber: string,
        image: shape({
            path: string.isRequired,
            name: string.isRequired,
        }).isRequired,
        name: string.isRequired,
        skuPriceRange: shape({
            retail: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
            sale: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
        }).isRequired,
        seo: shape({
            url: string.isRequired,
        }).isRequired,
    }).isRequired,
    isMobile: bool.isRequired,
    demandType: string,
    wcsStyle: bool, // comes from block data from homepage
    isFlowerBrand: bool,
    productPosition: number.isRequired,
    trendingProductsGrid: bool,
    productPaddingBottom: string,
    priceTitle: string,
    productClickCallback: func,
    salesforceResponse: object,
};

ProductUI.defaultProps = {
    wcsStyle: false,
    demandType: '',
    isFlowerBrand: true,
    trendingProductsGrid: false,
    productPaddingBottom: '',
    priceTitle: '',
    productClickCallback: noop,
    salesforceResponse: {},
};

export default (ProductUI);
