/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { useQuery } from '@apollo/client';
import { GRAPHQL_ENV } from '../../../gql';
import GraphqlTemplateContainer from './GraphqlTemplateContainer';
import { getUserSubmittedProductFilterZipcode } from '../../../../state/ducks/App/App-Selectors';
import findCategoryPageByURL from '../../../gql/queries/findCategoryPageByURL';

// GA Tagging
import { trackEvent as track } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { isEmpty } from '../../../pages/Account/utils/object';
import useExperimentServiceAttributes from '../../../helpers/experimentService/useExperimentServiceAttributes';

const styles = (theme) => ({
    siteContainer: {
        width: '100%',
        [theme.breakpoints.up(1430)]: {
            margin: '0 auto',
        },
    },
});
const stopReRender = (prevProps, nextProps) => {
    // This component should only run its query a single time on initial category page load.
    // If zipcode is set before cat page the query should still run with this filtering info
    // To prevent this larger query from rerunning on subsequent changes of location type or zipcode
    // The component is wrapped in react.memo and this function is provided as a param.
    // If this function returns true the component does not rerender and the query is not rerun
    if (prevProps.locationType !== nextProps.locationType) return true;
    if (prevProps.userSubmittedZip !== nextProps.userSubmittedZip) return true;
    return false;
};
const GraphqlTemplatePageContainer =  React.memo(({
    brand, pageData, classes, userSubmittedZip, trackEvent, redirectSubscriptionPage,
}) => {
    const { pathname } = useLocation();
    useEffect(() => {
        // track event for subscription page ab test
        if (!isEmpty(redirectSubscriptionPage) && redirectSubscriptionPage?.from === pathname) {
            trackEvent({
                eventCategory: 'Test Impression',
                eventAction: 'Subscriptions LP',
                eventLabel: redirectSubscriptionPage?.type || 'control',
            });
        }
    }, [pathname]);

    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryPageByUrl' });
    const TEMPLATE_PAGE_QUERY = findCategoryPageByURL(isGraphqlTargetingEnabled, brand.domain, pageData.path, pageData.contentType, userSubmittedZip);
    const { loading, data, error } = useQuery(TEMPLATE_PAGE_QUERY, {
        variables: {
            ...(isGraphqlTargetingEnabled ? { targeting } : {}),
        },
        context,
    });
    if (loading) {
        return <div style={{ height: '100vh' }} />;
    }
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: TEMPLATE_PAGE_QUERY,
            component: 'GraphqlTemplatePageContainer.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }
    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: TEMPLATE_PAGE_QUERY,
            component: 'GraphqlTemplatePageContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }
    let templateData = {};
    if (data?.findCategoryPageByUrl) {
        templateData = data.findCategoryPageByUrl;
    }

    return (
    // TODO: create a site container component
        <div className={classes.siteContainer}>
            {templateData ? <GraphqlTemplateContainer userSubmittedZip={userSubmittedZip} brand={brand} templateData={templateData} pageData={pageData}  /> : null}
        </div>
    );
}, stopReRender);
const mapStateToProps = (state) => ({
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    redirectSubscriptionPage: state?.ui?.abTesting?.redirect_subscription_page_ui || {},
});
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});
GraphqlTemplatePageContainer.propTypes = {
    classes: object.isRequired,
    brand: object.isRequired,
    pageData: object.isRequired,
    userSubmittedZip: string.isRequired,
    trackEvent: func.isRequired,
    redirectSubscriptionPage: object.isRequired,
};
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);
export default enhance(GraphqlTemplatePageContainer);
