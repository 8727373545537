/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, func, number, bool,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    bannerInner: {
        padding: '0 20px 25px 20px',
    },
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: '22px',
        },
    },
    subCopyText: {
        fontSize: 16,
        lineHeight: 1.2,
        padding: '5px 8px',
    },
    innerCopy: {
        width: '100%',
        position: 'absolute',
        top: '0',
        minHeight: 280,
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.cms?.white || '#ffffff',
        flexDirection: 'column',
        lineHeight: '30px',
        textAlign: 'center',
        textShadow: '1px 1px 6px rgba(0,0,0,0.1)',
    },
    bannerInnerImg: {
        position: 'relative',
        cursor: 'pointer',
    },
    bannerInnerImgOverlay: {
        position: 'relative',
        cursor: 'pointer',
        '&::after': {
            content: '""',
            backgroundColor: 'rgba(149, 71, 233, 0.25)',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
        },
    },
    bannerImg: {
        display: 'block',
        width: '100%',
    },
    shopBtn: {
        display: 'block',
        padding: '4px 10px',
        border: '2px solid',
        width: '150px',
        margin: '0 auto',
        marginTop: '15px',
        fontSize: '14px',
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
            border: '2px solid #fff',
        },
    },
});

const GiftBlockBannerFF = ({
    classes, title, imgUrl, imgTitle, trackEvent, index, tileHandler, showOverlay, subCopy, subCopyStyle, titleFontStyle, buttonFontStyle, buttonHoverStyle, isHoverColor, setHoverColor,
}) => {
    /*
     * @description starts subcollection rendering and sends ga event
     * @param {obj} subcollection data from tile
     * @param {int} index of tile
     * @return {} ga event and callback
     */

    const giftBlockBannerTileHandler = (tileTitle, idx) => {
        trackEvent({
            eventCategory: 'Jump Page Test',
            eventAction: `${tileTitle} click`,
            eventLabel: 'Tile click - event label',
        });
        tileHandler(idx);
    };

    return (
        <Grid item xs={6}>
            <div className={classes.bannerInner}>
                <div
                    className={showOverlay ? classes.bannerInnerImgOverlay : classes.bannerInnerImg}
                    role="presentation"
                    onKeyDown={() => {
                        giftBlockBannerTileHandler(
                            title,
                            index,
                        );
                    }}
                    onClick={() => {
                        giftBlockBannerTileHandler(
                            title,
                            index,
                        );
                    }}
                >
                    <div>
                        <img src={imgUrl} alt={imgTitle} className={classes.bannerImg} />
                        <div className={classes.innerCopy}>
                            <div style={titleFontStyle} className={classes.title}>{title}</div>
                            <div style={subCopyStyle} className={classes.subCopyText}>{subCopy}</div>
                            <span
                                onFocus={() => setHoverColor(index)}
                                onBlur={() => setHoverColor('')}
                                onMouseOver={() => setHoverColor(index)}
                                onMouseLeave={() => setHoverColor('')}
                                style={isHoverColor === index ? buttonHoverStyle : buttonFontStyle}
                                className={classes.shopBtn}
                            >
                                Shop Now
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    );
};

GiftBlockBannerFF.propTypes = {
    classes: object.isRequired,
    title: string.isRequired,
    imgUrl: string.isRequired,
    imgTitle: string.isRequired,
    trackEvent: func,
    index: number.isRequired,
    tileHandler: func.isRequired,
    subCopy: string.isRequired,
    showOverlay: bool.isRequired,
    titleFontStyle: object,
    subCopyStyle: object,
    buttonFontStyle: object,
    buttonHoverStyle: object,
    isHoverColor: number.isRequired,
    setHoverColor: func.isRequired,
};

GiftBlockBannerFF.defaultProps = {
    trackEvent: () => {},
    titleFontStyle: {},
    subCopyStyle: {},
    buttonFontStyle: {},
    buttonHoverStyle: {},
};

export default withStyles(styles)(GiftBlockBannerFF);
