/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    templateCollectionData: [
        {
            header: '<h1>Advice From a Sunflower</h1><p>Hold your head high. Look on the sunny day. And always shine.</p>',
            key: '/sunflowers',
            class: 'sunflowersContainer',
        },
    ],
};
export default data;
