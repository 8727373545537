/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, arrayOf, string, bool,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';
import { connect } from 'react-redux';
import GraphqlFindGiftFast from '../../../GraphqlHomePage/Partials/GraphqlFindAGiftFast/GraphqlFindAGiftFast';
import getNextNDates from '../../../../../helpers/getNextNDates';
import BestSellerGiftGuides from './BestSellerGiftGuide';
import ArticleGiftGuide from './ArticleGiftGuide';
import CategoryGiftGuides from './CategoryGiftGuides';
import BannerCategory from './BannerCategory';
import SpotifyWidget from './SpotifyWidget';
import GraphqlSeoHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import ProductsFallbackContainer from '../../../GraphqlCommonComponents/GraphqlProductsFallbackContainer/ProductsFallbackContainer';
import MainHero from './Partials/MainHero';
import CategoryTile from './Partials/CategoryTile';
import SimpleTile from './Partials/SimpleTile';
import TileRowWithHeading from './Partials/TileRowWithHeading';
import Banner from './Partials/Banner';
import { getBrand } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import GraphqlImoc from '../../../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlImoc/GraphqlImoc';

const dateOptions = getNextNDates(10);

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1400,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1400,
            margin: '0 auto',
        },
        '& a': {
            textDecoration: 'none',
            outline: 'none',
        },
    },
    imocBanner: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    heroMainImage: {
        width: '100%',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
    },
    simpleProduct: {
        flex: '0 0 33.333333%',
        maxWidth: '33.333333%',
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    simpleProduct4row: {
        flex: '0 0 25%',
        maxWidth: '25%',
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    simpleProduct5row: {
        flex: '0 0 20%',
        maxWidth: '20%',
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    productBlock: {
        position: 'relative',
        width: '100%',
        paddingRight: 10,
        paddingLeft: 10,
        margin: '15px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    logoImage: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 8,
        margin: '0 auto',
        '& img': {
            width: 'auto !important',
            maxWidth: '100%',
            maxHeight: '67px',
        },
    },
    imageBlock: {
        minHeight: '320px',
        position: 'relative',
        textDecoration: 'none',
        [theme.breakpoints.down(1024)]: {
            minHeight: '260px',
        },
        [theme.breakpoints.down(769)]: {
            minHeight: '200px',
        },
    },
    productImage: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    productName: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 14,
        marginTop: 10,
    },
    headerBlock: {
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        textAlign: 'center',
        width: '45%',
        padding: '0px 60px',
        whiteSpace: 'break-spaces',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            top: '15%',
            padding: '0px 7px',
        },
    },
    findGift: {
        position: 'relative',
        margin: '0 auto',
    },
    heading: {
        fontWeight: 700,
        lineHeight: '1.2',
        '& h2': {
            margin: 0,
            fontSize: '38px',
        },
        '& p': {
            fontSize: '24px',
            margin: '7px 0 ',
        },
        [theme.breakpoints.down(1024)]: {
            '& h2': {
                margin: 0,
                fontSize: '28px',
            },
            '& p': {
                fontSize: '17px',
                margin: '7px 0 ',
            },
        },
        [theme.breakpoints.down(769)]: {
            '& h2': {
                margin: 0,
                fontSize: '18px',
            },
            '& p': {
                fontSize: '18px',
                margin: '0',
            },
        },
    },
    subHeading: {
        '& p': {
            fontSize: '15px',
            margin: '10px 0 ',
            lineHeight: '1.4',
        },
        [theme.breakpoints.down(1024)]: {
            '& p': {
                fontSize: '14px',
            },
        },
        [theme.breakpoints.down(769)]: {
            '& p': {
                fontSize: '12px',
                margin: '5px 0 ',
            },
        },
    },
    subNav: {
        flex: '0 1 auto',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
        '& a': {
            flexGrow: 1,
            fontSize: 12,
            maxWidth: '100%',
            flexBasis: '100%',
            textAlign: 'center',
            textTransform: 'uppercase',
            textDecoration: 'none',
            padding: '10px 0',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
                marginBottom: 3,
            },
        },
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: theme.palette.grey8,
        marginTop: 20,
        border: 0,
    },
    featureBlock: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& img': {
            width: '100%',
        },
    },
    categoryBlock: {
        justifyContent: 'center',
        margin: '20px 0 15px',
        '& img': {
            width: '65%',
            display: 'block',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        '& $imageBlock': {
            display: 'flex',
        },
        '& $productName:after': {
            content: '" \u003E"',
        },
    },
    brandImage3row: {
        '& img': {
            width: '57%',
        },
    },
    brandBlock: {
        width: '100%',
        display: 'flex',
        margin: ' 0 auto',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    brandImage4row: {
        '& img': {
            width: '100%',
        },
    },
    brandHeading: {
        textAlign: 'center',
        width: '100%',
        fontSize: 26,
        padding: '40px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '20px 0',
            fontSize: 20,
        },
    },

    featureHeading: {
        padding: '15px 0',
        fontSize: 25,
        textAlign: 'center',
        width: '100%',
        fontWeight: 'bold',
        overflow: 'hidden',
        margin: '20px 0 25px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
        },
        '&::before,&::after': {
            width: '72%',
            height: 1,
            content: '""',
            display: 'inline-block',
            position: 'relative',
            verticalAlign: 'middle',
            backgroundColor: theme.palette.grey8,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        '&::after': {
            marginRight: '-50%',
            left: '.5em',
        },
        '&::before': {
            marginLeft: '-50%',
            right: '0.5em',
        },

    },
    disclaimer: {
        whiteSpace: 'break-spaces',
        margin: '15px 0',
        padding: '0px 10px',
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    shopButton: {
        padding: '15px 35px',
        display: 'inline-block',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: '1.2px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        [theme.breakpoints.down(1024)]: {
            padding: '10px 30px',
            fontSize: 14,
        },
    },
    motherDayContainer: {
        '& $categoryBlock': {
            '& img': {
                width: '100%',
                maxWidth: '85%',
            },
        },
        '& $brandBlock': {
            width: '100%',
            '& $productBlock': {
                padding: 15,
                borderRight: `1px solid ${theme.palette.grey8}`,
                borderBottom: `1px solid ${theme.palette.grey8}`,
                margin: 0,
                '&:nth-child(5)': {
                    borderRight: '0px solid',
                },
                '&:nth-child(9)': {
                    borderRight: '0px solid',
                },
                '&:last-of-type': {
                    borderRight: '0px solid',
                },
                '& $logoImage': {
                    '& img': {
                        width: '100px !important',
                        marginTop: 10,
                    },
                },
            },
        },
    },
    faq: {
        border: `1px solid ${theme.palette.grey7}`,
        padding: 10,
        backgroundColor: theme.palette.faqBgColor,
        '& h2': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        '& p': {
            margin: '5px 0',
        },
    },
    question: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
    },
    bannerCategory: {
        width: '100%',
        '& img': {
            width: '100%',
        },
    },
    birthdayGiftsContainer: {
        '& $productName': {
            fontWeight: 'bold',
        },
        '& $featureBlock': {
            '& $simpleProduct4row': {
                maxWidth: '18%',
                margin: '0',
            },
            '& $featureHeading': {
                fontSize: '2.25em',
                '&::before,&::after': {
                    width: '65%',
                },
            },
        },
    },
    getWellGiftContainer: {
        '& $heroMainImage': {
            [theme.breakpoints.down('md')]: {
                minHeight: '230px',
            },
        },
        '& $headerBlock': {
            width: '60%',
            [theme.breakpoints.down('md')]: {
                width: '70%',
                padding: '0 25px',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0 10px',
            },
        },
        '& $heading': {
            fontSize: '22px',
            fontFamily: 'Roboto Slab, serif',
            color: theme.palette.cms?.templateCollectionPageHeader || '#00a77d',
            fontWeight: '500',
        },
        '& $shopButton': {
            padding: '9px 35px',
            [theme.breakpoints.down('sm')]: {
                padding: '6px 25px',
            },
        },

    },
    simpleProduct6row: {
        flex: '0 0 16.666666%',
        maxWidth: '16.666666%',
        '& $productImage': {
            minHeight: 'auto',
            '& img': {
                width: '100%',
            },
        },
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    productTitle: {
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        padding: '10px 0px',
        background: theme.palette.common?.black,
        color: theme.palette.common?.white,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    brandHeading6row: {
        textAlign: 'center',
        width: '100%',
        fontSize: 22,
        padding: '20px 0',
        overflow: 'hidden',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        '&::before,&::after': {
            width: '34%',
            height: 1,
            padding: '0 10px',
            content: '""',
            display: 'inline-block',
            position: 'relative',
            verticalAlign: 'middle',
            backgroundColor: theme.palette.grey8,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        '&::after': {
            marginRight: '-50%',
            left: '.5em',
        },
        '&::before': {
            marginLeft: '-50%',
            right: '0.5em',
        },
    },
    articleGiftContainer: {
        width: '100%',
    },
    christmasGiftGuide: {
        '& $categoryBlock': {
            '& img': {
                width: '100%',
            },
        },
        '& [class*="DesktopHero-headerBlock"]': {
            top: '40%',
        },
        '& [class*="DesktopHero-heading"], [class*="MobileHero-heading"]': {
            width: '100%',
            '& h1, h2': {
                fontSize: '5em',
                [theme.breakpoints.down(600)]: {
                    fontSize: '4em',
                },
            },
            '& p': {
                color: theme.palette.cms?.green || '#008000',
                width: '45%',
                margin: '0 auto',
                '&::before': {
                    width: '15%',
                    right: '0.5em',
                    height: '1px',
                    content: '""',
                    display: 'inline-block',
                    position: 'relative',
                    marginLeft: '-50%',
                    verticalAlign: 'middle',
                    backgroundColor: theme.palette.cms?.green || '#008000',
                },
                '&::after': {
                    left: '.5em',
                    width: '15%',
                    height: '1px',
                    content: '""',
                    display: 'inline-block',
                    position: 'relative',
                    marginRight: '-50%',
                    verticalAlign: 'middle',
                    backgroundColor: theme.palette.cms?.green || '#008000',
                },
            },
        },
        '& [class*="CategoryTileBanner-bannerBackgroundImage"]': {
            minHeight: '560px',
            [theme.breakpoints.down(1162)]: {
                minHeight: 700,
            },
            [theme.breakpoints.down(769)]: {
                minHeight: 650,
            },
            [theme.breakpoints.down(600)]: {
                minHeight: 550,
            },
            [theme.breakpoints.down(482)]: {
                minHeight: 460,
            },
            [theme.breakpoints.down(380)]: {
                minHeight: 400,
            },
        },
        '& [class*="CategoryTileBanner-bannerHeading"]': {
            padding: '25px 0 15px 0px',
            fontWeight: 'normal',
            fontStyle: 'italic',
            [theme.breakpoints.up(960)]: {
                fontSize: '55px',
            },
        },
        '& [class*="CategoryTileBanner-leftBannerImage"]': {
            padding: '70px 0px 0px 60px',
        },
        '& [class*="CategoryTileBanner-leftBannerHeading"]': {
            paddingBottom: '0px',
            fontWeight: 'normal',
            fontStyle: 'italic',
            [theme.breakpoints.up(960)]: {
                fontSize: '55px',
            },
            [theme.breakpoints.down(380)]: {
                fontSize: '35px',
            },
        },
        '& [class*="CategoryTileBanner-leftBannerSubHeading"]': {
            fontSize: 16,
            [theme.breakpoints.down(380)]: {
                fontSize: 13,
            },
        },
    },
    bestSellerContainer: {
        width: '100%',
    },
    kindnessGiftsContainer: {
        '& $outerContainer': {
            marginTop: 0,
        },
    },
    outerContainer: {
        marginTop: 30,
    },
    imoc: {
        padding: 10,
    },
});

const TemplateCollectionGiftGuides = ({
    classes, templateData, loadComponent, isMobile, brand,
}) => {
    let categoryBlocks = null;
    let pageUrl = null;
    let seo = {};
    if (templateData?.content?.entries?.length) {
        categoryBlocks = templateData.content.entries[0]?.category_blocks;
        pageUrl = templateData.content.entries[0]?.url;
        ({ seo } = templateData.content.entries[0]);
    }
    const isMotherDayPage =  pageUrl === '/mothers-day-gift-ideas';
    const loadCssUrlBase = () => {
        switch (pageUrl) {
            case '/mothers-day-gift-ideas':
                return classes.motherDayContainer;
            case '/birthdaygifts':
                return classes.birthdayGiftsContainer;
            case '/get-well-gift-ideas':
                return classes.getWellGiftContainer;
            case '/christmas-gift-guide':
                return classes.christmasGiftGuide;
            case '/kindness-gifts':
                return classes.kindnessGiftsContainer;
            default:
                return null;
        }
    };
    const seed = useUIDSeed();
    // regex to match and remove text "font-family:"
    const fontFamily = (data) => data?.replace(/font-family: |;/g, '');
    if (categoryBlocks) {
        return (
            <>
                {seo?.seo_searchable && <GraphqlSeoHeadTags seoData={seo} />}

                <Grid container className={`${classes.container} ${loadCssUrlBase()}`}>
                    {
                        categoryBlocks.map((item) => {
                            const blockKey = Object.keys(item)[0];
                            const {
                                articles = {}, main_hero = {}, best_sellers = {}, categories = {}, disclaimer = {}, sub_nav = {}, gift_finder = {}, faq = {}, customer_reviews_gallery = {},
                            } = item;
                            switch (blockKey) {
                                case 'imoc':
                                {
                                    return (
                                        <div className={classes.imoc}>
                                            <GraphqlImoc
                                                imocBlock={item?.imoc}
                                            />
                                        </div>
                                    );
                                }
                                case 'main_hero': {
                                    return (
                                        <MainHero key={seed(item)} data={main_hero} fontFamily={fontFamily} isMotherDayPage={isMotherDayPage} />
                                    );
                                }
                                case 'category_tile_with_product_images': {
                                    return <CategoryTile key={seed(item)} data={item} brand={brand} fontFamily={fontFamily} />;
                                }
                                case 'simple_tile_row': {
                                    return <SimpleTile key={seed(item)} data={item} fontFamily={fontFamily} />;
                                }
                                case 'tile_row_with_heading': {
                                    return <TileRowWithHeading key={seed(item)} data={item} fontFamily={fontFamily} />;
                                }
                                case 'banner': {
                                    return <Banner key={seed(item)} data={item} isMobile={isMobile} />;
                                }
                                case 'gift_finder': {
                                    return (gift_finder?.gift_finder_reference.length > 0
                                        ? (
                                            <Grid key={seed(item)} item xs={12} className={classes.findGift}>
                                                {loadComponent && <GraphqlFindGiftFast data={gift_finder.gift_finder_reference[0]} dateOptions={dateOptions} />}
                                            </Grid>
                                        )
                                        : null
                                    );
                                }
                                case 'sub_nav': {
                                    return (
                                        <Grid className={classes.outerContainer} key={seed(item)} item xs={12}>
                                            <div className={classes.subNav}>
                                                {
                                                    sub_nav?.link.length > 0
                                                    && sub_nav.link.map((list) => (
                                                        <Link key={seed(list)} style={{ color: `${sub_nav?.font_color}`, backgroundColor: `${sub_nav?.background_color}` }} to={list?.href}>{list?.title}</Link>
                                                    ))
                                                }
                                            </div>
                                        </Grid>
                                    );
                                }
                                case 'categories': {
                                    const { heading = '', categories_per_row } = categories;

                                    if (categories_per_row === 1) {
                                        return (
                                            <Grid key={seed(item)} container>
                                                <BannerCategory categories={categories} classes={classes} />
                                                <hr className={classes.line} />
                                            </Grid>
                                        );
                                    }
                                    if (heading?.includes('Brands')) {
                                        return (
                                            <Grid key={seed(item)} container className={`${classes.brandBlock} ${categories_per_row === 3 ? classes.brandImage3row : classes.brandImage4row}`}>
                                                <div className={classes.brandHeading}>{heading}</div>
                                                {categories ? <CategoryGiftGuides categories={categories} classes={classes} /> : null}
                                            </Grid>
                                        );
                                    }
                                    if (heading?.includes('Love Most') || heading?.includes('SHOP')) {
                                        return (
                                            <Grid key={seed(item)} container className={classes.featureBlock}>
                                                {heading !== '' ? <div className={classes.featureHeading}>{heading}</div> : ''}
                                                {categories ? <CategoryGiftGuides categories={categories} classes={classes} /> : null}
                                                <hr className={classes.line} />
                                            </Grid>
                                        );
                                    }
                                    return (
                                        <Grid key={seed(item)} container className={classes.categoryBlock}>
                                            {heading !== '' && <div className={categories_per_row === 6 ? classes.brandHeading6row : classes.brandHeading}>{heading}</div>}
                                            <CategoryGiftGuides categories={categories} classes={classes} />
                                            <hr className={classes.line} />
                                        </Grid>
                                    );
                                }
                                case 'best_sellers': {
                                    return (loadComponent && <div key={seed(item)} className={classes.bestSellerContainer}><BestSellerGiftGuides bestSeller={best_sellers} /></div>);
                                }
                                case 'articles': {
                                    const { article = [], articles_per_row = '' } = articles;
                                    return <div key={seed(item)} className={classes.articleGiftContainer}> <ArticleGiftGuide article={article} articlesPerRow={articles_per_row} isMobile={isMobile} /> <hr className={classes.line} /> </div>;
                                }
                                case 'disclaimer': {
                                    const { copy = '' } = disclaimer;

                                    const LinkOrReference = (prop) => {
                                        const { href, children } = prop;
                                        return <a href={href} aria-hidden="true" tabIndex="-1">{children}</a>;
                                    };

                                    return (
                                        <Grid key={seed(item)} item xs={12}>
                                            <div className={classes.disclaimer}>
                                                <ReactMarkdown
                                                    source={copy}
                                                    renderers={{
                                                        linkReference: LinkOrReference,
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    );
                                }

                                case 'faq': {
                                    const { faqs = [], title = '' } = faq;
                                    return (
                                        <Grid key={seed(item)} item xs={12} className={classes.faq}>
                                            <h2>{title}</h2>
                                            {
                                                faqs.map((data) => (
                                                    <div key={seed(data)}>
                                                        <p className={classes.question}>{data.question}</p>
                                                        <p>{data.answer}</p>
                                                    </div>
                                                ))
                                            }
                                        </Grid>
                                    );
                                }

                                case 'spotify_widget': {
                                    return <SpotifyWidget key={seed(item)} data={item.spotify_widget} />;
                                }

                                case 'customer_reviews_gallery': {
                                    const { gallery_id = '' } = customer_reviews_gallery;
                                    return (
                                        <div className="yotpo yotpo-pictures-widget" data-gallery-id={gallery_id} />
                                    );
                                }

                                default:
                                    return null;
                            }
                        })
                    }
                </Grid>
            </>
        );
    }
    return <ProductsFallbackContainer />;
};

TemplateCollectionGiftGuides.propTypes = {
    classes: object.isRequired,
    loadComponent: bool,
    templateData: shape({
        category_blocks: arrayOf(
            shape({
                main_hero: shape({
                    sub_header: string.isRequired,
                    link: shape({
                        href: string.isRequired,
                        title: string.isRequired,
                    }),
                    image: shape({
                        url: string.isRequired,
                    }),
                }),
                sub_nav: shape({
                    font_color: string.isRequired,
                    background_color: string.isRequired,
                    link: arrayOf(
                        shape({
                            href: string.isRequired,
                            title: string.isRequired,
                        }),
                    ),
                }),
                gift_finder: object.isRequired,
                articles: object.isRequired,
                best_sellers: object.isRequired,
            }),
        ),

    }).isRequired,
    isMobile: bool.isRequired,
    brand: shape({
        domain: string,
    }).isRequired,
};
TemplateCollectionGiftGuides.defaultProps = {
    loadComponent: true,
};
const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps, null)(withStyles(styles)(TemplateCollectionGiftGuides));
