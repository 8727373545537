/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core';
import {
    arrayOf, object, string, shape,
} from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import SimpleProduct from '../../../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';
import GraphqlBreadcrumbs from '../../../../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import GraphqlIcBanner from '../../../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlIcBanner';
import SubnavImageBottomBanner from '../SubnavImageBottomBanner/SubnavImageBottomBanner';
import SubNavLeftMenuContainer from '../SubNavLeftMenuContainer';
import GraphqlSubnav from '../../../../../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlSubnav/GraphqlSubnav';
import {  getPresentationFamily } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import SubnavVideoContainer from '../SubnavVideoContainer/SubnavVideoContainer';

const styles = (theme) => ({
    collectionWrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        [theme.breakpoints.down('600')]: {
            padding: '0 16px 0',
        },
    },
    leftSideBar: {
        width: '260px',
        [theme.breakpoints.down('600')]: {
            width: '100%',
            marginBottom: '15px',
        },
    },
    mainCollection: {
        width: 'calc(100% - 290px)',
        marginLeft: '30px',
        [theme.breakpoints.down('600')]: {
            width: '100%',
            marginLeft: '0',
        },
    },
    mainCollectionNotExist: {
        width: '100%',
        marginLeft: '30px',
        [theme.breakpoints.down('600')]: {
            width: '100%',
            marginLeft: '0',
        },
    },
    productRow: {
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        marginRight: '-10px',
        marginLeft: '-10px',
        width: 'auto',
    },
    simpleProduct: {
        flex: '0 0 33.333333%',
        marginBottom: '30px',
        maxWidth: '33.333333%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('600')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    banner: {
        padding: '0 10px',
        width: '33.33%',
        [theme.breakpoints.down('600')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    bannerForFourItem: {
        padding: '0 10px',
        width: '25%',
        [theme.breakpoints.down('600')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
    breadCrumb: {
        padding: '0 16px 10px',
        [theme.breakpoints.down('600')]: {
            display: 'none',
        },
    },
    subNavBottomBanner: {
        width: '100%',
    },
    kidHungrySeasonContainer: {
        marginLeft: '287px',
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
            maxWidth: '1024px',
        },
    },
    foodSimpleProduct: {
        flex: '0 0 25%',
        marginBottom: '30px',
        maxWidth: '25%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('600')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
        },
    },
});

const SubnavImageCollection = ({
    classes, templateData, presentation_family, history, productsData,
}) => {
    const currentPath = history?.location?.pathname;
    const breadCrumbArray = templateData?.content?.entries?.[0]?.seo?.breadcrumb || null;
    const guidedNavFormData = templateData?.guidedNavForm?.entries?.[0] || null;
    const guidedNavLocationOverride = templateData?.content?.entries?.[0]?.guided_nav?.override_location_type || null;
    const products = productsData?.length ? productsData : templateData?.category?.products || [];
    const page_blocks = templateData?.content?.entries?.[0]?.page_blocks || [];
    const block_alignment = templateData?.content?.entries?.[0]?.block_alignment?.toLowerCase() || '';
    const LeftMenuData = templateData?.content?.entries?.[0] || [];

    const HASHTAG_URL_IDENTIFIER = '/flowers-gifts-400210491'; // consult with business on what this should be

    const icBannerBlock = [];
    if (page_blocks?.length > 0) {
        page_blocks.forEach((block) => {
            if (block?.in_collection_banners?.show_after_product_number) {
                icBannerBlock[block.in_collection_banners.show_after_product_number - 1] = block.in_collection_banners;
            }
        });
    }
    const productData = products;
    const  loadCssUrlBase = () => {
        switch (currentPath) {
            case '/no-kid-hungry-season-of-sharing':
                return classes.kidHungrySeasonContainer;
            default:
                return null;
        }
    };

    const subNavigation = (block) => {
        if (!block) {
            return null;
        }
        const subNavKey = Object.keys(block);
        const blockKey = subNavKey?.[0];
        const blockObj = block[blockKey];
        switch (blockKey) {
            case 'sub_navigation':
                return <GraphqlSubnav subnavBlock={blockObj} />;
            default:
                return null;
        }
    };
    const getBlock = (block) => {
        if (!block) {
            return null;
        }
        const subNavKey = Object.keys(block);
        const blockKey = subNavKey?.[0];
        const blockObj = block[blockKey];
        switch (blockKey) {
            case 'hero':
                return <SubnavImageBottomBanner bottomBanner={blockObj} />;
            case 'banner':
                return <SubnavImageBottomBanner bottomBanner={blockObj} />;
            case 'video_block':
                return <SubnavVideoContainer videoBlock={blockObj} currentPath={currentPath} hashtagUrlIdentifier={HASHTAG_URL_IDENTIFIER} />;
            default:
                return null;
        }
    };

    const getPageBlockLeftSide = page_blocks.find((da) => da.related_articles || da.related_searches || da.side_rail_banner);
    const checkIsContentValue = (guidedNavFormData || getPageBlockLeftSide) || false;
    const alignmentStylesOverwride = { };
    switch (block_alignment) {
        case 'left':
            alignmentStylesOverwride.textAlign = 'left';
            break;
        case 'center':
            alignmentStylesOverwride.textAlign = 'center';
            break;
        case 'right':
            alignmentStylesOverwride.marginLeft = 290;
            break;
        default:
            alignmentStylesOverwride.textAlign = 'unset';
    }

    /**
     * When on the specified url, the page bloc ordering
     * should be manually adjusted as
     *
     * top banner
     * video
     * products
     * banner
     * disclaimer
     */
    if (currentPath.includes(HASHTAG_URL_IDENTIFIER)) {
        /**
         * first, pull out the respective blocks to make this easier
         */
        let subNavigationBlock = null;
        let topBannerBlock = null;
        let videoBlock = null;
        let bannerBlock = null;
        let disclaimerBlock = null;

        page_blocks.forEach((block) => {
            const subNavKey = Object.keys(block);
            const blockKey = subNavKey?.[0];
            const component = getBlock(block);

            if (blockKey === 'sub_navigation') subNavigationBlock = component;
            if (blockKey === 'top_banner') topBannerBlock = component;
            if (blockKey === 'video_block') videoBlock = component;
            // products
            if (blockKey === 'banner') bannerBlock = component;
            if (blockKey === 'disclaimer') disclaimerBlock = component;
        });

        /**
         * Manually set the specific components for the url
         */
        return  (
            <>
                {subNavigationBlock}
                {topBannerBlock}
                {videoBlock}
                <div className={classes.breadCrumb}>
                    <GraphqlBreadcrumbs breadCrumbArray={breadCrumbArray} />
                </div>
                <div className={classes.collectionWrapper}>
                    {checkIsContentValue
                        ? (
                            <div className={classes.leftSideBar}>
                                <SubNavLeftMenuContainer guidedNavFormData={guidedNavFormData} guidedNavLocationOverride={guidedNavLocationOverride} LeftMenuData={LeftMenuData} />
                            </div>
                        ) : null}
                    <div className={checkIsContentValue ? classes.mainCollection : classes.mainCollectionNotExist}>
                        <div className={classes.productRow}>
                            {productData.length > 0 ? productData.map((data, i) => (
                                <>
                                    { products[i] ?  (
                                        <div className={checkIsContentValue ? classes.simpleProduct : classes.foodSimpleProduct} key={products[i].seo?.url}>
                                            <SimpleProduct showMovieUpsell={LeftMenuData?.movie_download_upsell?.products?.length ? LeftMenuData.movie_download_upsell : false} name={products[i].name} image={products[i].image} url={products[i].seo?.url} skuPriceRange={products[i].skuPriceRange} availability={products[i].availability} partNumber={products[i].partNumber} showSimpleProductRedesignAbTest={false} productBrand={products[i]?.brandId} />
                                        </div>
                                    ) : null }
                                    {  icBannerBlock[i]
                                        ? (
                                            <div className={checkIsContentValue ? classes.banner : classes.bannerForFourItem} key={Object.keys(icBannerBlock)}>
                                                <GraphqlIcBanner icBannerBlock={icBannerBlock[i]} presentation_family={presentation_family} />
                                            </div>
                                        ) : null}
                                </>
                            )) : null}
                        </div>
                    </div>
                </div>
                <div className={`${classes.subNavBottomBanner} ${loadCssUrlBase()}`} style={alignmentStylesOverwride} data-testid="subNavBottomBanner">
                    {bannerBlock}
                    {disclaimerBlock}
                </div>
            </>
        );
    }

    return (
        <>
            {page_blocks ? page_blocks.map((block) => subNavigation(block)) : null}
            <div className={classes.breadCrumb}>
                <GraphqlBreadcrumbs breadCrumbArray={breadCrumbArray} />
            </div>
            <div className={classes.collectionWrapper}>
                {checkIsContentValue
                    ? (
                        <div className={classes.leftSideBar}>
                            <SubNavLeftMenuContainer guidedNavFormData={guidedNavFormData} guidedNavLocationOverride={guidedNavLocationOverride} LeftMenuData={LeftMenuData} />
                        </div>
                    ) : null}
                <div className={checkIsContentValue ? classes.mainCollection : classes.mainCollectionNotExist}>
                    <div className={classes.productRow}>
                        {productData.length > 0 ? productData.map((data, i) => (
                            <>
                                { products[i] ?  (
                                    <div className={checkIsContentValue ? classes.simpleProduct : classes.foodSimpleProduct} key={products[i].seo?.url}>
                                        <SimpleProduct showMovieUpsell={LeftMenuData?.movie_download_upsell?.products?.length ? LeftMenuData.movie_download_upsell : false} name={products[i].name} image={products[i].image} url={products[i].seo?.url} skuPriceRange={products[i].skuPriceRange} availability={products[i].availability} partNumber={products[i].partNumber} showSimpleProductRedesignAbTest={false}  productBrand={products[i]?.brandId} />
                                    </div>
                                ) : null }
                                {  icBannerBlock[i]
                                    ? (
                                        <div className={checkIsContentValue ? classes.banner : classes.bannerForFourItem} key={Object.keys(icBannerBlock)}>
                                            <GraphqlIcBanner icBannerBlock={icBannerBlock[i]} presentation_family={presentation_family} />
                                        </div>
                                    ) : null}
                            </>
                        )) : null}
                    </div>
                </div>
            </div>
            <div className={`${classes.subNavBottomBanner} ${loadCssUrlBase()}`} style={alignmentStylesOverwride} data-testid="subNavBottomBanner">
                {page_blocks ? page_blocks.map((block) => getBlock(block)) : null}
            </div>
        </>
    );
};

SubnavImageCollection.propTypes = {
    classes: object.isRequired,
    templateData: shape({
        content: shape({
            entries: arrayOf(
                shape({
                    seo: shape({
                        breadcrumb: arrayOf(
                            shape({
                                title: string.isRequired,
                                href: string.isRequired,
                            }),
                        ),
                    }),
                }),
            ),
            category: shape({
                products: arrayOf(
                    shape({
                        image: shape({
                            name: string,
                            altText: string,
                        }),
                        name: string,
                        seo: shape({
                            url: string,
                        }),
                        partNumber: string,
                    }),
                ),
            }),
        }),
    }),
    presentation_family: string.isRequired,
    history: object.isRequired,
    productsData: arrayOf(
        shape({
            brand: string.isRequired,
            name: string.isRequired,
            partNumber: string.isRequired,
            id: string.isRequired,
        }),
    ),
};
SubnavImageCollection.defaultProps = {
    templateData: {},
    productsData: [],
};
const mapStateToProps = (state) => ({
    presentation_family: getPresentationFamily(state),

});
const enhance = compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps),
);
export default enhance(SubnavImageCollection);
