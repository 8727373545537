/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
    object, string,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
            padding: '0px 15px',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            margin: '0 auto',
            padding: '0px 15px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            margin: '0 auto',
        },
    },
    mainDisclaimerData: {
        padding: '14px 10px',
        margin: '0 auto',
        fontSize: 15,
        borderTop: `1px solid ${theme?.palette?.colorNeutral40 || '#e0e0e0'}`,
        '& p': {
            textAlign: 'justify',
        },
    },

});
const DisclaimerBlock = ({
    classes, disclaimer, disclaimerRef,
}) => (
    <Grid container id="disclaimer" ref={disclaimerRef} className={classes.container}>
        <Grid item xs={12}>
            <div className={classes.mainDisclaimerData}>
                <ReactMarkdown className={classes.disclaimerText} source={disclaimer} />
            </div>
        </Grid>
    </Grid>
);
DisclaimerBlock.propTypes = {
    classes: object.isRequired,
    disclaimer: string,
    disclaimerRef: object,
};
DisclaimerBlock.defaultProps = {
    disclaimer: '',
    disclaimerRef: {},

};
export default (withStyles(styles)(DisclaimerBlock));
