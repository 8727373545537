/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, arrayOf, string, number, bool,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import ReactMarkdown from 'react-markdown';
import LinkOrATag from '../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const styles = (theme)  => ({
    articleContent: {
        top: '27%',
        left: '1%',
        width: '28%',
        position: 'absolute',
        textAlign: 'center',
    },
    articleBlock: {
        padding: '25px 0',
        marginTop: 15,
        backgroundColor: theme?.palette?.giftGuideArticleBg,
        position: 'relative',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            margin: '15px 5px 0px',
            width: '100%',
        },
    },
    articleHeading: {
        fontSize: 17,
        marginBottom: 11,
    },
    articleSubHeading: {
        marginBottom: 25,
    },
    articleImage: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '25%',
    },
    shopButton: {
        color: theme?.palette?.white,
        backgroundColor: theme?.palette?.continueShoppingBg,
        padding: '8px 16px',
        fontSize: 14,
        boxSizing: 'border-box',
        textTransform: 'uppercase',
        fontWeight: 500,
        borderRadius: 4,
        boxShadow: `0px 1px 5px 0px ${theme?.palette?.imageCardBoxShadow}`,
        margin: '8px 0',
        display: 'inline-block',
        '&:hover': {
            backgroundColor: theme?.palette?.giftGuideHoverColor,
            textDecoration: 'none',
        },
    },
    simpleProduct1row: {
        flex: '0 0 100%',
        maxWidth: '100%',
        '& img': {
            width: '100%',
        },
    },
    simpleProduct2row: {
        flex: '0 0 48%',
        maxWidth: '48%',
        margin: '14px auto',
        '& img': {
            width: '100%',
        },
    },
    simpleProduct3row: {
        flex: '0 0 31%',
        maxWidth: '31%',
        margin: 14,
        '& img': {
            width: '100%',
        },
    },
    padding5: {
        padding: 5,
    },
    articleBlockCopy: {
        maxWidth: '70%',
        margin: 'auto',
        textAlign: 'center',
        '& $articleHeading': {
            fontSize: 18,
            textAlign: 'center',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        '& h2': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            textAlign: 'center',
            fontSize: 18,
        },
        '& ol': {
            paddingLeft: 15,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            margin: '0 10px',
        },
    },
});

const articleRow = (classes, categoryPerRow) => {
    if (categoryPerRow === 2) {
        return  classes.simpleProduct2row;
    }
    if (categoryPerRow === 3) {
        return  classes.simpleProduct3row;
    }
    return classes.simpleProduct1row;
};

const articleBlock = (classes, product) => {
    const href  = product?.link?.href  || '';
    let buttonColorStyle = {};
    buttonColorStyle = {
        color: product?.link_color,
        backgroundColor: product?.link_background_color,
    };
    if (product?.image_placement === 'right') {
        return  (
            <Grid container>
                <Grid xs={6} item className={classes.padding5}>
                    <h3 className={classes.articleHeading}>{product?.heading}</h3>
                    <div className={classes.articleSubHeading} style={{ color: `${product?.copy_color}` }}>{product?.copy} </div>
                    {href && <LinkOrATag className={classes.shopButton} style={buttonColorStyle} href={href} ariaLabel={`Click to ${product?.link?.title}`}> {product?.link?.title}</LinkOrATag>}
                </Grid>
                <Grid xs={6} item className={classes.padding5}>
                    <img src={product?.image?.url} alt={product?.heading} />
                </Grid>
            </Grid>
        );
    }
    if (product?.image_placement === 'left') {
        return  (
            <Grid container>
                <Grid xs={6} item className={classes.padding5}>
                    <img src={product?.image?.url} alt={product?.heading} />
                </Grid>
                <Grid xs={6} item className={classes.padding5}>
                    <h3 className={classes.articleHeading}>{product?.heading}</h3>
                    <div className={classes.articleSubHeading} style={{ color: `${product?.copy_color}` }}>{product?.copy} </div>
                    {href && <LinkOrATag className={classes.shopButton} style={buttonColorStyle} href={href} ariaLabel={`Click to ${product?.link?.title}`}> {product?.link?.title}</LinkOrATag>}
                </Grid>
            </Grid>
        );
    }
    return   (
        <div className={classes.articleContentMobile}>
            <h3 className={classes.articleHeading}>{product?.heading}</h3>
            <div className={classes.articleSubHeading} style={{ color: `${product?.copy_color}` }}><ReactMarkdown source={product?.copy} /></div>
        </div>
    );
};
const ArticleGiftGuide = ({
    classes, article = [], articlesPerRow = '', isMobile,
}) => {
    const seed = useUIDSeed();
    return (
        <Grid container>
            {article.length > 0 ? article.map((product) => (
                isMobile
                    ? (
                        <div key={seed(product)} className={product?.image ? classes.articleBlock :  classes.articleBlockCopy}>
                            {product?.image &&   (
                                <div className={classes.articleImageMobile}>
                                    <img src={product.image?.url} alt={product?.heading} />
                                </div>
                            )                                }
                            <div className={classes.articleContentMobile}>
                                <h3 className={classes.articleHeading}>{product?.heading}</h3>
                                <div className={classes.articleSubHeading} style={{ color: `${product?.copy_color}` }}><ReactMarkdown source={product?.copy} /></div>
                                { product?.link?.href !== ''
                                    ? <LinkOrATag className={classes.shopButton} ariaLabel={`Click to ${product.link?.title}`} style={{ color: product?.link_color, backgroundColor: product?.link_background_color }} href={product.link.href}> {product?.link?.title}</LinkOrATag>
                                    : null}
                            </div>
                        </div>
                    )
                    : (
                        <Grid key={seed(product)} className={`${product?.image ? classes.articleBlock :  classes.articleBlockCopy} ${articleRow(classes, articlesPerRow)}`}>
                            { articleBlock(classes, product)}
                        </Grid>
                    )
            )) : null}
        </Grid>
    );
};

ArticleGiftGuide.propTypes = {
    classes: object.isRequired,
    article: arrayOf(
        shape({
            copy: string.isRequired,
            copy_color: string.isRequired,
            heading: string.isRequired,
            link_background_color: string.isRequired,
            link_color: string.isRequired,
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }),
            image: shape({
                url: string.isRequired,
            }),
        }),
    ).isRequired,
    articlesPerRow: number.isRequired,
    isMobile: bool.isRequired,
};

export default withStyles(styles)(ArticleGiftGuide);
