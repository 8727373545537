/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { getSSRDeviceType } from '../../../../../../../../state/ducks/App/App-Selectors';

const styles = (theme) => ({
    defaultVideoContainer: {
        marginTop: '10px',
    },

    defaultVideo: {
        width: '72%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },

    leftPart: {
        textAlign: 'center',
        width: '55%',
        [theme.breakpoints.down(1050)]: {
            width: '100%',
        },
    },

    rightPart: {
        width: '45%',
        padding: '3% 0 ',
        [theme.breakpoints.down(1050)]: {
            width: '100%',
        },
    },

    contentContainer: {
        '& h1': {
            fontFamily: 'PlayfairDisplayBold',
            fontSize: 28,
            color: theme.palette.cms?.appleHeadingColor  || '#A35497',
            '& strong': {
                color: theme.palette.cms?.headinMiddleText  || '#FE948C',
            },
        },
        '& h1 ,h2': {
            margin: 0,
        },
        '& h2': {
            fontWeight: 'normal',
            fontSize: 18,
        },
        maxWidth: '450px',
        textAlign: 'center',
        margin: '0 auto',
        [theme.breakpoints.down(1050)]: {
            maxWidth: '600px',
            '& h1': {
                fontSize: 24,
            },
            '& h2': {
                fontSize: 16,
            },
        },
    },

    mainContainer: {
        display: 'flex',
        border: ' 24px solid #FFECEA',
        padding: '60px 0',
        [theme.breakpoints.down(1050)]: {
            display: 'block',
            border: ' 14px solid #FFECEA',
            padding: '15px 25px',
        },
    },

    hashtagHeader: {
        textTransform: 'uppercase',
        letterSpacing: '-1px',
        color: '#000000',
        opacity: '1',
        width: '262px',
        height: '56px',
        fontWeight: 'bold',
        fontFamily: 'Lato',
        fontSize: '38px',
        [theme.breakpoints.down(1300)]: {
            fontSize: '28px',
        },
        [theme.breakpoints.down(599)]: {
            fontSize: '18px',
        },
    },

    content: {
        fontSize: 18,
        [theme.breakpoints.down(599)]: {
            fontSize: 16,
        },
    },

    inTextHashtag: {
        fontWeight: 'bold',
    },

    container: {
        padding: '0px 16px 16px 16px',
    },

    videoContainer: {
        margin: '0 auto',
        maxWidth: '610px',
        maxHeight: '343px',
    },

    video: {
        height: '100%',
        width: '100%',
    },
});

const SubnavVideoContainer = ({
    classes, videoBlock, ssrDeviceType, currentPath, hashtagUrlIdentifier,
}) => {
    const posterUrl = videoBlock?.static_image_preview?.url ?? '';
    const videoUrl = videoBlock?.video?.url ?? '';

    let renderHashtagVideoblock = false;
    if (currentPath.includes(hashtagUrlIdentifier)) renderHashtagVideoblock = true;

    /**
     * If we are on a specific URL: '/flowers-gifts-400210491', we want
     * to render specific components, otherwise ignore them
     */
    if (renderHashtagVideoblock) {
        return (
            <div className={classes.container}>
                <div className={classes.mainContainer}>
                    <div className={classes.leftPart}>
                        <div
                            className={classes.videoContainer}
                            id="video-contianer"
                            data-testid="hashtag-subnav-video"
                            alt={videoBlock?.video?.title}
                        >
                            <video
                                controls
                                className={classes.video}
                                poster={posterUrl}
                            >
                                <source src={videoUrl} type="video/mp4" preload="metadata" />
                                <track kind="captions" />
                            </video>
                        </div>
                    </div>
                    <div className={classes.rightPart}>
                        <div className={classes.contentContainer}>
                            <ReactMarkdown source={videoBlock?.header} escapeHTML={false} />
                            { ssrDeviceType !== 'mobile' && <ReactMarkdown source={videoBlock?.sub_header} escapeHTML={false} /> }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={classes.defaultVideoContainer}
            id="video-contianer"
            data-testid="standard-subnav-video"
            alt={videoBlock?.video?.title}
        >
            <video
                controls
                className={classes.defaultVideo}
                poster={posterUrl}
            >
                <source src={videoUrl} type="video/mp4" preload="metadata" />
                <track kind="captions" />
            </video>
        </div>
    );
};
SubnavVideoContainer.propTypes = {
    currentPath: string.isRequired,
    hashtagUrlIdentifier: string.isRequired,
    classes: object.isRequired,
    ssrDeviceType: string.isRequired,
    videoBlock: shape({
        video: shape({
            url: string.isRequired,
        }).isRequired,
    }).isRequired,
};
const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
});
const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles),
);
export default  enhance(SubnavVideoContainer);
