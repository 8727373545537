/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import ReactHTMLParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import {
    object, shape, string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import { compose } from 'recompose';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import { getFeatureFlags } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import findCategoryById from '../../../../../gql/queries/findCategoryById';
import { GRAPHQL_ENV } from '../../../../../gql';
import { getBrandName } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import useExperimentServiceAttributes from '../../../../../helpers/experimentService/useExperimentServiceAttributes';
import PriceBreakDownUi from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceBreakDownUi';

const styles = (theme) => ({
    productName: {
        textAlign: 'left',
        fontWeight: 700,
        fontSize: 16,
    },
    productPrice: {
        textAlign: 'left',
        fontWeight: 700,
        fontSize: 16,
        '& >span': {
            fontWeight: 'normal',
        },
    },
    imageCard: {
        width: '100%',
        marginBottom: 8,
        borderRadius: 0,
        position: ' relative',
        boxShadow: `0 2px 4px 0 ${theme.palette?.imageCardBoxShadow || '#00000010'}`,
    },
    productImage: {
        display: 'block',
        width: 'inherit',
    },
    productList: {
        padding: 8,
        '& a': {
            textDecoration: 'none',
            fontSize: 16,
            color: theme.palette?.sectionHeaderTextColor || '#1f1f1b',
        },

    },
    crossedOutRetailPrice: {
        textDecoration: 'line-through',
        fontWeight: '400',
    },
    salePrice: {
        color: theme.palette.pdp.salePrice || '#a1001a',
    },
    title: {
        textAlign: 'center',
        fontSize: 20,
        margin: '0 0 15px',
    },
    bestSeller: {
        textAlign: 'center',
        justifyContent: 'center',
        '& h4': {
            overflow: 'hidden',
            padding: '15px 0px 5px',
            fontSize: 22,
            fontFamily: 'PlayfairDisplayRegular, serif',
            margin: 0,
            lineHeight: 1,
            fontWeight: 'normal',
        },
    },
    subHeading: {
        fontSize: 16,
        fontWeight: 400,
        padding: 8,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    shopButton: {
        color: theme.palette?.white || '#fff',
        backgroundColor: theme.palette?.continueShoppingBg || '#a1001a',
        padding: '8px 16px',
        fontSize: 14,
        boxSizing: 'border-box',
        textTransform: 'uppercase',
        fontWeight: 500,
        borderRadius: 4,
        boxShadow: `0px 1px 5px 0px ${theme.palette?.imageCardBoxShadow || '#00000010'}`,
        margin: '20px 0px 30px',
        display: 'inline-block',
        '&:hover': {
            backgroundColor: theme.palette?.giftGuideHoverColor || '#8a0016',
            textDecoration: 'none',
        },
    },
    passportEligibleImage: {
        width: '50%',
        display: 'block',
        marginBottom: '3px',
    },
});

const productList = (classes, data, flags, numberOfProducts, seed) => data.map((product, i) => {
    const {
        skuPriceRange,
        isPassportEligible,
    } = product;
    const { sale, retail } = skuPriceRange || {};
    if (product?.seo?.url && i < numberOfProducts) {
        return (
            <Grid key={seed(product)} item xs={6} sm={3} md={3} lg={3} className={classes.productList}>
                <Grid item xs={12}>
                    <Link
                        to={`${product?.seo?.url}`}
                        className={classes.product}
                    >
                        <Card className={classes.imageCard}>
                            <img
                                src={`${product?.image?.path}${product?.image?.name}x.jpg?auto=webp`} // to do: do not hardcode the x and change based on screensize?
                                alt={product?.name}
                                className={classes.productImage}
                            />
                        </Card>
                        {isPassportEligible && flags && flags['is-prime-free-ship-icon-enabled'] === true && <img className={classes.passportEligibleImage} src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt8d4549d3cac15860/61e09d4f2e109d6c649d4aa4/PP_EligibleIcon.svg" alt="Passport Eligible" />}
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography className={classes.productName}>{ReactHTMLParser(product?.name || '')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.productPrice}>
                                    <span>from</span> {(sale && sale?.length > 1 && sale?.[0]?.value.toString() === retail?.[0]?.value.toString() && sale?.[1]?.value.toString() === retail?.[1]?.value.toString()) ? (
                                            `$${retail?.[0]?.value} - $${retail?.[1]?.value}`
                                    ) : (
                                        <div>
                                            {retail && retail?.length > 1 && sale && sale?.length > 0
                                                    && (<><s>${retail?.[0]?.value} - ${retail?.[1]?.value}</s> <span className={classes.salePrice}> {product?.sale || null} ${sale?.[0]?.value} - ${sale?.[1]?.value}</span></>)}
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Link>
                    <PriceBreakDownUi />
                </Grid>
            </Grid>
        );
    }
    return null;
});

const BestSellerGiftGuide = ({
    classes, bestSeller,
}) => {
    const seed = useUIDSeed();
    const brand = useSelector(getBrandName);
    const flags = useSelector(getFeatureFlags);
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
    const PRODUCTS_QUERY = findCategoryById(isGraphqlTargetingEnabled);
    const variables = {
        brand,
        environment: GRAPHQL_ENV,
        locale: 'en-us',
        id: bestSeller?.sub_collection_id || '',
        productOptions: {
            pageSize: bestSeller?.number_of_products || 4,
            page: 1,
        },
        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
    };
    const { data, loading, error } = useQuery(PRODUCTS_QUERY, { variables, context });
    if (loading) {
        return null;
    }
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'BestSellerGiftGuide.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            categoryId: bestSeller?.sub_collection_id,
            error,
        });
        return null;
    }
    const products = data?.findCategoryById?.products || [];
    const numberOfProducts = bestSeller?.number_of_products || 4;
    return (
        <Grid container className={classes.bestSeller}>
            <Grid item xs={12}>
                <h4 className={classes.heading}> {bestSeller?.heading}</h4>
                {bestSeller?.sub_heading && <div className={classes.subHeading}> {bestSeller.sub_heading}</div>}
            </Grid>

            {products && products.length > 0
                ? productList(classes, products, flags, numberOfProducts, seed) : null}
            {bestSeller?.link?.href ? <Link className={classes.shopButton} to={bestSeller?.link?.href}>{bestSeller?.link?.title}</Link> : null}
        </Grid>
    );
};
BestSellerGiftGuide.propTypes = {
    classes: object.isRequired,
    bestSeller: shape({
        heading: string.isRequired,
        sub_heading: string.isRequired,
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }),

    }).isRequired,
};

const enhance = compose(
    withStyles(styles),
);
export default enhance(BestSellerGiftGuide);
