/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

const styles = (theme) => ({
    bottomBannerContainer: {
        '& img': {
            width: '100%',
            display: 'block',
        },
    },
    kidHungrySeason: {
        display: 'inline-block',
        verticalAlign: 'top',
        '&:nth-child(4n)': {
            marginTop: '-55px',
        },
        [theme.breakpoints.down(1280)]: {
            marginLeft: '0px',
        },
        [theme.breakpoints.down(1024)]: {
            display: 'block',
        },
    },

});
const SubnavImageBottomBanner = ({
    classes, bottomBanner, history,
}) => {
    const currentPath = history?.location?.pathname;
    const  loadCssUrlBase = () => (currentPath.includes('no-kid-hungry-season-of-sharing') ? classes.kidHungrySeason  : null);
    const { desktop_image: { url = '' } = {}, link: { href = '' } = {} } = bottomBanner || {};
    const imageUrl = url || bottomBanner?.image?.url;

    const imageBanner = () => <img src={imageUrl} alt="Bottom-banner" />;

    return (
        <div className={`${classes.bottomBannerContainer} ${loadCssUrlBase()}`}>
            {href ? (
                <>
                    {href.includes('://') || href.indexOf('https://') === 0 || href.indexOf('http://') === 0 || href.includes('/blog') || href?.includes('/articles') ? (
                        <a href={href}>{imageBanner()}</a>
                    ) : (
                        <Link to={href}>{imageBanner()}</Link>
                    )}
                </>
            ) : imageBanner()}
        </div>
    );
};
SubnavImageBottomBanner.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    bottomBanner: shape({
        desktop_image: shape({
            url: string.isRequired,
        }).isRequired,
        link: shape({
            href: string.isRequired,
        }).isRequired,
    }),

};
SubnavImageBottomBanner.defaultProps = {
    bottomBanner: {},
};
const enhance = compose(
    withRouter,
    withStyles(styles),
);

export default enhance(SubnavImageBottomBanner);
