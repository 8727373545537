/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object, shape, string } from 'prop-types';
import ReactMarkdown from 'react-markdown';
import ReactHtmlParser from 'react-html-parser';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { useUIDSeed } from 'react-uid';
import DesktopMobile from '../../../../../../../helpers/DesktopMobile/DesktopMobile';

const styles = (theme) => ({
    topBannerContainerMain: {
        display: 'block',
        textAlign: 'center',
        position: 'relative',
        margin: '10px 0px',
        '& img': {
            maxWidth: '100%',
            margin: '0 auto',
        },
        [theme.breakpoints.down('600')]: {
            marginBottom: '20px',
            '& img': {
                minHeight: 'auto',
            },
        },
    },
    topBannerContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        lineHeight: 1.5,
        '& p': {
            fontSize: '16px',
        },
    },
    title: {
        fontSize: '30px',
    },
    subTitle: {
        fontSize: '44px',
    },
    heroTextContainer: {
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: 0,
            padding: 0,
            fontWeight: 'normal',
        },
        '& p': {
            fontSize: '14px',
        },
        '& h1': {
            lineHeight: '1.2',
            fontSize: '42px',
        },
        '& h2': {
            fontSize: '36px',
        },
        '& h3': {
            fontSize: '28px',
        },
    },
    mobileTopBannerContainer: {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        lineHeight: 1.5,
        '& h1': {
            fontSize: '25px',
            margin: '0',
            lineHeight: '30px',
            [theme.breakpoints.down(480)]: {
                fontSize: '20px',
                lineHeight: '20px',
                margin: '0 0 5px 0',
            },
        },
        '& p': {
            fontSize: '16px',
            margin: '0',
            fontWeight: '600',
            [theme.breakpoints.down(480)]: {
                fontSize: '11px',
            },
        },
    },
    sunflowersContainer: {
        top: '40% !important',
        '& p': {
            fontWeight: '400',
        },
        '& h1': {
            lineHeight: '1em',
            fontWeight: '400',
        },
        [theme.breakpoints.down(480)]: {
            '& h1': {
                fontSize: '1.5em',
            },
        },
        [theme.breakpoints.up(480)]: {
            '& h1': {
                fontSize: '2em',
                marginBottom: '10px',
            },
        },
    },
    sunflowersDesktopContainer: {
        top: '40% !important',
        '& p': {
            fontSize: '20px',
        },
    },
});
const SubnavImageTopbanner = ({
    classes, topBanner, mobileBannerData, history,
}) => {
    const currentPath = history.location.pathname;
    const title_copy = topBanner?.title_copy || null;
    const seed = useUIDSeed();
    const title_copy_font_color = topBanner?.title_copy_font_color || null;
    const image = topBanner?.image || null;
    let top_banner_background_color = topBanner?.background_color || null;
    if (top_banner_background_color) {
        if (!top_banner_background_color.includes('#')) {
            top_banner_background_color = `#${top_banner_background_color}`;
        }
    }
    const hero_text_orientation = topBanner?.text_position || null;
    const { url = '', title = 'mobile_top_banner' } = topBanner?.mobile_image || {};
    const mobile_title_copy = topBanner?.mobile_title_copy || '';
    const heroTextOrientation = () => {
        let left;
        let top;
        if (hero_text_orientation === 'left') {
            left = '30%';
        }
        if (hero_text_orientation === 'right') {
            left = '75%';
        }
        if (hero_text_orientation === 'bottom-center') {
            left = '50%';
            top = '80%';
        }
        if (hero_text_orientation === 'top-center') {
            left = '50%';
            top = '40%';
        }
        return {
            color: title_copy_font_color,
            left: left || '50%',
            top: top || '50%',
        };
    };
    const loadCssUrlBase = () => {
        switch (currentPath) {
            case '/sunflowers':
                return classes.sunflowersContainer;
            default:
                return null;
        }
    };
    const loadDesktopCssUrlBase = () => {
        switch (currentPath) {
            case '/sunflowers':
                return classes.sunflowersDesktopContainer;
            default:
                return null;
        }
    };
    const MobileHero = () => (
        <div>
            <img src={url} alt={title} />
            {mobile_title_copy
                ? (
                    <div className={`${classes.mobileTopBannerContainer} ${loadDesktopCssUrlBase()}`} style={heroTextOrientation()}>
                        <ReactMarkdown
                            source={mobile_title_copy}
                            skipHtml
                            className={classes.heroTextContainer}
                        />
                    </div>
                )
                : mobileBannerData?.map((item, i) => (currentPath === item.key ? (
                    <div key={seed(i)} className={`${classes.mobileTopBannerContainer} ${loadCssUrlBase()} `} style={heroTextOrientation()}>
                        {ReactHtmlParser(item.header)}
                    </div>
                )
                    : (
                        null
                    )))}
        </div>
    );
    const DesktopHero = () => (
        <>
            <img src={image && image.url ? image.url : ''} alt="top-banner" />
            <div className={`${classes.topBannerContainer} ${loadDesktopCssUrlBase()}`} style={heroTextOrientation()}>
                <ReactMarkdown
                    source={title_copy}
                    skipHtml
                    className={classes.heroTextContainer}
                />
            </div>
        </>
    );
    return (
        <div id="topBannerContainerMain" className={classes.topBannerContainerMain} style={{ backgroundColor: top_banner_background_color }}>
            <DesktopMobile
                mobile={() =>  <MobileHero />}
                desktop={() => <DesktopHero />}
            />
        </div>
    );
};
SubnavImageTopbanner.propTypes = {
    classes: object.isRequired,
    topBanner: shape({
        name: string.isRequired,
        sub_title: string.isRequired,
        sub_title_font_color: string.isRequired,
        title_copy: string.isRequired,
        title_copy_font_color: string.isRequired,
        image: object.isRequired,
        featureFlags: object.isRequired,
    }),
    history: object.isRequired,
    mobileBannerData: ({
        templateCollectionData: shape({
            header: string.isRequired,
            key: string.isRequired,
            class: string.isRequired,
        }),
    }).isRequired,
};
const enhance = compose(
    withRouter,
);
SubnavImageTopbanner.defaultProps = {
    topBanner: {},
};
export default (enhance(withStyles(styles)(SubnavImageTopbanner)));
