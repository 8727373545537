/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bool, object } from 'prop-types';
import { useSelector } from 'react-redux';
import { useUIDSeed } from 'react-uid';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';

import { withStyles } from '@material-ui/core/styles';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';
import DesktopGiftBlock from './GraphqlGiftPage/DesktopGiftBlock/DesktopGiftBlock';
import MobileGiftBlock from './GraphqlGiftPage/MobileGiftBlock/MobileGiftBlock';
import SEOHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import ProductsFallbackContainer from '../../../GraphqlCommonComponents/GraphqlProductsFallbackContainer/ProductsFallbackContainer';

import findCategoryById from '../../../../../gql/queries/findCategoryById';
import { GRAPHQL_ENV } from '../../../../../gql';
import { getBrand } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import useExperimentServiceAttributes from '../../../../../helpers/experimentService/useExperimentServiceAttributes';

const styles = () => ({
    semCollectionMain: {
        maxWidth: '1400px',
        margin: '0 auto',
        width: '100%',
    },
});
const TemplateCollectionSem = ({ templateData, isMobile, classes }) => {
    const entriesData = templateData?.content?.entries?.[0] || {};
    const page_blocks = entriesData?.page_blocks || [];
    const seo = entriesData?.seo || {};
    const brand = useSelector(getBrand);

    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
    const SEM_SUBCOLLECTION_QUERY = findCategoryById(isGraphqlTargetingEnabled);

    if (!page_blocks.length) {
        return null;
    }

    // Mobile and Desktop tiles correspond to same categories.
    const bannersBlock = page_blocks[1]?.banners?.banner[0]?.banner_group || [];
    const DTBannersData = bannersBlock.map((block, idx) => {
        if (!block.collection_identifier) {
            return null;
        }

        const { data, error, loading } = useQuery(
            SEM_SUBCOLLECTION_QUERY,
            {
                variables: {
                    brand: brand?.domain,
                    environment: GRAPHQL_ENV,
                    locale: 'en-us',
                    id: block.collection_identifier,
                    productOptions: {
                        pageSize: 6,
                        page: 1,
                    },
                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                },
                context,
            },
        );

        if (loading) {
            return null;
        }

        if (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                component: 'TemplateCollectionSem.js',
                message: 'Error loading data',
                collectionIdentifier: block.collection_identifier,
                domain: brand.domain,
                error,
                env: GRAPHQL_ENV,
            });
            return null;
        }
        if (!data) {
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                component: 'TemplateCollectionSem.js',
                message: 'No data returned for query',
                env: GRAPHQL_ENV,
            });
            return null;
        }
        if (!data.findCategoryById || !data.findCategoryById.products) {
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                component: 'TemplateCollectionSem.js',
                message: 'No products returned for query',
                env: GRAPHQL_ENV,
            });
            return null;
        }

        if (data) {
            return {
                pdps: data.findCategoryById.products,
                tileData: {
                    idx,
                    title: block.link.title,
                    link: block.link.href,
                },
            };
        }

        return null;
    });

    if (page_blocks) {
        const seed = useUIDSeed();
        return (
            <div className={classes.semCollectionMain}>
                <SEOHeadTags seoData={seo} />
                {page_blocks && page_blocks.map((block) => (
                    <div key={seed}>
                        <DesktopMobile
                            desktop={() => <DesktopGiftBlock block={block} SEMsubcollections={DTBannersData} isMobile={isMobile} />}
                            mobile={() => <MobileGiftBlock block={block} SEMsubcollections={DTBannersData} isMobile={isMobile} />}
                        />
                    </div>
                ))}
            </div>
        );
    }
    return <ProductsFallbackContainer />;
};

TemplateCollectionSem.propTypes = {
    templateData: object.isRequired,
    isMobile: bool.isRequired,
    classes: object.isRequired,
};

export default (withStyles(styles)(TemplateCollectionSem));
