/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import {
    object, shape, arrayOf, string, func,
} from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { trackEvent as trackEventActions } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    title: {
        fontFamily: 'PlayfairDisplay, serif',
        fontSize: '28px',
        position: 'relative',
        textAlign: 'center',

        '& h1, h2, & h3, & h4, & h5, & h6': {
            fontSize: '30px',
            fontWeight: 'normal',
            position: 'relative',
            display: 'inline-block',
            zIndex: 9,
            background: '#FFF',
            padding: '0 30px',
            margin: '0',
        },
    },
    backgroundStroke: {
        '&::before': {
            content: '""',
            background: theme.palette.common?.black || '#000',
            width: '100%',
            height: '1px',
            position: 'absolute',
            right: 0,
            left: 0,
            top: '50%',
            zIndex: 1,
        },
    },
    ourFavoriteBlockContainer: {
        maxWidth: '1400px',
        width: '100%',
        margin: '0 auto',
    },
    favInnerContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '3em',
        [theme.breakpoints.down(768)]: {
            marginTop: '2em',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    favCard: {
        borderRadius: '36px',
        boxShadow: '0px 9px 18px rgba(75,75,75,0.12)',
        textAlign: 'center',
    },
    mainImage: {
        display: 'block',
        width: '100%',
        borderRadius: '36px 36px 0 0',
    },
    favCardTextWrapper: {
        marginTop: '50px',
        marginBottom: '65px',
    },
    favCardTitle: {
        fontFamily: 'PlayfairDisplay, serif',
        fontSize: '28px',
        marginBottom: '10px',
    },
    favCardDesc: {
        width: '85%',
        margin: '0 auto',
        lineHeight: '20px',
        fontSize: '16px',
        marginBottom: '15px',
    },
    favCardPriceContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        '& p': {
            margin: 0,
            marginRight: '5px',
        },
    },
    favPrice: {
        fontFamily: 'PlayfairDisplayBold, serif',
        fontSize: '24px',
        paddingBottom: '2px',
    },
    favBlockCtaBtn: {
        fontSize: 14,
        textDecoration: 'none',
        display: 'block',
        margin: '0 auto',
        width: 208,
        letterSpacing: '1.47px',
        textTransform: 'uppercase',
        borderRadius: '3px',
        border: '1px solid black',
        lineHeight: '40px',
        fontWeight: '600',
        color: '#000000',
    },
    favCardPadding: {
        margin: '0px 8em',
        [theme.breakpoints.down(1366)]: {
            margin: '0px 3em',
        },
        [theme.breakpoints.down(768)]: {
            marginLeft: '20px',
        },
        [theme.breakpoints.down(1024)]: {
            margin: '0px 0px',
        },
    },
    favCardContainer: {
        width: '27%',
        display: 'flex',
        marginBottom: '3em',
        '&:last-child': {
            marginRight: 0,
        },
        [theme.breakpoints.down(1366)]: {
            width: '30%',
        },
        [theme.breakpoints.down(1024)]: {
            width: '44%',
            margin: '0px 20px',
        },
        [theme.breakpoints.down(768)]: {
            width: '90%',
            maxWidth: '378px',
            marginRight: '20px',
            marginLeft: '20px',
            marginBottom: '30px',
            '&:last-child': {
                marginRight: '20px',
                marginBottom: 0,
            },
        },
    },
    logoImage: {
        width: 'auto',
        margin: '0px auto 20px',
    },
    subHeading: {
        '& *': {
            fontSize: '18px',
            position: 'relative',
            textAlign: 'center',
            margin: 0,
        },
    },
});
const OurFavoritesBlock = ({
    classes, ourFavoritesBlockData, trackEvent, tracking,
}) => {
    if (!ourFavoritesBlockData) {
        return null;
    }
    const seed = useUIDSeed();
    const ourFavoritesBlockTilesData = ourFavoritesBlockData?.tile || [];

    return (
        <div className={classes.ourFavoriteBlockContainer}>
            {ourFavoritesBlockData?.title
                && (
                    <div className={`${classes.title} ${ourFavoritesBlockData?.title_background_stroke ? classes.backgroundStroke : ''}`}>
                        <h2>{ourFavoritesBlockData?.title}</h2>
                    </div>
                )}
            {ourFavoritesBlockData?.sub_heading && <ReactMarkdown className={classes.subHeading}  source={ourFavoritesBlockData.sub_heading} escapeHtml={false} />}
            <div className={classes.favInnerContainer}>
                {ourFavoritesBlockTilesData?.map((tileItem, index) => {
                    // for Banner Button
                    const ctaButton = tileItem?.cta_button?.title || '';
                    const ctaButtonURL = tileItem?.cta_button?.href || '';
                    const favCardPadding = (index + 1) % 3  !== 0 &&  index % 3  !== 0 ? classes.favCardPadding : ''; // adding the class on element
                    return (
                        <div className={`${classes.favCardContainer}  ${favCardPadding}`}>
                            <div className={`${classes.favCard}`} key={seed(tileItem)}>
                                { tileItem?.image?.url && <img className={classes.mainImage} src={tileItem.image.url} alt={tileItem.image.description || 'Floral Subscription Cards'} />}
                                <div className={classes.favCardTextWrapper}>
                                    {
                                        tileItem?.header
                                        && <ReactMarkdown source={tileItem.header} escapeHtml={false} className={classes.favCardTitle} />
                                    }
                                    {tileItem?.logo_image?.url && <img className={classes.logoImage} src={tileItem.logo_image.url} alt="Desktop" /> }
                                    { tileItem?.sub_header && <div className={classes.favCardDesc}>{tileItem.sub_header}</div>}
                                    {tileItem?.price && (
                                        <div className={classes.favCardPriceContainer}>
                                            <p>Starting at</p>
                                            <div className={classes.favPrice}>{tileItem.price}</div>
                                        </div>
                                    ) }
                                    {ctaButtonURL && (
                                        <Link
                                            className={classes.favBlockCtaBtn}
                                            onClick={() => trackEvent({
                                                eventCategory: tileItem.tracking_event_category || tracking?.tracking_event_category || 'Subscriptions',
                                                eventAction: tileItem.tracking_event_action || tracking?.tracking_event_action || 'Our Favorites',
                                                eventLabel: tileItem.tracking_event_label || tileItem?.header,
                                            })}
                                            to={ctaButtonURL}
                                        >
                                            {ctaButton}
                                        </Link>

                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

OurFavoritesBlock.propTypes = {
    classes: object,
    ourFavoritesBlockData: shape({
        tile: arrayOf(
            shape({
                header: string,
                sub_header: string,
                text_color: string,
                tile_background_color: string,
            }),
        ),
    }),
    trackEvent: func.isRequired,
    tracking: shape({
        tracking_event_action: string,
        tracking_event_category: string,
        tracking_event_label: string,
    }),
};
OurFavoritesBlock.defaultProps = {
    classes: {},
    ourFavoritesBlockData: {},
    tracking: {},
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(trackEventActions, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(OurFavoritesBlock);
