/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
    object, bool, arrayOf, shape, string,
} from 'prop-types';
import SubCollectionHeader from './Partials/SubCollectionHeader/SubCollectionHeader';
import SubCollectionBar from './Partials/SubCollectionBar/SubCollectionBar';
import SubCollectionProducts from './Partials/SubCollectionProducts/SubCollectionProducts';
import GraphqlImoc from '../../../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlImoc/GraphqlImoc';
import { getBrand } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import GraphqlDisclaimer from '../../../GraphqlCategoryPage/Partials/GraphqlDisclaimer/GraphqlDisclaimer';
import FaqBlock from '../../../GraphqlCategoryPage/Partials/FaqBlock/FaqBlock';
import RailMenuTabletContainer from '../../../GraphqlCategoryPage/Partials/RailMenuTabletContainer/RailMenuTabletContainer';
import SubNavLeftMenuContainer from '../TemplateCollectionSubnavImageBanner/Partials/SubNavLeftMenuContainer';
import LinkOrATag from '../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import GraphqlSeoHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';

const styles = (theme) => ({
    templateCollectionSubBannerMain: {
        maxWidth: '1400px',
        margin: '0 auto',
    },
    subNavProductRow: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 'auto',
        boxSizing: 'border-box',
        padding: '20px 10px',
        marginRight: '-10px',
        marginLeft: '-10px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
    },
    simpleProduct3Row: {
        boxSizing: 'border-box',
        flex: '0 0 33%',
        marginBottom: '30px',
        maxWidth: '33%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '33.33%',
            flex: '0 0 33.33%',
        },
    },
    simpleSubNavProduct3Row: {
        boxSizing: 'border-box',
        flex: '0 0 25%',
        marginBottom: '30px',
        maxWidth: '25%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '33.33%',
            flex: '0 0 33.33%',
        },
    },
    subNavContainer: {
        boxSizing: 'border-box',
        width: '100%',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    aboveProductHeroCopy: {
        textAlign: 'center',
        padding: '10px 0 20px 0',
        fontSize: '28px',
        '& > *': {
            margin: 0,
            padding: 0,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
            fontWeight: '500',
        },
    },
    belowProductHeroCopy: {
        textAlign: 'center',
        padding: '20px 0 10px 0',
        fontSize: '28px',
        '& > *': {
            margin: 0,
            padding: 0,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
        },
    },
    subCollectionHeader: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down(600)]: {
            border: 'inherit',
        },
        '& p': {
            margin: 0,
            fontSize: '26px',
            [theme.breakpoints.down(600)]: {
                fontSize: '19px',
            },
        },
        '& p:nth-child(2)': {
            fontSize: '60px',
            margin: '7px 0',
        },
    },
    subCollectionBar: {
        display: 'block',
        textAlign: 'center',
        padding: '15px 0',
        fontSize: '26px',
        marginBottom: '30px',
        backgroundColor: theme.palette.colorNeutral20,
        '& p': {
            margin: 0,
            color: theme.palette.common?.black,
            [theme.breakpoints.down('sm')]: {
                '&::before ,&::after': {
                    width: '50%',
                    height: '1px',
                    display: 'inline-block',
                    content: "''",
                    position: 'relative',
                    verticalAlign: 'middle',
                    backgroundColor: theme.palette.common?.black,
                },
                overflow: 'hidden',
                '&::after': {
                    left: '0.5em',
                    marginRight: '-50%',
                },
                '&::before': {
                    right: '0.5em',
                    marginLeft: '-50%',
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            backgroundColor: theme.palette.white,
            margin: '0',
        },
    },
    um0: {
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },
    },
    mainCollection: {
        width: 'calc(100% - 290px)',
        marginLeft: '30px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    collectionWrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
    },
    leftSideBar: {
        width: 260,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    simpleProduct4Row: {
        boxSizing: 'border-box',
        flex: '0 0 25%',
        marginBottom: 10,
        maxWidth: '25%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '25%',
            flex: '0 0 25%',
        },
    },
    simpleSubNavProduct4Row: {
        boxSizing: 'border-box',
        flex: '0 0 20%',
        marginBottom: 10,
        maxWidth: '20%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '50%',
            flex: '0 0 50%',
        },
    },
    simpleProduct5Row: {
        boxSizing: 'border-box',
        flex: '0 0 20%',
        marginBottom: 10,
        maxWidth: '20%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '20%',
            flex: '0 0 20%',
        },
    },
    simpleSubNavProduct5Row: {
        boxSizing: 'border-box',
        flex: '0 0 16.66%',
        marginBottom: 10,
        maxWidth: '16.66%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '20%',
            flex: '0 0 20%',
        },
    },
    simpleProduct2Row: {
        boxSizing: 'border-box',
        flex: '0 0 50%',
        marginBottom: '30px',
        maxWidth: '50%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    simpleSubNavProduct2Row: {
        boxSizing: 'border-box',
        flex: '0 0 50%',
        marginBottom: '30px',
        maxWidth: '50%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    bottomBanner: {
        width: '100%',
        '& img': {
            width: '100%',
        },
    },
});

const TemplateCollectionSubnavBanner = ({
    classes, templateData, brand, isMobile, productsData, device,
}) => {
    const entries = templateData?.content?.entries?.[0] ||  {};
    const products = productsData?.length ? productsData : templateData?.category?.products || [];
    const subNavProductCollection = (templateData?.subNavCollection?.products) ? templateData.subNavCollection.products : null;
    const { sub_nav = {} } = entries?.page_blocks?.find((block) => block?.sub_nav) || {};
    const numOfProducts = sub_nav?.number_of_products || 3;
    const pageBlock = entries?.page_blocks || [];
    const showNoOfProducts = isMobile ? sub_nav?.number_of_product_mobile : sub_nav?.number_of_products;
    const subNavProductRender = subNavProductCollection?.slice(0, showNoOfProducts);
    let imocObj;
    let j;
    for (j = 0; j < pageBlock.length; j += 1) {
        if (pageBlock?.[j]?.imoc) {
            imocObj = pageBlock[j].imoc;
            break;
        }
    }
    const disclaimerObj = pageBlock?.find((block) => block?.disclaimer) || {};
    const faqObj  = pageBlock?.find((block) => block?.faq) || {};
    const bottomBanner = entries?.bottom_banner || {};
    const bottomBannerImage = device === 'desktop' ? bottomBanner?.image : bottomBanner?.image_mobile;
    const { main_page_banner = {} } = entries?.page_blocks?.find((block) => block.main_page_banner) || {};
    const subNavCollectionCopy = main_page_banner?.copy || '';
    const seo =  entries?.seo || {};
    let productPerRowDesktop = { products_per_row: 3 };
    productPerRowDesktop = entries?.product_layout?.find((block) => (isMobile ?  block?.name?.includes('Mobile') :  block?.name?.includes('Desktop')));

    const productPerRow = (rowNumber) => {
        switch (rowNumber) {
            case 5:
                return classes.simpleSubNavProduct5Row;
            case 4:
                return classes.simpleSubNavProduct4Row;
            case 3:
                return classes.simpleSubNavProduct3Row;
            case 2:
                return classes.simpleSubNavProduct2Row;
            default:
                return  classes.simpleSubNavProduct3Row;
        }
    };
    return (
        <div className={classes.templateCollectionSubBannerMain}>
            {
                seo?.seo_searchable && <GraphqlSeoHeadTags seoData={seo} />
            }
            <GraphqlImoc
                imocBlock={imocObj}
                brand={brand}
            />
            <SubCollectionHeader
                classes={classes}
                brand={brand}
                subNavHeaderPosition={sub_nav?.copy_spot}
                subNavHeaderCopy={sub_nav?.copy}
                subNavHeaderFontColor={sub_nav?.copy_font_color}
                subNavHeaderBorderColor={sub_nav?.border_color}
                subNavHeaderBgColor={sub_nav?.copy_background_color}
                subNavProductCollection={subNavProductRender}
                subNavImgInCollection={{
                    img: sub_nav?.image?.url,
                    imgSpot: sub_nav?.image_spot,
                }}
                productPerRow={productPerRow}
                numOfProducts={numOfProducts}
            />
            { subNavCollectionCopy && (
                <SubCollectionBar
                    classes={classes}
                    subCollectionBarBg={main_page_banner?.background_color}
                    subCollectionBarFont={main_page_banner?.font_color}
                    subCollectionCopy={main_page_banner?.copy}
                />
            )}
            <div className={classes.collectionWrapper}>
                <div className={classes.leftSideBar}>
                    <SubNavLeftMenuContainer guidedNavLocationOverride={entries?.guided_nav?.override_location_type} guidedNavFormData={templateData?.guidedNavForm?.entries?.[0] || {}} LeftMenuData={entries} />
                </div>
                <div className={classes.mainCollection}>
                    <SubCollectionProducts
                        classes={classes}
                        brand={brand}
                        subCollectionProducts={products}
                        numOfProducts={productPerRowDesktop?.products_per_row}
                        showMovieData={entries?.movie_download_upsell}
                        entries={entries}
                    />
                </div>
                {bottomBanner
                && (
                    <div className={classes.bottomBanner}>
                        <LinkOrATag href={bottomBanner?.link?.href}>
                            <img src={bottomBannerImage?.url} alt={bottomBanner?.name} />
                        </LinkOrATag>
                    </div>
                )                }
            </div>
            {isMobile ? (
                <RailMenuTabletContainer
                    railMenuData={entries}
                    isMobile={isMobile}
                />
            ) : (
                <>
                    {disclaimerObj?.disclaimer && <GraphqlDisclaimer disclaimerBlock={disclaimerObj.disclaimer} />}
                    {faqObj && <FaqBlock faqBlock={faqObj} /> }
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    device: getSSRDeviceType(state),
});

TemplateCollectionSubnavBanner.propTypes = {
    templateData: object.isRequired,
    classes: object.isRequired,
    brand: object.isRequired,
    isMobile: bool.isRequired,
    productsData: arrayOf(
        shape({
            brand: string.isRequired,
            name: string.isRequired,
            partNumber: string.isRequired,
            id: string.isRequired,
        }),
    ).isRequired,
    device: string.isRequired,
};

export default connect(mapStateToProps, null)(withStyles(styles)(TemplateCollectionSubnavBanner));
