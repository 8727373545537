/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    shape, string, func, bool,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import { Add, Remove } from '@material-ui/icons';
import { useUIDSeed } from 'react-uid';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles((theme) => ({
    headerBlock: {
        top: '0',
        width: '100%',
        margin: '0 auto',
        padding: '0px 7px',
        position: 'absolute',
        textAlign: 'center',
        whiteSpace: 'break-spaces',
        right: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        lineHeight: 0.9,
    },
    imocBanner: {
        position: 'relative',
        display: 'block',
    },
    heroMainImage: {
        width: '100%',
        display: 'block',
    },
    heading: ({ data }) => ({
        fontWeight: 700,
        lineHeight: '1.2',
        '& h1': {
            margin: 0,
            fontSize: 34,
            color: data?.heading_font_color?.color || '#ffffff',
            '& strong': {
                color: theme.palette.cms?.subHeadercolor  || '#fe948c',
            },
        },
        '& h3': {
            overflow: 'hidden',
            margin: '10px 0px 0px',
            color: theme.palette.cms?.subHeadercolor || '#A35497',
            '&::before': {
                width: '10%',
                right: '0.5em',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: theme.palette.cms?.subHeadercolor || '#A35497',
            },
            '&::after': {
                left: '.5em',
                width: '10%',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: theme.palette.cms?.subHeadercolor || '#A35497',
            },
        },
        '& h2': {
            margin: 0,
            fontSize: '18px',
            color: data?.heading_font_color?.color || '#ffffff',
        },
        '& p': {
            fontSize: '18px',
            margin: '0',
        },
    }),
    subHeading: {
        '& p': {
            fontSize: 16,
            lineHeight: '1.4',
            margin: '2px 0',
        },
        '& h2': {
            margin: 0,
            fontSize: 18,
            overflow: 'hidden',
            '&::before': {
                width: '15%',
                right: '0.5em',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: theme.palette.cms?.black || '#000000',
            },
            '&::after': {
                left: '.5em',
                width: '15%',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                backgroundColor: theme.palette.cms?.black || '#000000',
            },
        },
    },
    shopButton: {
        padding: '10px 30px',
        display: 'inline-block',
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: '1.2px',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    listBlock: {
        width: '100%',
        '& a': {
            textDecoration: 'none',
            display: 'block',
        },
        '& ul': {
            padding: 0,
            margin: 0,
        },
        '& li': {
            listStyle: 'none',
            borderTop: '1px solid',
            textAlign: 'center',
            padding: '4px 0px',
            fontWeight: '600',
            fontSize: '16px',
        },
    },
    mainHeroBanner: {
        position: 'relative',
        width: '100%',
    },
    mainListBlock: {
        position: 'absolute',
        top: 35,
        left: 100,
    },
    accordian: {
        margin: '0px !important',
        '& svg': {
            color: theme.palette.cms?.white || '#fff',
            width: 20,
        },
    },
    summary: {
        '& div': {
            margin: '0px !important',
            flexGrow: 'inherit',
            padding: 0,
        },
        background: theme.palette.cms?.categoryBackground || '#931614',
    },
    accordianHeading: {
        margin: 0,
        color: theme.palette.cms?.white || '#fff',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    accordianDetails: {
        padding: 0,
    },
    motherdaySubHeading: {
        width: '90%',
        bottom: '17%',
        position: 'absolute',
        margin: 'auto',
        textAlign: 'center',
        left: 0,
        right: 0,
    },
}));

const headingAlign = (position) => {
    let headingMargin = 'auto';
    if (position === 'right') {
        headingMargin = '0 0 0  auto';
    }
    if (position === 'left') {
        headingMargin = '0 0 auto';
    }
    return headingMargin;
};
// List View
const ListData = (classes, list, listFontStyle) => {
    const seed = useUIDSeed();
    return (
        <div className={classes.listBlock}>
            <ul>
                {list?.link?.map((item) => (
                    <LinkOrATag className={classes.link} key={seed(item)} style={listFontStyle} href={item?.href} ariaLabel={item?.title}>
                        <li>
                            {item?.title}
                        </li>
                    </LinkOrATag>
                ))}
            </ul>
        </div>
    );
};

// Hero Banner Grid
const heroBlock = (classes, position, href, title, url, header, sub_header, button_copy, buttonStyleOverridess, headerStyle, subHeaderStyle, borderStyle, isMotherDayPage) => (
    <Grid item xs={12} className={classes.imocBanner}>
        {href ?  (
            <LinkOrATag href={href}>
                <img src={url} alt={title} className={classes.heroMainImage} />
            </LinkOrATag>
        )
            : <img src={url} alt={title} className={classes.heroMainImage} />}
        <div className={classes.headerBlock} style={position}>
            <div style={borderStyle}>
                <div style={headerStyle}><ReactMarkdown source={header} className={classes.heading} escapeHtml={false} /></div>
                {!isMotherDayPage && <div style={subHeaderStyle}> <ReactMarkdown source={sub_header} className={classes.subHeading} /></div>}
                { button_copy &&  <div className={classes.shopButton} style={buttonStyleOverridess}><LinkOrATag href={href}>{button_copy}</LinkOrATag></div>}
            </div>
        </div>
        {isMotherDayPage && <div style={subHeaderStyle}> <ReactMarkdown source={sub_header} className={classes.motherdaySubHeading} /></div>}
    </Grid>
);

const MobileHero = ({ data, fontFamily, isMotherDayPage }) => {
    const [expandedData, setExpand] = useState(true);
    const position = {};
    if (data) {
        const  buttonPosition  = data?.mobile_heading_alignment === 'right' ? 'flex-end' :   data?.mobile_heading_alignment || 'center';
        if (buttonPosition) {
            position.justifyContent = buttonPosition;
            position.top =  ((data?.mobile_heading_alignment === 'bottom' && 'auto') || (data?.mobile_heading_alignment === 'top' && '15px'));
            position.bottom =  (data?.mobile_heading_alignment === 'bottom' || data?.mobile_heading_alignment === 'center') && 0;
        }
    }
    const linkList = data?.link_list;
    // CMS Mobile style override
    const mobileStyleOverride = (backgroundColor = '', color = '', fonts = '', borderInPx, width = '', margin = '') => ({
        backgroundColor: backgroundColor || '',
        color: color || '',
        fontFamily: fonts || '',
        border: `${borderInPx}px solid #F1B858` || '',
        width: width  || '',
        margin: margin  || '',
    });
    const buttonStyleOverrides = mobileStyleOverride(data?.button_background_color,  data?.button_font_color);
    const listFontStyle =  mobileStyleOverride('', '#000000', fontFamily(linkList?.font_family));
    const headerStyle = mobileStyleOverride('',  '',  fontFamily(data?.heading_font_family), '', '100%', headingAlign(data?.mobile_heading_alignment));
    const subHeaderStyle = mobileStyleOverride('',  data?.sub_heading_font_color?.color,  fontFamily(data?.sub_heading_font_family));
    const borderStyle =  mobileStyleOverride('',  '',  '', data?.border_width, '100%');
    const classes = useStyles({ data });
    return (
        <div className={classes.mainHeroBanner}>
            <div className={classes.mainImageBanner}>
                { heroBlock(classes, position, data?.link?.href, data?.link?.title, data?.mobile_image?.url, data?.mobile_header, data?.mobile_sub_header, data?.button_copy, buttonStyleOverrides, headerStyle, subHeaderStyle, borderStyle, isMotherDayPage)}
            </div>
            {linkList?.link?.length > 0
            && (
                <Accordion className={classes.accordian}>
                    <AccordionSummary
                        style={{ backgroundColor: '#B1379E' }}
                        className={classes.summary}
                        expandIcon={expandedData ? <Add />  : <Remove />}
                        aria-controls="panelcontent"
                        id="panelheader"
                        onClick={() => setExpand(!expandedData)}
                    >
                        <p className={classes.accordianHeading}>Categories</p>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordianDetails}>
                        {ListData(classes, linkList, listFontStyle)}
                    </AccordionDetails>
                </Accordion>
            )            }
        </div>
    );
};

MobileHero.propTypes = {
    data: shape({
        mobile_header: string.isRequired,
        mobile_sub_header: string.isRequired,
        mobile_image: shape({
            url: string.isRequired,
        }.isRequired),
        link: shape({
            href: string.isRequired,
        }.isRequired),
    }).isRequired,
    fontFamily: func.isRequired,
    isMotherDayPage: bool.isRequired,
};

export default MobileHero;
