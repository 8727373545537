/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import mbpLogger from 'mbp-logger';
import {
    object, shape, arrayOf, string, number, array, func,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// components
import GiftBlockBanner from './Partials/GiftBlockBanner';
import GiftBlockBannerFF from './Partials/GiftBlockBannerFF';
import SEMTemplateDropdown from '../../../../../GraphqlCommonComponents/GraphqlSEMTemplateDropdown/SEMTemplateDropdown';
import GiftBlockHeader from './Partials/GiftBlockHeader';
import GiftBlockBottom from './Partials/GiftBlockBottom';
import NUp from '../../../../../../ContentTypeComponents/NUp/NUp';
import GenericBannerData from '../../../../../../ContentTypeComponents/GenericBanner/GenericBanner';
// helpers
import booleanGetCutoffTimes from '../../../../../../../helpers/booleanGetCutoffTimes';
import { trackEvent as track } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlags } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import BlockHeading from '../../../../../../ContentTypeComponents/BlockHeading';

const styles = (theme) => ({
    bannerContainer: {
        width: '75%',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    bottomContainer: {
        width: '60%',
        margin: 'auto',
        paddingBottom: '25px',
    },
});

const DesktopGiftBlock = ({
    classes, block, featureFlags, SEMsubcollections, trackEvent,
}) => {
    const [tileIndex, setTileIndex] = useState(-1);
    const [isHoverColor, setHoverColor] = useState('');

    /*
     * @description hook handler when tile or close button is clicked
     * @param {int} tile index with default
     * @return {} updates state
     */
    const tileHandler = (idx = -1) => {
        if (idx >= 0) {
            setTileIndex(idx);
        } else if (idx < 0) {
            setTileIndex(-1);
        }
    };

    /*
     * @description helper function to determine if first tile is 'Same-Day' or 'Best-Sellers'
     * @param {object} cutoff data
     * @param {int} index from component map
     * @return {bool} depending on params and cutoff time helper
     * uses booleanGetCutoffTimes helper function
     */
    const sameDayAndBestSellerTileHelper = (cutoff_banner, idx) => (idx === 0 && booleanGetCutoffTimes() && cutoff_banner?.image?.url);

    const key = Object.keys(block)[0];
    const renderHeader = () => {
        const header = block?.header || {};
        const url = header.icon?.url || '';
        const title = header.icon?.title || '';
        const copy = header.copy || '';
        if (!url || !title || !copy) {
            mbpLogger.logDebug({
                message: `No url, title, or copy returned for Desktop Gift Block Headers block \n url data ${url} \n title data ${title} \n copy data ${copy}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
            return null;
        }
        return <GiftBlockHeader url={url} title={title} copy={copy} />;
    };

    // regex remove text "font-family:" from string
    const setFontFamily = (fontData) => {
        let getFont = '';
        if (fontData) {
            getFont = fontData?.replace(/font-family: |;/g, '');
        }
        return getFont;
    };

    const renderBanners = () => {
        // eslint-disable-next-line
        const { banners: { banner = [] } = {} } = block;
        const [banner_group = ''] = banner?.filter((viewport) => viewport?.name === 'Desktop Banner');
        const cutoff_banner = banner[0]?.cuttoff_image;
        const bannerGroupStyling = banner_group?.banner_group_font_styling || {};
        if (!banner_group) {
            mbpLogger.logDebug({
                message: `No banner_group returned for Desktop Gift Block Banners block \n banner_group data ${banner_group}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
            return null;
        }

        let titleFontStyle = {};
        let subCopyStyle = {};
        let buttonFontStyle = {};
        let buttonHoverStyle = {};

        const borderColorThemeNone = bannerGroupStyling?.button_border_color?.colorTheme === 'none';
        const buttonolorThemeNone = bannerGroupStyling?.button_background_color?.colorTheme === 'none';

        // Banner Heading Style
        titleFontStyle = {
            fontSize: `${bannerGroupStyling?.heading_font_size || 30}px`,
            color: bannerGroupStyling?.heading_color?.color,
            fontFamily: setFontFamily(bannerGroupStyling?.heading_font_style),
        };

        // Banner Copy Style
        subCopyStyle = {
            fontSize: `${bannerGroupStyling?.copy_font_size || 16}px`,
            color: bannerGroupStyling?.copy_color?.color,
            fontFamily: setFontFamily(bannerGroupStyling?.copy_font_family),
        };
        // Button withoutHover Style
        buttonFontStyle = {
            fontSize: `${bannerGroupStyling?.button_font_size || 14}px`,
            color: bannerGroupStyling?.button_text_color?.color,
            borderColor: borderColorThemeNone ? 'transparent' : bannerGroupStyling?.button_border_color?.color,
            backgroundColor: buttonolorThemeNone ? 'transparent' : bannerGroupStyling?.button_background_color?.color,
        };
        // Button Hover Style
        buttonHoverStyle = {
            backgroundColor: bannerGroupStyling?.button_hover_color?.color,
        };

        // eslint-disable-next-line
        if (featureFlags['is-sem-template-dropdown-enabled']) {
            const allTiles = banner_group?.banner_group.map((tile, i) => {
                const title = tile?.link?.title || '';
                const href = tile?.link?.href || '';
                const url = tile?.image?.url || '';
                const numberOfProductsToShow = tile?.number_of_products_to_show;
                const collectionIdentifier = tile?.collection_identifier;
                const subCopyText = tile?.sub_copy || '';
                if (!title || !href || !url) {
                    return null;
                }

                if (sameDayAndBestSellerTileHelper(cutoff_banner, i)) {
                    return (
                        <GiftBlockBannerFF
                            data-testid="sem-cutoff-ssd-test"
                            key="sem-cutoff-img"
                            title={cutoff_banner.link?.title}
                            imgUrl={cutoff_banner.image?.url}
                            imgTitle={cutoff_banner.link?.title}
                            index={i}
                            titleFontStyle={titleFontStyle}
                            subCopyStyle={subCopyStyle}
                            buttonFontStyle={buttonFontStyle}
                            buttonHoverStyle={buttonHoverStyle}
                            tileHandler={tileHandler}
                            showOverlay={tileIndex === i}
                            isHoverColor={isHoverColor}
                            setHoverColor={setHoverColor}
                            trackEvent={trackEvent}
                        />
                    );
                }
                if (i % 2 === 0) {
                    return (
                        <GiftBlockBannerFF
                            key={`${title}`}
                            title={title}
                            imgUrl={url}
                            subCopy={subCopyText}
                            imgTitle={title}
                            titleFontStyle={titleFontStyle}
                            subCopyStyle={subCopyStyle}
                            buttonHoverStyle={buttonHoverStyle}
                            buttonFontStyle={buttonFontStyle}
                            index={i}
                            tileHandler={tileHandler}
                            showOverlay={tileIndex === i}
                            trackEvent={trackEvent}
                            isHoverColor={isHoverColor}
                            setHoverColor={setHoverColor}
                        />
                    );
                }
                if (i % 2 !== 0) {
                    return (
                        <>
                            <GiftBlockBannerFF
                                key={`${title}`}
                                title={title}
                                link={href}
                                imgUrl={url}
                                imgTitle={title}
                                subCopy={subCopyText}
                                titleFontStyle={titleFontStyle}
                                subCopyStyle={subCopyStyle}
                                buttonFontStyle={buttonFontStyle}
                                buttonHoverStyle={buttonHoverStyle}
                                collectionId={collectionIdentifier}
                                amount={numberOfProductsToShow}
                                index={i}
                                tileHandler={tileHandler}
                                showOverlay={tileIndex === i}
                                trackEvent={trackEvent}
                                isHoverColor={isHoverColor}
                                setHoverColor={setHoverColor}
                            />
                            <SEMTemplateDropdown
                                twoSEMsubcollections={{
                                    subcollection1: SEMsubcollections[i - 1],
                                    subcollection2: SEMsubcollections[i],
                                }}
                                tileIndex={tileIndex}
                                tileHandler={tileHandler}
                                trackEvent={trackEvent}
                                amount={4}
                            />
                        </>
                    );
                }

                return null;
            });

            return (
                <div className={classes?.bannerContainer} data-testid="featureFlag-giftBlockBanner">
                    <Grid container>
                        {allTiles}
                    </Grid>
                </div>
            );
        }

        return (
            <div className={classes?.bannerContainer}>
                <Grid container>
                    {banner_group?.banner_group?.map((group, i) => {
                        const title = group?.link?.title || '';
                        const href = group?.link?.href || '';
                        const url = group?.image?.url || '';
                        const subCopyText = group?.sub_copy || '';

                        if (!title || !href || !url) {
                            return null;
                        }

                        if (sameDayAndBestSellerTileHelper(cutoff_banner, i)) {
                            return (
                                <GiftBlockBanner
                                    titleFontStyle={titleFontStyle}
                                    subCopyStyle={subCopyStyle}
                                    buttonFontStyle={buttonFontStyle}
                                    buttonHoverStyle={buttonHoverStyle}
                                    subCopy={subCopyText}
                                    key={`sem-cutoff-img - ${title}`}
                                    title={cutoff_banner.link?.title}
                                    link={cutoff_banner.link?.href}
                                    imgUrl={cutoff_banner.image?.url}
                                    index={i}
                                    imgTitle={cutoff_banner.link?.title}
                                    isHoverColor={isHoverColor}
                                    setHoverColor={setHoverColor}
                                />
                            );
                        }

                        return (
                            <>
                                <GiftBlockBanner
                                    buttonHoverStyle={buttonHoverStyle}
                                    buttonFontStyle={buttonFontStyle}
                                    titleFontStyle={titleFontStyle}
                                    subCopyStyle={subCopyStyle}
                                    subCopy={subCopyText}
                                    key={`${href} - ${title}`}
                                    title={title}
                                    index={i}
                                    link={href}
                                    imgUrl={url}
                                    imgTitle={title}
                                    isHoverColor={isHoverColor}
                                    setHoverColor={setHoverColor}
                                />
                            </>
                        );
                    })}
                </Grid>
            </div>
        );
    };

    const renderBottom = () => {
        // eslint-disable-next-line
        const { bottom: { bottom_banner = [] } = {} } = block;
        const { image = '' } = bottom_banner?.[0];
        if (!image) {
            mbpLogger.logDebug({
                message: `No bottom_banner returned for Desktop Gift Block Bottom block \n image data ${image}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
            return null;
        }
        return (
            // eslint-disable-next-line
            <div className={classes.bottomContainer}>
                <Grid container>
                    <GiftBlockBottom image={image} />
                </Grid>
            </div>
        );
    };

    switch (key) {
        case 'header':
            return renderHeader();

        case 'banners':
            return renderBanners();

        case 'bottom':
            return renderBottom();

        case 'nup':
            return (
                <>
                    {block?.nup?.heading && <BlockHeading data={block?.nup} />}
                    <NUp data={block?.nup} />
                </>
            );

        case 'banner':
            return <GenericBannerData data={block?.banner} />;

        default:
            mbpLogger.logError({
                message: `Unrecognized page_block for Template Collection Sem Page Desktop Gift Block. \n Please remove from ContentStack \n Block name key = ${key}, Block Data = ${block}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
            return null;
    }
};

DesktopGiftBlock.propTypes = {
    classes: object.isRequired,
    block: shape({
        header: shape({
            copy: string,
        }),
        banners: shape({
            banner: arrayOf(shape({
                banner_group: array,
            })),
        }),
        bottom: shape({
            bottom_banner: arrayOf(shape({
                image: object,
            })),
        }),
    }),
    featureFlags: object.isRequired,
    trackEvent: func.isRequired,
    SEMsubcollections: arrayOf(shape({
        pdps: array,
        tileData: {
            idx: number,
            title: string,
            link: string,
        },
    })),
};

DesktopGiftBlock.defaultProps = {
    block: {},
    SEMsubcollections: [],
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(DesktopGiftBlock));
