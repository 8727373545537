/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { object } from 'prop-types';
import React from 'react';
import useSSRMediaQuery from '../../../../../../helpers/hooks/useSSRMediaQuery';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const GenericBanner = ({
    data,
}) => {
    const device = useSSRMediaQuery();
    const title = data?.trackable_link?.link?.title || '';
    const link = data?.trackable_link?.link?.href || '';
    const banner = (
        <ResponsiveImage
            path={device === 'mobile' && data?.mobile_image?.url ? data?.mobile_image?.url : data?.desktop_image?.url}
            alt={title}
            style={{
                objectFit: 'cover',
                maxWidth: '100%',
            }}
        />
    );
    if (link) {
        return (
            <LinkOrATag
                isTrackEventEnabled
                trackingEventAction={data?.trackable_link?.event_action}
                trackingEventCategory={data?.trackable_link?.event_category}
                trackingEventLabel={data?.trackable_link?.event_label}
                title={title}
                href={link}
            >
                {banner}
            </LinkOrATag>
        );
    }
    return (
        <>{banner}</>
    );
};

GenericBanner.propTypes = {
    data: object,
};

GenericBanner.defaultProps = {
    data: {},
};

export default GenericBanner;
