/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';

const BannerCategory = ({
    classes, categories,
}) => {
    const category = categories?.category || [];
    return (
        category.map((product) => {
            const seed = useUIDSeed();
            return (
                <Grid
                    key={seed(product)}
                    item
                    className={classes.bannerCategory}
                >
                    <Link
                        to={product?.link?.href}
                        className={classes.productImage}
                    >
                        <img
                            src={product?.image?.url}
                            alt={product?.link?.title}
                        />
                    </Link>
                </Grid>
            );
        }));
};
BannerCategory.propTypes = {
    classes: object.isRequired,
    categories: shape({
        product: arrayOf(
            shape({
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }),
                image: shape({
                    url: string.isRequired,
                }),
            }),
        ),
    }),
};

export default BannerCategory;
