/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useEffect, createRef } from 'react';
import { useUIDSeed } from 'react-uid';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import {
    arrayOf, shape, string, number, bool, array, func, object,
} from 'prop-types';
import Slider from 'react-slick';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import parseFontFamilyExtension from '../../../../helpers/contentstack/parseFontFamilyExtension';

// components
import ProductUI from './Partials/ProductUI';
import customBreakpoints from '../../../../helpers/customBreakpoints';
import ConnectedDesktopSimpleProduct from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/DesktopSimpleProduct';
import { getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';
import { getBrandName } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import noop from '../../../../helpers/noop';

const useStyles = makeStyles((theme) => ({
    title: (styles) => ({
        color: styles?.displayTitleFontColor,
        fontFamily: styles?.displayTitleFontFamily,
        fontSize: styles?.displayTitleFontSize,
        fontStyle: styles?.displayTitleFontStyle,
        // add other header tags here ONLY if needed for SEO/styling
        '& h3, & p': {
            margin: styles?.displayTitleMargin || '44px 0 20px 0',
            textAlign: 'center',
            fontSize: styles?.displayTitleFontSize || '26px',
            fontWeight: '400',
            letterSpacing: '.4px',
            color: 'inherit',
            [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
                margin: styles?.displayTitleMargin || '30px 0 20px 0',
                fontSize: 16,
                fontWeight: 500,
            },
        },
    }),
    productContainerWithScroll: (styles) => ({
        display: 'flex',
        margin: '0 auto',
        padding: '0 0 10px 0',
        width: '100%',
        maxWidth: '1400px',
        justifyContent: 'flex-start',
        overflowX: 'auto',
        marginBottom: styles?.displayContainerBottomMargin,
    }),
    productContainer: {
        display: 'flex',
        margin: '0 auto',
        padding: '0 0 10px 0',
        width: '100%',
        maxWidth: '1400px',
        justifyContent: 'center',
        '&--space-evenly': {
            justifyContent: 'space-around',
            [theme.breakpoints.down(1025)]: {
                justifyContent: 'space-around',
                overflowX: 'inherit',
            },
        },
        '&--center': {
            justifyContent: 'center',
            [theme.breakpoints.down(1025)]: {
                justifyContent: 'center',
            },
        },
        [theme.breakpoints.down(1025)]: {
            overflowX: 'scroll',
            justifyContent: 'flex-start',
        },
    },
    main: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        '& .slick-track': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: '0 15px',
            position: 'relative',
        },
    },
    container: {
        '& .slick-track': {
            width: '100% !important',
        },
        '& .slick-slide': {
            maxWidth: 'calc(18.5%)',
        },
        [theme.breakpoints.down(770)]: {
            '& .slick-slide': {
                maxWidth: 'calc(33.3%) !important',
            },
        },
    },
    fixWidth: (props) => ({
        '& .slick-slide': {
            maxWidth: props.totalNumberOfProducts <= 5 ? 'calc(20%)' : '300px',
        },
        [theme.breakpoints.down(602)]: {
            '& .slick-slide': {
                maxWidth: '300px !important',
            },
        },
    }),
    sliderContainer: (props) => ({
        '& .slick-list': {
            overflow: 'inherit',
            margin: '0 0px',
        },
        '& .slick-prev, & .slick-next': {
            transform: 'translate(0,-50%)',
            cursor: 'pointer',
            border: 'none',
            outline: 0,
            fontSize: 0,
            lineHeight: 0,
            top: '42%',
            width: 16,
            height: 30,
            position: 'absolute',
            background: 'transparent',
        },
        '& .slick-prev': {
            left: 0,
        },
        '& .slick-next': {
            right: 0,
        },
        '& .slick-dots': {
            listStyle: 'none',
            display: 'flex !important',
            padding: '0',
            justifyContent: 'center',
            margin: '0',
            '& li': {
                lineHeight: '1',
            },
            '& .slick-active': {
                '& div': {
                    background: theme.palette.cms?.sliderNavDotSelectedBackground || 'rgb(47, 47, 47)',
                },
            },
            '& .slick-current': {
                '& div': {
                    paddingRight: '5px',
                },
            },
        },
        [theme.breakpoints.between(602, 770)]: {
            '& .slick-track': {
                width: props.totalNumberOfProducts <= 3 && '100% !important',
            },
            '& .slick-slide': {
                maxWidth: props.totalNumberOfProducts <= 3 && 'calc(33.3%) !important',
            },
        },

        [theme.breakpoints.up(1000)]: {
            padding: '0 70px',
            '& .slick-prev, & .slick-next': {
                width: 25,
            },
        },
        [theme.breakpoints.up(600)]: {
            padding: '0 50px',
            '& .slick-prev, & .slick-next': {
                width: 25,
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 30px',
            '& .slick-prev, & .slick-next': {
                width: 25,
            },
        },
    }),
    carouselItems: {
        '& .slick-list': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    slides: {
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 15px !important',
        },
    },
    slideItem: {
        height: '100%', // full height based on parent
        '& > div.rl-box': {
            position: 'inherit',
        },
    },
    dotNavigation: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        background: theme.palette.cms?.sliderNavDotUnSelectedBackground || '#fff',
        border: `${'1px solid'} ${theme.palette.cms?.sliderNavDotSelectedBackground || 'rgb(47, 47, 47)'}`,
        marginRight: 5,
    },
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
}));

// Smoothing out scroll behavior
let firstClientX;
let clientX;

// eslint-disable-next-line consistent-return
const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
        e.preventDefault();
        e.returnValue = false;

        return false;
    }
};

const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
};

const CarouselBuilder = ({
    blockData,
    blockData: {
        wcs_style,
        products_to_show,
    },
    demandType,
    disableDots = false,
    eventTrackingData,
    isPdpSlider = false,
    nDisplayProducts,
    products,
    stylesOveride,
    templateProductPriceLayout,
    title,
    titleMargin,
    titleFontColor,
    titleFontSize,
    titleFontStyle,
    titleFontFamily,
    productClickCallback,
    salesforceResponse,
}) => {
    // Smooth scroll behavior by managing vertical v horizontal scrolling when customer interacts with recs container
    const containerRef = createRef();
    const location = useLocation();
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('touchstart', touchStart);
            containerRef.current.addEventListener('touchmove', preventTouch, {
                passive: false,
            });
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('touchstart', touchStart);
                containerRef.current.removeEventListener('touchmove', preventTouch, {
                    passive: false,
                });
            }
        };
    });

    const seed = useUIDSeed();
    const presentationFamily = useSelector(getPresentationFamily);
    const deviceType = useSelector(getSSRDeviceType);
    const brand = useSelector(getBrandName);

    const isMobile = deviceType === 'mobile';
    const isFlowerBrand = presentationFamily !== 'food';
    const isMobileScreen = useMediaQuery('(max-width: 767px)');
    const shouldScrollContainer = products.length > nDisplayProducts || (wcs_style && products.length > nDisplayProducts / 2);
    const isFlowerBrandOnPdp = isFlowerBrand && isPdpSlider;
    const isTemplateProduct = templateProductPriceLayout?.length > 0;
    const display_as_grid_mobile = blockData?.display_as_grid_mobile;
    const productPaddingBottom = blockData?.padding_bottom;
    const productLength = products.length?.length || products.length;
    const priceTitle = blockData?.starting_from || '';

    let productsToShow;
    if (disableDots && nDisplayProducts) {
        productsToShow = nDisplayProducts;
    } else {
        const maxToShow = products_to_show?.desktop || 5;
        productsToShow = products.length >= maxToShow ? maxToShow : productLength;
    }
    const productToShowOnMobile = isTemplateProduct ? 1 : products_to_show?.mobile || 2;
    /**
     * Reconcile title and accompanying css; font color, font family, font size, font style. See comment in propTypes.
     * Styling can come from Homepage block 'Trending Products' in CMS.
     */
    // Created new content type for trending gifts below values are now consuming from the same, not from Homepage block 'Trending Products' in CMS.
    let displayTitle = blockData.title;
    let displayTitleFontColor = blockData.title_font_color;
    let displayTitleFontFamily = parseFontFamilyExtension(blockData.title_font_family);
    let displayTitleFontSize = blockData.title_font_size;
    let displayTitleFontStyle = blockData.title_font_style;

    if (displayTitle === '') displayTitle = title;
    if (displayTitleFontColor === '') displayTitleFontColor = titleFontColor;
    if (displayTitleFontFamily === '' || displayTitleFontFamily === null) displayTitleFontFamily = titleFontFamily;
    if (displayTitleFontSize === '') displayTitleFontSize = titleFontSize;
    if (displayTitleFontStyle === '') displayTitleFontStyle = titleFontStyle;

    const styles = {
        displayTitleMargin: blockData.title_margin || titleMargin,
        displayTitleFontColor,
        displayTitleFontFamily,
        displayTitleFontSize,
        displayTitleFontStyle,
        displayContainerBottomMargin: stylesOveride.containerBottomMargin,
    };
    styles.totalNumberOfProducts = products.length;
    const classes = useStyles(styles);

    /**
     * Reconcile carousel styling for wcs_style flag
     *
     * wcs_style flag makes the iamges significantly larger meanings many adjustments need
     * to be made depending on how many images are in the carousel
     */
    let productUIContainerClass = classes.productContainer;
    // the images arent that big and there arent too many of them so center the images
    // in the carousel
    if (!shouldScrollContainer && !wcs_style && products?.length < 3) {
        productUIContainerClass = `${productUIContainerClass} ${productUIContainerClass}--center`;
    }

    // if the images are large and we arent going to scroll, space them evenly for wcs
    if (!shouldScrollContainer && wcs_style) {
        productUIContainerClass = `${productUIContainerClass} ${productUIContainerClass}--space-evenly`;
    }

    // if we are going to scroll, its going to flex start
    if (shouldScrollContainer) {
        productUIContainerClass = classes.productContainerWithScroll;
    }

    // overriding the style for food
    const carouselStyle = {};
    if (!isFlowerBrand) {
        carouselStyle.overflowX = 'hidden'; // removing scroll bar for slick slider
    }

    if (products && productsToShow > 0) {
        return (
            <div>
                <div
                    aria-hidden="true"
                >
                    <ReactMarkdown
                        source={displayTitle}
                        skipHtml
                        className={classes.title}
                    />
                </div>
                {/*
                        Rendered By
                        - TrendingProducts (homepage)
                    */}
                {isFlowerBrand && !isPdpSlider && !disableDots ? (
                    <div style={carouselStyle} className={`${productUIContainerClass} ${(display_as_grid_mobile && isMobile) && classes.gridContainer}`} data-testid="trending-products">
                        {products?.map((product, productPosition) => (
                            <ProductUI
                                productLength={products.length}
                                isMobile={isMobile}
                                isFlowerBrand={isFlowerBrand}
                                productData={product}
                                brand={brand}
                                key={product?.id || product?.partNumber}
                                wcsStyle={wcs_style}
                                demandType={demandType}
                                eventTrackingData={eventTrackingData}
                                productPosition={productPosition}
                                // 4810; ensure in cms this is on for desktop entries as well for PriceRange styling, price display style should be updated in separate field not related to mobile grid
                                trendingProductsGrid={display_as_grid_mobile}
                                productPaddingBottom={productPaddingBottom}
                                priceTitle={priceTitle}
                                productClickCallback={productClickCallback}
                                salesforceResponse={salesforceResponse}
                            />
                        ))}
                    </div>
                ) : (
                    /*
                                Rendered By
                                    - ProductRecommendationsBuilder (pdp)
                            */
                    <div ref={containerRef} style={carouselStyle} className={`${productUIContainerClass} ${(display_as_grid_mobile && isMobile) && classes.gridContainer}`}>
                        <Slider
                            dots={disableDots ? false : isFlowerBrandOnPdp}
                            speed={800}
                            slidesToShow={productsToShow}
                            slidesToScroll={1}
                            infinite={products.length >= productsToShow}
                            nextArrow={<ArrowForwardIosIcon />}
                            prevArrow={<ArrowBackIosIcon />}
                            className={`
                                ${classes.main}
                                ${products.length <= 3 && !isMobileScreen && classes.container}
                                ${(products.length > 3 || isMobileScreen) && classes.sliderContainer}
                                ${(products.length > 3 || isMobileScreen) && productToShowOnMobile !== 1 && classes.fixWidth}
                                ${isMobileScreen && products.length < 3 && classes.carouselItems}
                            `}
                            customPaging={() => <div className={classes.dotNavigation} />}
                            responsive={[
                                {
                                    breakpoint: 769,
                                    settings: {
                                        slidesToShow: products_to_show?.tablet || 3,
                                        slidesToScroll: 1,
                                        infinite: products.length >= 3,
                                    },
                                },
                                {
                                    breakpoint: 601,
                                    settings: {
                                        slidesToShow: productToShowOnMobile,
                                        slidesToScroll: productToShowOnMobile,
                                        infinite: products.length >= productToShowOnMobile,
                                    },
                                },
                            ]}
                        >
                            {products?.map((product) => {
                                const {
                                    name, image, seo: { url }, skuPriceRange, availability, partNumber, brandId,
                                } = product;
                                let link = url;
                                if (demandType && url) {
                                    link = link.indexOf('?') >= 0 ? `${link}&demandType=${demandType}` : `${link}?demandType=${demandType}`;
                                }
                                return (
                                    <div className={classes.slideItem} key={seed(link || name || partNumber)}>
                                        <ConnectedDesktopSimpleProduct
                                            name={name}
                                            url={link}
                                            image={image}
                                            skuPriceRange={skuPriceRange}
                                            availability={availability}
                                            partNumber={partNumber}
                                            abacusStyle={!(isTemplateProduct)}
                                            priceRangeLayout={isTemplateProduct ? templateProductPriceLayout : null}
                                            eventTrackingData={eventTrackingData}
                                            categoryName={eventTrackingData?.categoryName}
                                            categoryId={eventTrackingData?.categoryPath}
                                            categoryLegacyId={eventTrackingData?.categoryPath}
                                            showSimpleProductRedesignAbTest={false}
                                            productBrand={brandId}
                                            location={location}
                                            fromCategoryPage={eventTrackingData?.fromCategoryPage} // this is required to send tracking
                                        />
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                )}
            </div>
        );
    }

    return null;
};

CarouselBuilder.propTypes = {
    products: arrayOf(shape({
        id: string,
        partNumber: string,
        image: shape({
            path: string.isRequired,
            name: string.isRequired,
        }).isRequired,
        name: string.isRequired,
        skuPriceRange: shape({
            retail: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
            sale: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
        }).isRequired,
        seo: shape({
            url: string.isRequired,
        }).isRequired,
    })).isRequired,
    nDisplayProducts: number.isRequired, // break point between scrolling/non-scrolling

    /**
     * This is variables coming from the homepage and SSR.
     * To make this component work elsewhere, it is no longer
     * marked as required but is required for anything on the homepage.
     *
     * Currently, that includes:
     * - Trending Products Carousel
     * - ProductRecommendationBuilder
     * - RecentlyViewedProductsCarouselBuilder in file child of RecentlyViewedProductsCarouselContainer
     *
     * For other components, use title and titleFontColor props.
     */
    blockData: shape({
        title: string,
        title_margin: string,
        title_font_color: string,
        title_font_size: string,
        title_font_style: string,
        // title_font_family is the value of the Font extension value from CMS, needs to be parsed
        title_font_family: string,
        display_as_grid_mobile: bool,
    }),
    stylesOveride: shape({
        containerBottomMargin: '',
    }),
    title: string,
    titleMargin: string,
    titleFontColor: string,
    titleFontSize: string,
    titleFontStyle: string,
    titleFontFamily: string,
    demandType: string,
    disableDots: bool,
    isPdpSlider: bool,
    templateProductPriceLayout: array,
    eventTrackingData: shape({
        callbackData: string,
        productName: string,
    }),
    productClickCallback: func,
    salesforceResponse: object,
};

CarouselBuilder.defaultProps = {
    blockData: {
        title: '',
        title_margin: '',
        title_font_color: '',
        title_font_size: '',
        title_font_style: '',
        title_font_family: '',
        display_as_grid_mobile: false,
    },
    // 4810; title can be empty, removed default 'Other Products'
    stylesOveride: {
        containerBottomMargin: '',
    },
    title: '',
    titleMargin: '',
    titleFontColor: '#000',
    titleFontSize: '26px',
    titleFontStyle: 'normal',
    titleFontFamily: 'LatoMedium, sans-serif',
    demandType: '',
    disableDots: false,
    isPdpSlider: false,
    templateProductPriceLayout: [],
    eventTrackingData: {
        callbackData: '',
        productName: '',
    },
    productClickCallback: noop,
    salesforceResponse: {},
};

export default CarouselBuilder;
