/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useRef, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import {
    func, object, number, bool, string,
} from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import getSkuPriceRange from '../../../../helpers/getSkuPriceRange';
import { getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { country } from '../../../../../state/ducks/App/App-Selectors';

import ProductQuickview from '../../GraphqlCategoryPage/Partials/ProductQuickview/ProductQuickview';

const useStyle = makeStyles((theme) => ({
    subCollectionContainer: {
        width: '100%',
        position: 'relative',
    },
    subCollectionWrapper: {
        margin: '0 auto 3%',
        padding: '2% 2%',
        width: '98%',
        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
        [theme.breakpoints.down(1024)]: {
            margin: '10px auto 3%',
        },
    },
    subCollectionHeader: {
        margin: '0 auto',
        display: 'flex',
    },
    subCollectionTitle: {
        margin: '0 auto 0 0',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    subCollectionCloseBtn: {
        margin: '0 0 0 auto',
        textAlign: 'center',
        color: '#505050',
        fontSize: '22px',
        cursor: 'pointer',
    },
    subCollectionLinkWrapper: {
        margin: '10px auto 0',
        textAlign: 'center',
    },
    subCollectionLink: {
        margin: '0 auto',
        padding: '12px 45px',
        backgroundColor: '#52327b',
        fontFamily: 'Roboto Slab, sans-serif',
        textTransform: 'uppercase',
        textDecoration: 'none',
        textAlign: 'center',
        fontSize: '16px',
        letterSpacing: '3px',
        color: '#FFF',
        display: 'inline-block',
        [theme.breakpoints.down(1024)]: {
            fontSize: '16px',
            letterSpacing: '1px',
            borderRadius: '5px',
        },
    },
    triRight: {
        width: '0',
        height: '0',
        borderRight: '25px solid transparent',
        borderBottom: '25px solid #fff',
        fontSize: '0',
        position: 'absolute',
        top: '-2%',
        left: '74.5%',
        boxShadow: '-3px 3px 3px 0 rgba(0, 0, 0, 0.2)',
        transform: 'rotate(135deg)',
        [theme.breakpoints.down(1024)]: {
            top: '0%',
        },
    },
    triLeft: {
        width: '0',
        height: '0',
        borderRight: '25px solid transparent',
        borderBottom: '25px solid #fff',
        fontSize: '0',
        position: 'absolute',
        top: '-2%',
        left: '24.5%',
        boxShadow: '-3px 3px 3px 0 rgba(0, 0, 0, 0.2)',
        transform: 'rotate(135deg)',
        [theme.breakpoints.down(1024)]: {
            top: '0%',
        },
    },
    subCollectionSliderWrapper: {
        display: 'block',
        width: '100%',
    },
    sliderContainer: {
        position: 'relative',
        maxWidth: '1380px',
        width: '100%',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '15px',
            height: '100%',
        },
        '& .slick-track': {
            height: '100%',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: '0 8px',
        },
        '& .slick-prev, & .slick-next': {
            cursor: 'pointer',
            outline: 0,
            color: '#593a80',
            fontSize: 0,
            lineHeight: 0,
            zIndex: 2,
            top: '42%',
            width: '40px',
            height: '40px',
            position: 'absolute',
            border: 'none',
        },
        '& .slick-prev': {
            left: '-1%',
        },
        '& .slick-next': {
            right: '-2.5%',
        },
    },
    pdpWrapper: {
        display: 'block',
        position: 'relative',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
        '& button': {
            width: 'auto',
            [theme.breakpoints.up('lg')]: {
                left: '22%',
            },
        },
    },
    pdpTitle: {
        margin: '0 auto',
        display: 'block',
        fontWeight: 'bold',
        fontFamily: '"Hind", sans-serif',
        cursor: 'pointer',
        color: '#000',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    pdpImageWrapper: {
        position: 'relative',
        width: '100%',
    },
    pdpImageBanner: {
        position: 'absolute',
        top: 0,
        left: '20px',
        [theme.breakpoints.down(1024)]: {
            left: '10px',
            width: '25%',
        },
    },
    pdpImage: {
        width: '100%',
        border: '1px solid #CCC',
    },
    pdpPrice: {
        fontFamily: '"Hind", sans-serif',
        letterSpacing: '.5px',
        lineHeight: 1,
        [theme.breakpoints.down(1024)]: {
            fontSize: '0.65em',
        },
    },
}));

const SEMTemplateDropdown = ({
    tileHandler,
    trackEvent,
    twoSEMsubcollections,
    tileIndex,
    amount,
    isMobile,
    featureFlags,
    countryName,
}) => {
    const classes = useStyle();
    const [shouldSlide, setShouldSlide] = useState(true);
    const [cords, setCords] = useState({ x: null, y: null });

    const sliderRef = useRef();
    useEffect(() => {
        sliderRef.current.slickGoTo(0);
    }, [tileHandler]);

    /**
     * @description onClick handler for 'View All Products' btn
     * @param {str} name of collection
     * @return {} sends ga event
     */
    const viewMoreClickHandler = (collectionName) => {
        trackEvent({
            eventCategory: 'Jump Page Test',
            eventAction: `${collectionName} view more`,
            eventLabel: 'View All Products click - event label',
        });
    };

    let containerDisplay = { display: 'none' };
    let titleDisplay = '';
    let tileLink = '/';
    let products = [];
    let triLeftDisplay = false;

    if (tileIndex === twoSEMsubcollections?.subcollection1?.tileData.idx) {
        containerDisplay = { display: 'block' };
        titleDisplay = twoSEMsubcollections.subcollection1.tileData.title;
        tileLink = twoSEMsubcollections.subcollection1.tileData.link;
        products = twoSEMsubcollections.subcollection1.pdps;
        triLeftDisplay = true;
    } else if (tileIndex === twoSEMsubcollections?.subcollection2?.tileData.idx) {
        containerDisplay = { display: 'block' };
        titleDisplay = twoSEMsubcollections.subcollection2.tileData.title;
        tileLink = twoSEMsubcollections.subcollection2.tileData.link;
        products = twoSEMsubcollections.subcollection2.pdps;
    }

    /**
     * @descriptions Consolodate event handling to a single function for simplicity
     * @param {object} e dom event
     * @param {string} id product.id
     */
    const reactSlickEventHandler = (e, id) => {
        // If the quickview dialog is showing, cancel this function call.
        if (!shouldSlide) return;

        if (e.type === 'mousedown') {
            /**
             * Track the cords on a mouse down so in the case of an e.type === click event,
             * one can compare them to indicate if the interaction was a drag or a click.
             */
            setCords({ x: e.clientX, y: e.clientY });
            e.preventDefault();
            return;
        }

        if (e.type === 'mouseup') {
            // Always prevent default, no other logic needed.
            e.preventDefault();
            return;
        }

        if (e.type === 'click') {
            /**
             * If the click event does not have the same cords as the mouse down event,
             * acknowledge it as a "drag" interaction and prevent the link redirection.
             */
            const isClickEvent = e.clientX === cords.x && e.clientY === cords.y;

            if (!isClickEvent) {
                e.preventDefault();
                return;
            }

            trackEvent({
                eventCategory: 'Jump Page Test',
                eventAction: `${titleDisplay} ${id} click`,
                eventLabel: 'Collection product click - event label',
            });
        }
    };

    const handleQuickviewOnSetShow = (isShowing) => {
        /**
         * The quickview dialog is nested within the slider component which causes
         * erratic behaviour with highligting or dragging the mouse.
         *
         * Prevent this by conditionally allowing interactions
         * with the Slider component if the Quickview dialog is showing.
         */
        if (isShowing) {
            setShouldSlide(false);
            return;
        }
        setShouldSlide(true);
    };

    const greenBannerHandler = (idx) => {
        let bannerImage = 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2020/subcollection/1737-one-f.png';

        if (idx === 1) {
            bannerImage = 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2020/subcollection/1737-two-f.png';
        } else if (idx === 2) {
            bannerImage = 'https://cdn4.1800flowers.com/wcsstore/Flowers/images/2020/subcollection/1737-three-f.png';
        }

        return bannerImage;
    };

    if (products) {
        return (
            <div className={classes.subCollectionContainer} data-testid="SEMTemplateDropdown-test" style={containerDisplay}>
                {triLeftDisplay ? <p className={classes.triLeft} /> : <p className={classes.triRight} />}
                <div className={classes.subCollectionWrapper}>
                    <div className={classes.subCollectionHeader}>
                        <p className={classes.subCollectionTitle}>{titleDisplay}</p>
                        <p className={classes.subCollectionCloseBtn} role="presentation" onKeyDown={() => { tileHandler(-1); }} onClick={() => { tileHandler(-1); }}>X</p>
                    </div>

                    <div className={classes.subCollectionSliderWrapper}>
                        <Slider
                            dots={false}
                            infinite
                            speed={500}
                            swipe={shouldSlide}
                            draggable={shouldSlide}
                            slidesToShow={amount}
                            swipeToSlide
                            slidesToScroll={1}
                            initialSlide={0}
                            nextArrow={<ArrowForwardIosIcon />}
                            prevArrow={<ArrowBackIosIcon />}
                            className={`carousel-container ${classes.sliderContainer}`}
                            ref={sliderRef}
                        >
                            {products.map((product, idx) => {
                                const {
                                    brand,
                                    image: { name, path },
                                    name: productName,
                                    id,
                                    skuPriceRange,
                                    seo: { url },
                                    partNumber,
                                    isPersonalizable,
                                    brandId,
                                } = product;
                                const productImage = [path, name, 'x.jpg?width=234&amp;height=256&amp'].join('');
                                const skuPrice = getSkuPriceRange(skuPriceRange);
                                let displayPrice = skuPrice.retailPrice ? skuPrice.retailPrice : skuPrice.salePrice;

                                if (isMobile) {
                                    displayPrice = <>from <b>{displayPrice.split(' - ')[0]}</b></>;
                                }
                                const quickviewEnabled = isMobile === false && featureFlags['which-category-quickview-modal-enabled'][brandId] && !isPersonalizable && countryName === 'United States';

                                return (
                                    <div key={`${productName} - ${id}`}>
                                        <Link
                                            to={url}
                                            className={classes.pdpWrapper}
                                            onMouseDown={reactSlickEventHandler}
                                            onMouseUp={reactSlickEventHandler}
                                            onClick={(e) => reactSlickEventHandler(e, id)}
                                        >
                                            {quickviewEnabled && (
                                                <ProductQuickview
                                                    brand={{ domain: brand }}
                                                    partNumber={partNumber}
                                                    linkPackage={{
                                                        id: `sem-template-quickview-${productName}${'-'}${partNumber}`.split(' ').join('-').toLowerCase(),
                                                        sendLinkTo: { pathname: url },
                                                        handleLinkOnClick: () => {},
                                                    }}
                                                    onSetShow={handleQuickviewOnSetShow}
                                                />
                                            )}
                                            {idx < 3 && (
                                                <>
                                                    <img
                                                        alt={productName}
                                                        src={greenBannerHandler(idx)}
                                                        className={classes.pdpImageBanner}
                                                    />
                                                    <img
                                                        alt={productName}
                                                        src={ReactHTMLParser(productImage)}
                                                        className={classes.pdpImage}
                                                    />
                                                </>
                                            )}
                                            {idx >= 3 && (
                                                <img
                                                    alt={productName}
                                                    src={ReactHTMLParser(productImage)}
                                                    className={classes.pdpImage}
                                                />
                                            )}
                                        </Link>
                                        <p className={classes.pdpTitle}>
                                            {ReactHTMLParser(productName)}
                                        </p>
                                        <div className={classes.pdpPrice}>
                                            {displayPrice}
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    <div className={classes.subCollectionLinkWrapper}>
                        <Link
                            onClick={() => { viewMoreClickHandler(titleDisplay); }}
                            to={tileLink}
                            className={classes.subCollectionLink}
                        >
                            View All Products
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

SEMTemplateDropdown.propTypes = {
    featureFlags: object.isRequired,
    tileHandler: func,
    trackEvent: func,
    twoSEMsubcollections: object.isRequired,
    tileIndex: number.isRequired,
    amount: number.isRequired,
    isMobile: bool,
    countryName: string.isRequired,
};

SEMTemplateDropdown.defaultProps = {
    tileHandler: () => {},
    trackEvent: () => {},
    isMobile: false,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    countryName: country(state),
});

const enhance = compose(
    connect(mapStateToProps),
);

export default enhance(SEMTemplateDropdown);
