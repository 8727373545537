/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { object, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    title: {
        position: 'absolute',
        textAlign: 'center',
        fontSize: '16px',
        color: theme.palette.copyright,
        width: '100%',
        bottom: '7px',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
    },
    shopBtn: {
        display: 'block',
        padding: '0px 8px',
        border: '2px solid',
        width: '100px',
        margin: '0 auto 10px auto',
        fontSize: '14px',
        textTransform: 'uppercase',
        borderRadius: '4px',
    },
    bannerContainer: {
        margin: '0px 3px',
        border: `1px solid ${theme.palette.colorNeutral30}`,
        boxShadow: `0 5px 4px -2px  ${theme.palette.colorNeutral30}`,
        borderRadius: '3px',
        display: 'block',
        '& img': {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            marginBottom: '16px',
        },
    },
    bannerContainerMain: {
        width: '50%',
        height: '100%',
        position: 'relative',
        marginBottom: 16,
    },
});
const MobileBanners = ({
    classes,
    title,
    link,
    imgUrl,
    imgTitle,
}) => (
    <div className={classes.bannerContainerMain}>
        <Link to={link} className={classes.bannerContainer}>
            <img src={imgUrl} alt={imgTitle} />
            <div className={classes.title}>
                {title}
            </div>
        </Link>
    </div>
);

MobileBanners.propTypes = {
    classes: object.isRequired,
    title: string.isRequired,
    link: string.isRequired,
    imgUrl: string.isRequired,
    imgTitle: string.isRequired,
};
export default withStyles(styles)(MobileBanners);
