/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, shape, string, bool,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const styles = ()  => ({
    bannerBlock: {
        width: '100%',
        margin: '10px 0',
        '& img': {
            width: '100%',
        },
        '& a': {
            width: '100%',
            display: 'block',
        },
    },

});

const Banner = ({ classes, data, isMobile }) => {
    const bannerBlock = data?.banner || {};
    const imageUrl = isMobile ?  bannerBlock?.mobile_image?.url :  bannerBlock?.image?.url;
    return (
        <div className={classes.bannerBlock}>
            <Grid container>
                <LinkOrATag href={bannerBlock?.link?.href}> <img src={imageUrl} alt={bannerBlock?.image?.description} /> </LinkOrATag>
            </Grid>
        </div>
    );
};
Banner.propTypes = {
    classes: object.isRequired,
    isMobile: bool.isRequired,
    data: shape({
        banner: shape({
            image: shape({
                url: string,
            }),
            mobile_image: shape({
                url: string,
            }),
            link: shape({
                title: string,
                href: string,
            }),
            text: string,
        }),
    }).isRequired,
};

export default withStyles(styles)(Banner);
