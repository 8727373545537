/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    arrayOf, shape, string, bool, object,
} from 'prop-types';
import { withStyles } from '@material-ui/core';
import SubnavImageTopbanner from './Partials/SubnavImageTopBanner/SubnavImageTopBanner';
import SubnavImageSubCollectionBanner from './Partials/SubnavImageSubCollectionBanner/SubnavImageSubCollectionBanner';
import SubnavImageCollection from './Partials/SubnavImageCollection/SubnavImageCollection';
import GraphqlSeoHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import GraphqlDisclaimer from '../../../GraphqlCategoryPage/Partials/GraphqlDisclaimer/GraphqlDisclaimer';
import FaqBlock from '../../../GraphqlCategoryPage/Partials/FaqBlock/FaqBlock';
import RailMenuTabletContainer from '../../../GraphqlCategoryPage/Partials/RailMenuTabletContainer/RailMenuTabletContainer';
import data from './Partials/SubnavImageTopBanner/SubNavImageTopBannerData';

const styles = () => ({
    subTemplateSubImageBanner: {
        maxWidth: '1400px',
        margin: '0 auto',
    },
});

const TemplateCollectionSubnavImageBanner = ({
    templateData, isMobile, productsData, classes,
}) => {
    const {
        templateCollectionData,
    } = data;
    const page_blocks = templateData?.content?.entries[0]?.page_blocks || null;
    const seo = templateData?.content?.entries[0]?.seo || null;

    const getBlock = (block) => {
        const blockKey = Object.keys(block)[0];
        const blockObj = block[blockKey];
        switch (blockKey) {
            case 'top_banner':
                return <SubnavImageTopbanner topBanner={blockObj} mobileBannerData={templateCollectionData} />;
            case 'sub_collection_banners':
                return <SubnavImageSubCollectionBanner subCollectionBanners={blockObj} />;
            default:
                return null;
        }
    };
    const getFaqAndDisclaimer = (block) => {
        const blockKey = Object.keys(block)?.[0];
        const blockObj = block[blockKey];
        switch (blockKey) {
            case 'disclaimer':
                return <GraphqlDisclaimer disclaimerBlock={blockObj} />;
            case 'faq':
                return <FaqBlock faqBlock={{ faq: blockObj }} />;
            case 'customer_reviews_gallery':
                return (
                    <div className="yotpo yotpo-pictures-widget" data-gallery-id={blockObj?.gallery_id} />
                );
            default:
                return null;
        }
    };
    return (
        <div className={classes.subTemplateSubImageBanner}>
            <GraphqlSeoHeadTags seoData={seo} />
            {
                page_blocks ? page_blocks.map((block) => getBlock(block)) : null
            }
            {
                templateData && <SubnavImageCollection templateData={templateData} productsData={productsData} />
            }
            {
                page_blocks && !isMobile ? page_blocks.map((block) => getFaqAndDisclaimer(block)) : null
            }
            <RailMenuTabletContainer
                railMenuData={templateData?.content?.entries?.[0]}
                isMobile={isMobile}
            />
        </div>
    );
};

TemplateCollectionSubnavImageBanner.propTypes = {
    classes: object.isRequired,
    templateData: shape({
        content: shape({
            entries: arrayOf(
                shape({
                    seo: shape({
                        keywords: string.isRequired,
                        meta_description: string.isRequired,
                        page_title: string.isRequired,
                        seo_searchable: bool.isRequired,
                    }),
                }),
            ),
        }),
    }),
    isMobile: bool,
    productsData: arrayOf(
        shape({
            brand: string.isRequired,
            name: string.isRequired,
            partNumber: string.isRequired,
            id: string.isRequired,
        }),
    ),
};
TemplateCollectionSubnavImageBanner.defaultProps = {
    templateData: {},
    isMobile: '',
    productsData: [],
};

export default (withStyles(styles)(TemplateCollectionSubnavImageBanner));
