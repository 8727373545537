/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, shape, string, arrayOf, func,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CategoryTileBanner from './CategoryTileBanner';
import CategoryProductTiles from './CategoryProductTiles';

const styles = (theme)  => ({
    tileBlock: {
        alignItems: 'center',
        '&:nth-child(even)': {
            '& $productBlock': {
                '&:nth-child(odd)': {
                    [theme.breakpoints.up('sm')]: {
                        margin: 0,
                    },
                },
            },
        },
    },
    productBlock: {
        width: '80%',
        textAlign: 'center',
        '& img': {
            width: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '10px 0 0 auto',
            '& img': {
                width: '100%',
            },
        },
    },
    productRow: {
        justifyContent: 'center',
        '& a': {
            color: theme.palette.cms?.black || '#000',
        },
    },
    shopButton: {
        width: 200,
        margin: '5px auto 10px',
        textAlign: 'center',
        '& a': {
            display: 'block',
            padding: 5,
            borderRadius: 4,
        },
    },
    tileGrid: {
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    productName: {
        padding: '0px 25px',
        overflow: 'hidden',
        lineHeight: 1.3,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
    },
    productTileMain: {
        justifyContent: 'center',
    },
});
const TileBlock = (classes, banner, products, key, brand, fontFamily) => {
    const isEven  = key % 2 === 0;
    return (
        <Grid container className={isEven ? `${classes.tileGrid} ${classes.tileBlock}` :  classes.tileBlock} key={key}>
            <Grid item xs={12} md={6} lg={6}>
                <CategoryTileBanner bannerData={banner} isEven={isEven} fontFamily={fontFamily} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <CategoryProductTiles classes={classes} productData={products} brand={brand} />
            </Grid>
        </Grid>
    );
};
const CategoryTile = ({
    classes, data, brand, fontFamily,
}) => {
    const categoryTileBlock = data?.category_tile_with_product_images?.row;
    return (
        <>
            {categoryTileBlock.map((block, key) => TileBlock(classes, block?.banner, block?.products, key, brand, fontFamily))}
        </>

    );
};
CategoryTile.propTypes = {
    classes: object.isRequired,
    data: shape({
        category_tile_with_product_images: shape({
            row: arrayOf(shape({
                banner: shape({
                    background_image: shape({
                        url: string,
                    }),
                    heading: string,
                    sub_heading: string,
                }),
                products: shape({
                    link: shape({
                        href: string,
                    }),
                    image: arrayOf(shape({
                        url: string,
                    })),
                }),
            })),
        }),
    }).isRequired,
    brand: shape({
        domain: string,
    }).isRequired,
    fontFamily: func.isRequired,
};

export default withStyles(styles)(CategoryTile);
