/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, arrayOf, string, bool,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Helmet from 'react-helmet';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';
import TemplateFareWell from './TemplateFareWell';
import MobileFareWell from './Partials/MobileFareWell';
import GraphqlSeoHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import { getBrandName } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const TemplateFareWellPage = ({ templateData, presentationFamily }) => {
    const seoDataFareWellPage = templateData?.content?.entries?.[0]?.seo;
    const brandName = useSelector(getBrandName);
    const { pathname } = useLocation();
    const sympathyPage = pathname === '/Sympathy' || pathname === '/sympathy-multi-subcollection';
    let imageOg = '';
    if (sympathyPage) {
        imageOg = '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/95097lx.jpg?height=354&width=324';
    } else if (pathname === '/sanctuary') {
        imageOg = '//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/157623mgx.jpg?height=354&width=324';
    }
    let SEO = {};
    if (typeof seoDataFareWellPage !== 'undefined') {
        SEO = JSON.parse(JSON.stringify(seoDataFareWellPage));
    }
    SEO.og = [];
    SEO.og.push(
        { property: 'og:title', content: seoDataFareWellPage?.page_title || '' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: imageOg },
        { property: 'og:description', content: seoDataFareWellPage?.meta_description || '' },
        { property: 'og:url', content: `https://www.${brandName}.com${pathname || ''}` },
    );
    const collectionSeoSchema = () => (
        <Helmet>
            <script data-react-helmet="true" type="application/ld+json">
                {'{"@context":"http://schema.org","@type":"CollectionPage","name":"Sympathy Flowers","description":"Trust 1800Flowers sympathy flowers & arrangements to express your condolences. Order sympathy flowers for delivery online for the service or to the home.","primaryImageOfPage":{"@type":"ImageObject","url":"//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/95097lx.jpg"},"image":{"@type":"ImageObject","url":"//cdn1.1800flowers.com/wcsstore/Flowers/images/catalog/95097lx.jpg"}}'}
            </script>
        </Helmet>
    );
    return (
        <>
            {sympathyPage && collectionSeoSchema()}
            {seoDataFareWellPage ? <GraphqlSeoHeadTags seoData={SEO} /> : null}
            <DesktopMobile
                breakpoint={767}
                desktop={() => <TemplateFareWell templateData={templateData} presentationFamily={presentationFamily} />}
                mobile={() => <MobileFareWell templateData={templateData} presentationFamily={presentationFamily} />}
            />
        </>
    );
};

TemplateFareWellPage.propTypes = {
    templateData: shape({
        seo: shape({
            breadcrumb: arrayOf(
                shape({
                    title: string.isRequired,
                    href: string.isRequired,
                }).isRequired,
            ),
            keywords: string,
            meta_description: string,
            seo_searchable: bool.isRequired,
            page_title: string,
        }),
        category_blocks: arrayOf(
            shape({
                main_banner: shape({
                    link: shape({
                        href: string.isRequired,
                        title: string.isRequired,
                    }),
                    banner_content: shape({
                        cta_button_background_color: string,
                        cta_button_text_color: string,
                        headline: string,
                        headline_font_color: string,
                        sub_headline: string,
                        sub_headline_font_color: string,
                    }),
                    desktop_image: shape({
                        url: string.isRequired,
                    }),
                }),
                you_choose_block: shape({
                    background_color: string,
                    copy: string,
                    header: string,
                    text_color: string,
                }),
                info_block: shape({
                    tile: arrayOf({
                        header: string,
                        sub_header: string,
                        tile_background_color: string,
                        text_color: string,
                    }),
                }),
                sub_banner: shape({
                    desktop_image: shape({
                        url: string.isRequired,
                        title: string.isRequired,
                    }),
                }),
                bottom_banners: shape({
                    title: string.isRequired,
                    banners: arrayOf({
                        link: shape({
                            href: string.isRequired,
                        }),
                        desktop_image: shape({
                            url: string.isRequired,
                            title: string.isRequired,
                        }),
                    }),
                }),
            }),
        ),
    }),
    presentationFamily: string.isRequired,
};
TemplateFareWellPage.defaultProps = {
    templateData: {},
};

export default TemplateFareWellPage;
