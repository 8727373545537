/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import {
    object, shape, arrayOf, string, bool,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import RailCategoryRelatedBox from './RailCategoryRelatedBox';

const styles = () => ({
    railMenuComponent: {
        display: 'block',
        width: '100%',
        padding: '0 15px',
        margin: '10px 0',
        overflow: 'hidden',
    },
});

const RailMenuTabletContainer = ({ classes, railMenuData, isMobile }) => {
    const seed = useUIDSeed();
    let pageTitle = railMenuData?.name || railMenuData?.title || '';
    let railData = [];
    if (railMenuData?.category_blocks?.length) {
        railData = railMenuData.category_blocks;
    }
    if (railMenuData?.page_blocks?.length) {
        railData = railMenuData.page_blocks;
    }
    return (
        isMobile
            ? (
                <Grid container direction="column">
                    {railData?.length ? railData.map((block) => {
                        const blockKey = Object.keys(block)[0];
                        const blockObj = block[blockKey];
                        // we do this because we need to get the h1 page title
                        if (block.imoc) {
                            block.imoc.imoc_group.forEach((d) => {
                                if (d.name.toLowerCase() === 'web imoc') {
                                    pageTitle = d.header;
                                }
                            });
                        }
                        if (blockKey === 'related_searches' || blockKey === 'related_articles' || blockKey === 'disclaimer' ||  blockKey === 'faq') {
                            return (
                                <div
                                    className={classes.railMenuComponent}
                                    key={seed(blockObj)}
                                >
                                    <RailCategoryRelatedBox data={blockObj} blockName={blockKey} pageTitle={pageTitle} />
                                </div>
                            );
                        }
                        return null;
                    }) : null}
                </Grid>
            )
            : null
    );
};

RailMenuTabletContainer.propTypes = {
    classes: object.isRequired,
    railMenuData: shape({
        category_blocks: arrayOf(
            shape({
                related_articles: shape({
                    title: string.isRequired,
                    link: arrayOf(shape({
                        title: string.isRequired,
                        href: string.isRequired,
                    })).isRequired,
                }),
                related_searches: shape({
                    title: string.isRequired,
                    link: arrayOf(shape({
                        title: string.isRequired,
                        href: string.isRequired,
                    })).isRequired,
                }),
            }),
        ),
    }).isRequired,
    isMobile: bool.isRequired,
};

export default withStyles(styles)(RailMenuTabletContainer);
