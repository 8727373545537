/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, arrayOf, string, number, bool,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';
import { getBrand } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import TemplateJasonwuSlider from './TemplateJasonwuSlider';
import ProductsFallbackContainer from '../../../GraphqlCommonComponents/GraphqlProductsFallbackContainer/ProductsFallbackContainer';
import JasonwuConnectArticleBlock from './Partials/JasonwuConnectArticleBlock';
import LinkOrATag from '../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import GraphqlSeoHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1400,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1400,
            margin: '0 auto',
        },
    },
    listBlock: {
        width: 'calc(33% - 18px)',
        margin: '12px 0 25px',
        textAlign: 'center',
        flexBasis: 'calc(33% - 18px)',
        backgroundColor: theme.palette.bgFooter,
        '& h2': {
            fontWeight: 'normal',
            fontSize: '30px',
        },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },

    notImageBorder: {
        color: theme.palette.common.black,
        height: '100%',
        display: 'block',
        padding: '32px',
        textDecoration: 'none',
    },
    imageBorder: {
        padding: '62px',
        outline: `1px solid ${theme.palette.common.black}`,
        outlineOffset: '-32px',
        color: theme.palette.common.black,
        height: '100%',
        display: 'block',
        textDecoration: 'none',
    },
    bottomSection: {
        '& img': {
            width: '100%',
        },
    },
    imagePath: {
        '& img': {
            width: '100%',
            objectFit: 'cover',
        },
    },

    contentBlock: {
        width: '85%',
        margin: '5% auto',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },

    headerBlock: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',

    },

    shopButton: {
        textTransform: 'uppercase',
        marginTop: '16px',
        fontSize: '14px',
    },
    subBanner: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    left: {
        paddingLeft: 40,
        [theme.breakpoints.down(450)]: {
            paddingLeft: 10,
            maxWidth: '100%',
            flexBasis: 'inherit',
        },
    },
    copy: {
        fontSize: 28,
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontWeight: 500,
        borderBottom: 'none',
        '& p': {
            margin: '10px 0px',
        },
    },
    subCopy: {
        fontSize: 17,
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        '& p': {
            margin: '10px 0px',
        },
    },
    right: {
        marginTop: 10,
        textAlign: 'center',
    },
    variantListBlock: {
        textAlign: 'center',
        padding: '40px 10px',
        '& a': {
            outline: 'none',
        },
        '& h1': {
            fontSize: 44,
        },
        '& h2': {
            fontSize: 18,
        },
    },
    variantLink: {
        display: 'block',
        textDecoration: 'none',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        '& h1, h2': {
            margin: 0,
        },
        '& p': {
            margin: '5px 0px 20px 0px',
        },
    },
    variantShopButton: {
        '& b': {
            background: 'black',
            color: 'white',
            padding: '7px 20px',
        },
    },
    copyBlock: {
        margin: '0px 0px 0px 25px',
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        },
    },
    bottomBorder: {
        borderBottom: '1px solid',
    },
});

const sectionContent = (classes, list) => (
    <>
        <h2 className={classes.contentHeading}> {ReactHtmlParser(list?.header)} </h2>
        {list?.image?.url && <div className={classes.imagePath}><img src={list.image.url} alt={list?.header} /></div>}
        <ReactMarkdown source={list?.sub_copy} escapeHtml={false} />
        <div className={list?.presentation_style === 'variant_a' ? classes.variantShopButton : classes.shopButton}><b>{list?.link?.title}</b></div>
    </>
);
const bannerLink = (classes, list) => (list?.link?.href?.indexOf('http://') === 0 || list?.link?.href?.indexOf('https://') === 0
    ? (
        <a className={!list?.image || list?.box_outline ? classes.imageBorder : classes.notImageBorder} href={list?.link?.href}>{sectionContent(classes, list)}</a>
    )
    : (
        <Link className={!list?.image || list?.box_outline ? classes.imageBorder : classes.notImageBorder} to={list?.link?.href}>
            {sectionContent(classes, list)}
        </Link>
    ));
const subBannerBlock = (classes, list, brand) => (list?.link?.href.includes('/blog') || list?.link?.href?.includes('/articles')
    ? <a className={!list?.image || list?.box_outline ? classes.imageBorder : classes.notImageBorder} href={`https://www.${brand?.domain}.com${list?.link?.href}`}> {sectionContent(classes, list)}</a>
    : (
        bannerLink(classes, list)
    ));

const renderLinks = (classes, list, brand) => (list?.link?.href
    ? subBannerBlock(classes, list, brand)
    : (
        <div className={!list?.image || list?.box_outline ? classes.imageBorder : classes.notImageBorder}>{sectionContent(classes, list) }</div>
    ));
const bottomBannerLink = (classes, bottomImage, isMobile) => {
    if (bottomImage?.bottom_banner?.link?.href && bottomImage.bottom_banner?.mobile_image?.url && bottomImage.bottom_banner?.desktop_image?.url) {
        return (bottomImage.bottom_banner.link.href?.includes('/blog') || bottomImage?.bottom_banner?.link?.href?.indexOf('http://') === 0 || bottomImage.bottom_banner.link.href?.indexOf('https://') === 0
            ? (
                <a className={classes.bottomSection} href={bottomImage.bottom_banner.link.href}>
                    {
                        isMobile ? <img src={bottomImage.bottom_banner?.mobile_image?.url} alt={bottomImage.bottom_banner?.mobile_image?.description || 'Bottom Mobile Image Banner'} /> : <img src={bottomImage.bottom_banner?.desktop_image?.url} alt={bottomImage.bottom_banner?.desktop_image?.description || 'Bottom Image Banner'} />
                    }
                </a>
            )
            : (
                <Link className={classes.bottomSection} to={bottomImage.bottom_banner.link.href}>
                    {
                        isMobile ? <img src={bottomImage.bottom_banner?.mobile_image?.url} alt={bottomImage.bottom_banner?.mobile_image?.description || 'Bottom Image Banner'} /> : <img src={bottomImage.bottom_banner?.desktop_image?.url} alt={bottomImage.bottom_banner?.desktop_image?.description || 'Bottom Image Banner'} />
                    }
                </Link>
            ));
    }
    return (
        <div className={classes.bottomSection}>
            {(isMobile && bottomImage?.bottom_banner?.mobile_image?.url) && <img src={bottomImage?.bottom_banner?.mobile_image?.url} alt={bottomImage?.bottom_banner?.mobile_image?.description || 'Bottom Mobile Image Banner'} /> }
            {(!isMobile && bottomImage?.bottom_banner?.desktop_image?.url) && <img src={bottomImage?.bottom_banner?.desktop_image?.url} alt={bottomImage?.bottom_banner?.desktop_image?.description || 'Bottom Image Banner'} />}
        </div>
    );
};
const renderVariantALinks = (classes, list) => (
    <LinkOrATag className={classes.variantLink} href={list?.link?.href}>
        {sectionContent(classes, list)}
    </LinkOrATag>
);
const TemplateJasonwuConnect = ({
    classes, templateData, isMobile, brand,
}) => {
    const seo = templateData?.content?.entries?.[0]?.seo;
    const categoryBlocks = templateData?.content?.entries?.[0]?.page_blocks || [];
    let backgroundImage = templateData?.content?.entries?.[0]?.background_treatment || {};

    const seed = useUIDSeed();
    if (!categoryBlocks.length) {
        return <ProductsFallbackContainer />;
    }
    if (backgroundImage?.background_image?.url) {
        backgroundImage = { backgroundImage: `url(${backgroundImage?.background_image?.url})` };
    }
    return (
        <>
            {
                seo?.seo_searchable
                    ? (
                        <GraphqlSeoHeadTags seoData={seo} />
                    ) : null
            }
            <Grid container className={classes.container}>
                <div className={classes.contentBlock}  style={backgroundImage}>
                    {categoryBlocks?.map((item) => {
                        const blockKey = Object.keys(item)[0];
                        switch (blockKey) {
                            case 'slider_block':
                            {
                                return (
                                    <Grid item xs={12}>
                                        {item?.header?.presentation_style === 'variant_a'
                                            ? (
                                                <Grid container>
                                                    <Grid className={classes.left} item xs={12} md={9} lg={9}>
                                                        <div className={classes.copy}>   <ReactMarkdown source={item?.header?.copy} escapeHtml={false} /> </div>
                                                        <div className={classes.subCopy}>  <ReactMarkdown source={item?.header?.sub_copy} escapeHtml={false} /> </div>
                                                    </Grid>
                                                    <Grid className={classes.right} item xs={12} md={3} lg={3}>
                                                        <img src={isMobile ? item?.header?.right_rail_image_mobile?.url : item?.header?.right_rail_image?.url} alt={item?.header?.copy} />
                                                    </Grid>
                                                </Grid>
                                            )
                                            :  (
                                                <div className={classes.headerBlock}>
                                                    <TemplateJasonwuSlider sliderBlock={item?.slider_block} />
                                                </div>
                                            )}

                                    </Grid>
                                );
                            }
                            case 'sub_banners':
                            {
                                return (
                                    <>
                                        <Grid item xs={12} className={classes.subBanner}>
                                            {item?.sub_banners?.sub_banner.map((list) => {
                                                const subBannerStyle = { color: list?.font_color, backgroundColor: list?.background_color };
                                                return list?.presentation_style === 'variant_a'
                                                    ? (
                                                        <Grid key={seed(list)} style={subBannerStyle}  item xs={12} md={6} lg={6} className={classes.variantListBlock}>
                                                            {renderVariantALinks(classes, list)}
                                                        </Grid>
                                                    )
                                                    : (
                                                        <div key={seed(list)} style={subBannerStyle} xs={12} md={6} lg={6} className={classes.listBlock}>
                                                            {renderLinks(classes, list, brand)}
                                                        </div>
                                                    );
                                            },
                                            )}
                                        </Grid>
                                    </>
                                );
                            }
                            case 'bottom_banner':
                            {
                                return (
                                    <>
                                        <Grid item xs={12} key={seed(item)}>
                                            {bottomBannerLink(classes, item, isMobile)}
                                        </Grid>
                                    </>
                                );
                            }
                            case 'articles':
                                return (
                                    <JasonwuConnectArticleBlock data={item?.articles} brand={brand?.domain} />
                                );
                            case 'copy':
                            {
                                const copystyle = {
                                    color: item?.copy?.text_color?.color,
                                    fontSize: item?.copy?.font_size,
                                };
                                return (
                                    <Grid conatiner className={`${classes.copyBlock } ${item?.copy?.bottom_border ? classes.bottomBorder : '' }`}>
                                        <Grid item xs={12} lg={8} md={8} key={seed(item)} style={copystyle}>
                                            <ReactMarkdown source={item?.copy?.copy} escapeHtml={false} />
                                        </Grid>
                                    </Grid>
                                );
                            }
                            default:
                                return null;
                        }
                    })}

                </div>
            </Grid>
        </>
    );
};

TemplateJasonwuConnect.propTypes = {
    classes: object.isRequired,
    templateData: shape({
        category_blocks: arrayOf(
            shape({
                sub_banners: shape({
                    number_across: number.isRequired,
                    sub_banner: arrayOf({
                        header: string.isRequired,
                        font_color: string.isRequired,
                        box_outline: string.isRequired,
                        background_color: string.isRequired,
                        sub_copy: string.isRequired,
                        image: shape({
                            url: string.isRequired,
                        }),
                        link: shape({
                            href: string.isRequired,
                            title: string.isRequired,
                        }),
                    }),
                }),
                bottom_banner: shape({
                    link: shape({
                        href: string.isRequired,
                    }),
                    desktop_image: shape({
                        url: string.isRequired,
                        title: string.isRequired,
                    }),
                }),
            }),
        ),
    }).isRequired,
    isMobile: bool.isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
};
const mapStateToProps = (state) => ({
    brand: getBrand(state),
});
export default connect(mapStateToProps, null)(withStyles(styles)(TemplateJasonwuConnect));
