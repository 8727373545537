/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, arrayOf, bool,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useUIDSeed } from 'react-uid';
import { useLocation } from 'react-router-dom';
import TileBlock from './TileBlock';
import parseFont from '../../../../GraphqlHeader/NavigationMenu_V2/helpers/parseFontFamily';
import getBackgroundColor from '../../../../../../helpers/getBackgroundColor';

const useStyles = makeStyles((theme) => ({
    tileBlock: ({ tileRow }) => ({
        maxWidth: '1440px',
        margin: 'auto',
        textAlign: 'center',
        padding: '0.625rem 0px',
        width: '100%',
        backgroundColor: tileRow?.background_color?.color || '#ffffff',
        [theme.breakpoints.down('sm')]: {
            padding: '0px',
        },
        '& a': {
            textDecoration: 'none',
        },
    }),
    heading: ({ tileRow }) => ({
        padding: '0.938rem 0px 0.313rem',
        color: getBackgroundColor(tileRow?.heading_styling?.color) || '#000000',
        fontSize: tileRow?.heading_styling?.font_size || '1.375rem',
        fontFamily: parseFont(tileRow?.heading_styling?.font_family) ||  'PlayfairDisplayBold, serif',
    }),
    withBorderHeading: {
        overflow: 'hidden',
        '&::before': {
            width: '50%',
            right: '0.5em',
            height: '1px',
            content: '""',
            display: 'inline-block',
            position: 'relative',
            marginLeft: '-50%',
            verticalAlign: 'middle',
            backgroundColor: theme.palette.cms?.grey || '#cccccc',
        },
        '&::after': {
            left: '.5em',
            width: '50%',
            height: '1px',
            content: '""',
            display: 'inline-block',
            position: 'relative',
            marginRight: '-50%',
            verticalAlign: 'middle',
            backgroundColor: theme.palette.cms?.grey || '#cccccc',
        },
    },
    icon: {
        margin: '0px 0.188rem',
        width: '1.125rem',
        verticalAlign: 'middle',
    },
    tileImageBlock: {
        justifyContent: 'center',
        alignItems: 'baseline',
    },
    subHeading: (tileRow) => ({
        fontFamily: parseFont(tileRow?.subheading_font_family) || '',
        fontSize: '1.125rem',
    }),
}));

const TileRowWithHeading = ({
    data, isBirthdayLPLayout,
}) => {
    const location = useLocation();
    const seed = useUIDSeed();
    const tileRow =  data?.tile_row_with_heading || {};
    const classes = useStyles({ tileRow });
    const headingDecoration = tileRow?.add_heading_decorarion || false;
    const rowWithHeadingTileBlock = tileRow?.group || tileRow?.tile || [];
    const titleStyle = { backgroundColor: tileRow?.tile_title_background?.color || '#ffffff' };
    const heading = tileRow?.heading || '';
    const icon =  tileRow?.icon?.url || '';
    const subHeading = tileRow?.subheading || '';

    return (
        <div className={classes.tileBlock}>
            {heading && (
                <Grid item xs={12} className={headingDecoration ? `${classes.withBorderHeading} ${classes.heading}` : classes.heading}>
                    { icon && <img className={classes.icon} src={icon} alt={heading} />}
                    {heading}
                </Grid>
            )}
            {subHeading &&  (
                <Grid item xs={12} className={classes.subHeading}>
                    {subHeading}
                </Grid>
            )}
            <Grid container className={classes.tileImageBlock}>
                {rowWithHeadingTileBlock.map((block) => (
                    <TileBlock
                        key={seed(block)}
                        block={block}
                        noOfTiles={rowWithHeadingTileBlock?.length || 0}
                        titleStyle={titleStyle}
                        headingDecoration={headingDecoration}
                        location={location}
                        isBirthdayLPLayout={isBirthdayLPLayout}
                    />
                ))}
            </Grid>
        </div>
    );
};
TileRowWithHeading.propTypes = {
    isBirthdayLPLayout: bool.isRequired,
    data: shape({
        heading: string,
        icon: shape({
            url: string.isRequired,
        }),
        tile: arrayOf(shape({
            image: shape({
                url: string,
            }),
            link: shape({
                title: string,
                href: string,
            }),
            title: string,
        })),
    }).isRequired,
};

export default TileRowWithHeading;
