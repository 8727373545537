/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - baskets or berries
 * @param {object} page - contains path and contentType
 */

const determineProductOptions = (userSubmittedZip = '', locationType = 'Residence', isBot = false, contentType) => {
    if (isBot) {
        // render all products for bots
        return `{locationType: "${locationType}"}`;
    }
    if (contentType === 'template_page_sales') {
        return `{locationType: "${locationType}", zipCode: "${userSubmittedZip}", page: 1, pageSize: 20}`;
    }
    if (userSubmittedZip !== '') {
        return `{locationType: "${locationType}", zipCode: "${userSubmittedZip}", page: 1, pageSize: 12}`;
    }
    return `{locationType: "${locationType}", page: 1, pageSize: 12}`;
};

const findCategoryPageByUrl = (targetingEnabled, brand, url, contentType = 'standard_collections', userSubmittedZip, locationType, isBot) => (
    // very important for serializing. add space between bracket and query name + add typenames to query
    gql`query CategoryDataQuery${targetingEnabled ? '($targeting: [Targeting])' : ''} { findCategoryPageByUrl(brand: "${brand}", environment: "${GRAPHQL_ENV}", locale: "en-us", url: "${url}", contentType: "${contentType}", productOptions: ${determineProductOptions(userSubmittedZip, locationType, isBot, contentType)}${targetingEnabled ? ', targeting: $targeting' : ''}) {
                content
                showUnderNavText
                brand
                subCollections {
                    id
                    name
                    products {
                      id
                      name
                      brandId
                      productSkus {
                        id
                      }  
                      image {
                        additionalImages
                        altText
                        name
                        path
                        snipe
                        snipeImagePath
                        __typename
                    }
                      prices {
                        currency
                        type
                        value
                    }
                    skuPriceRange {
                        sale {
                            value
                        }
                        retail {
                            value
                        }
                    }
                    seo {
                        url
                    }
                    }
                  }
                category {
                    id
                    legacyId
                    name
                    guidedNavFlag
                     productFacets {
                            name
                            displayOpen
                                entries{
                                    name
                                    value
                                    count
                                    from
                                    to
                                }
                        }
                    breadCrumbs {
                        id
                        name
                        __typename
                    seo {
                        keywords
                        metaDescription
                        title
                        url
                        __typename
                        }
                    __typename
                }
                products {
                    id
                    brandId
                    image {
                        additionalImages
                        altText
                        name
                        path
                        snipe
                        snipeImagePath
                        __typename
                    }
                    partNumber
                    productType
                    baseCode
                    prices {
                        currency
                        type
                        value
                        __typename
                    }
                    skuPriceRange {
                        sale {
                            value
                            __typename
                        }
                        retail {
                            value
                            __typename
                        }
                        __typename
                    }
                    name
                    brand
                    seo {
                        url
                        __typename
                    }
                    productSkus {
                        partNumber
                        id
                        __typename
                      }              
                    availability {
                        availabilityIndicator
                        deliveryDateType
                        displayEndDate
                        displayStartDate
                        earliestShipDate
                        latestShipDate
                        perishable
                        productDeliveryType
                        shipAlone
                        deliveryMessage
                        __typename
                    }
                    isPersonalizable
                    isPassportEligible
                    __typename
                }
                image
                shortDescription
                seo {
                    keywords
                    title
                    metaDescription
                    __typename
                }
                totalPages
                totalProducts
                __typename
            }
            subNavCollection {
                products {
                    id
                    brandId
                    isPassportEligible
                    image {
                        additionalImages
                        altText
                        name
                        path
                        snipe
                        snipeImagePath
                        __typename
                    }
                    productType
                    partNumber
                    prices {
                        currency
                        type
                        value
                    }
                    skuPrices
                    skuPriceRange{
                        sale {
                            value
                        }
                        retail{
                            value
                        }
                    }
                    name
                    brand
                    seo {
                        url
                    }
                    availability {
                        availabilityIndicator
                        deliveryDateType
                        displayEndDate
                        displayStartDate
                        earliestShipDate
                        latestShipDate
                        perishable
                        productDeliveryType
                        shipAlone
                        deliveryMessage
                    }
                }
            }
            guidedNavForm
            samedayCutoff
            __typename
        }
    }
`);

export const findCategoryPageByUrlNoProducts = (targetingEnabled) => (
    // very important for serializing. add space between bracket and query name + add typenames to query
    gql` 
    query CategoryDataQuery($brand: String!, $environment: String!, $url: String!, $locale: String!, $contentType: String!${targetingEnabled ? ', $targeting: [Targeting]' : ''}) {
        findCategoryPageByUrl(brand: $brand, environment: $environment, locale: $locale, url: $url, contentType: $contentType${targetingEnabled ? ', targeting: $targeting' : ''}) {
                    content
                    showUnderNavText
                    brand
                    subNavCollection {
                        products {
                            id
                            brandId
                            isPassportEligible
                            productSkus {
                                id
                              }  
                            image {
                                additionalImages
                                altText
                                name
                                path
                                snipe
                                snipeImagePath
                                __typename
                            }
                            partNumber
                            skuPriceRange{
                                sale {
                                    value
                                }
                                retail{
                                    value
                                }
                            }
                            name
                            brand
                            seo {
                                url
                            }
                            availability {
                                availabilityIndicator
                                deliveryDateType
                                displayEndDate
                                displayStartDate
                                earliestShipDate
                                latestShipDate
                                perishable
                                productDeliveryType
                                shipAlone
                                deliveryMessage
                            }
                        }
                    }
                    subCollections {
                        id
                        name
                        products {
                        id
                        brandId
                        name
                        image {
                            additionalImages
                            altText
                            name
                            path
                            snipe
                            snipeImagePath
                            __typename
                        }
                            prices {
                            currency
                            type
                            value
                        }
                        skuPriceRange {
                            sale {
                                value
                            }
                            retail {
                                value
                            }
                        }
                        seo {
                            url
                        }
                        }
                    }
                    category {
                        id
                        legacyId
                        name
                        guidedNavFlag
                        productFacets {
                                name
                                displayOpen
                                    entries{
                                        name
                                        value
                                        count
                                        from
                                        to    
                                    }
                            }
                        breadCrumbs {
                            id
                            name
                            __typename
                        seo {
                            keywords
                            metaDescription
                            title
                            url
                            __typename
                            }
                        __typename
                    }
                }
                guidedNavForm
                samedayCutoff
                __typename
            }
        }
`);

export default findCategoryPageByUrl;
