/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { shape, string, func } from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { trackEvent as trackEventActions } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import DesktopMobile from '../../../../../../helpers/DesktopMobile/DesktopMobile';
import getBackgroundColor from '../../../../../../helpers/getBackgroundColor';
import transformRenderer from '../../../../../../helpers/markdown/characterTransformer';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    bannerText: (style) => ({
        '& *': {
            marginTop: 0,
            marginBottom: 10,
            fontSize: style?.font_size || '28px',
            color: style?.headline_font_color || '#000000',
            fontFamily: `${parseFont(style.font_family) || 'PlayfairDisplay, serif'}`,
            fontWeight: 'normal',
            textAlign: 'center',
            [theme.breakpoints.down(600)]: {
                lineHeight: '34px',
            },
        },
    }),
    bannerSubText: (style) => ({
        fontSize: 14,
        display: 'flex',
        flexWrap: 'wrap',
        '& p': {
            padding: '0 5px',
            margin: '0 0 20px 0',
            flex: '0 0 50%',
            textAlign: 'center',
            '& strong': {
                display: 'block',
                letterSpacing: '1.47px',
            },
            [theme.breakpoints.down(600)]: {
                flex: '0 0 100%',
            },
        },
        '& *': {
            fontSize: style?.sub_heading_font_size || '14px',
            color: style?.headline_font_color || '#000000',
            fontFamily: parseFont(style.font_family),
        },
        '& ul': {
            padding: '0px 0px 0px 24px',
        },
        '& p:last-child': {
            flex: '0 0 100%',
        },
    }),
    ctaBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    ctaBtn: {
        display: 'inline-block',
        color: '#FFF',
        backgroundColor: '#0A512E',
        textDecoration: 'none',
        lineHeight: '43px',
        fontSize: '14px',
        textAlign: 'center',
        margin: '0 auto',
        letterSpacing: '1.47px',
        fontWeight: 'bold',
        padding: '0 30px',
        position: 'relative',
        [theme.breakpoints.down(600)]: {
            backgroundColor: 'transparent',
            color: '#000',
            lineHeight: '18px',
        },
    },
    tilesterms: {
        marginTop: '10px',
        fontSize: '14px',
        textAlign: 'center',
        color: '#000000',
        '& button': {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: '#000000',
            background: 'transparent',
            border: 'none',
            padding: 0,
            margin: 0,
        },
    },
    contextBoxPosition: (style) => ({
        display: 'flex',
        alignItems: 'center',
        margin: style?.desktop_styling?.context_box_margin || 0,  // context box margin
        height: 'inherit',
        justifyContent: style?.desktop_styling?.copy_horizontal_alignment || 'center',  // desktop horizontal alignment
        [theme.breakpoints.down(769)]: {
            margin: style?.mobile_styling?.context_box_margin || 0,  // context box margin
            justifyContent: style?.mobile_styling?.copy_horizontal_alignment || 'center',  // mobile horizontal alignment
        },
        [theme.breakpoints.down(600)]: {
            height: '100%',
            minHeight: '400px',
            maxHeight: '600px',
        },
    }),
    contentBoxContainer: (style) => ({
        display: 'inline-block',
        width: style?.copy_container_width || '650px',
        height: '100%',
        background: getBackgroundColor(style?.copy_background_color),
        [theme.breakpoints.down(600)]: {
            margin: style?.mobile_styling?.container_margin || 0,  // content margin
        },
    }),
    contentInnerBoxContainer: (style) => ({
        border: style?.show_border ? '1px solid #000' : '',
        padding: style?.desktop_styling?.container_padding || 0,  // content padding
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: style?.desktop_styling?.copy_vertical_alignment || 'center',  // veritcal alignment
        [theme.breakpoints.down(600)]: {
            justifyContent: style?.mobile_styling?.copy_vertical_alignment || 'center',  // veritcal alignment
            padding: style?.mobile_styling?.container_padding || 0,  // content padding
        },
    }),
    subscriptionBlock: {
        width: '1400px',
        height: '420px',
        margin: '0 auto 30px',
        backgroundPosition: '50% 50%',
        position: 'relative',
        [theme.breakpoints.down(768)]: {
            width: '100%',
        },
    },
    subscriptionMobileBlock: {
        width: '100%',
        minHeight: '400px',
        maxHeight: '600px',
        height: '100%',
        margin: '0 auto',
        position: 'relative',
        backgroundPosition: '50% 50%',
    },
    bannerImg: {
        width: '100%',
        minHeight: '400px',
        maxHeight: '600px',
        height: '100%',
        objectFit: 'cover',
        top: 0,
        zIndex: '-1',
        position: 'absolute',
    },
}));
const SubscriptionBlock = ({
    item, scrollToDisclaimer, trackEvent,
}) => {
    if (!item) {
        return null;
    }
    const classes = useStyles(item?.subscription_block?.banner_content);
    const subscriptionBlockData = item?.subscription_block;
    const url = subscriptionBlockData?.desktop_image?.url || '';
    const mobileUrl = subscriptionBlockData?.mobile_image?.url || '';
    const bannerName = subscriptionBlockData?.desktop_image?.description || subscriptionBlockData?.mobile_image?.description || 'Subscription Hero';
    const bannerHeader = subscriptionBlockData?.banner_content?.header || '';
    const bannerSubHeader = subscriptionBlockData?.banner_content?.sub_header || '';

    // for Banner Button
    const ctaButton = subscriptionBlockData?.banner_content?.cta_button?.title || '';
    const ctaButtonURL = subscriptionBlockData?.banner_content?.cta_button?.href || '';

    // for tracking event
    const trackingEventCategory = subscriptionBlockData?.banner_content?.tracking_event_category || 'Subscriptions';
    const trackingEventAction = subscriptionBlockData?.banner_content?.tracking_event_action || 'Banner';
    const trackingEventLabel = subscriptionBlockData?.banner_content?.tracking_event_label || ctaButton;

    const desktopSubscription = () => (
        <div className={classes.subscriptionBlock}>
            {url && <img src={url} alt={bannerName} className={classes.bannerImg} />}
            <div className={classes.contextBoxPosition}>
                <div className={classes.contentBoxContainer}>
                    <div className={classes.contentInnerBoxContainer}>
                        {
                            bannerHeader && (
                                <div>
                                    <ReactMarkdown
                                        className={classes.bannerText}
                                        source={bannerHeader}
                                        renderers={transformRenderer}
                                        escapeHtml={false}
                                    />
                                </div>
                            )
                        }
                        {
                            bannerSubHeader && (
                                <div>
                                    <ReactMarkdown
                                        className={classes.bannerSubText}
                                        source={bannerSubHeader}
                                        escapeHtml={false}
                                        renderers={transformRenderer}
                                    />
                                </div>
                            )
                        }
                        {ctaButtonURL && (
                            <div className={classes.ctaBtnContainer}>
                                <Link
                                    className={classes.ctaBtn}
                                    to={ctaButtonURL}
                                    onClick={() => trackEvent({
                                        eventCategory: trackingEventCategory,
                                        eventAction: trackingEventAction,
                                        eventLabel: trackingEventLabel,
                                    })}
                                >
                                    {ctaButton}
                                </Link>
                            </div>
                        )}
                        {subscriptionBlockData?.banner_content?.terms?.title && (
                            <div className={classes.tilesterms}>
                                {subscriptionBlockData?.banner_content?.terms?.href.includes('#')
                                    ? (
                                        <button type="button" onClick={scrollToDisclaimer}>
                                            {subscriptionBlockData?.banner_content?.terms?.title}
                                        </button>
                                    ) : (
                                        <Link to={subscriptionBlockData?.banner_content?.terms?.href}>
                                            {subscriptionBlockData?.banner_content?.terms?.title}
                                        </Link>
                                    )}
                            </div>
                        )                        }
                    </div>
                </div>
            </div>
        </div>
    );

    const mobileSubscriptionBlock = () => (
        <div className={classes.subscriptionMobileBlock}>
            {mobileUrl && <img src={mobileUrl} alt={bannerName} className={classes.bannerImg} />}
            <div className={classes.contextBoxPosition}>
                <div className={classes.contentBoxContainer}>
                    <div className={classes.contentInnerBoxContainer}>
                        <div>
                            <ReactMarkdown className={classes.bannerText} source={bannerHeader} escapeHtml={false} />
                        </div>
                        <div>
                            <ReactMarkdown className={classes.bannerSubText} source={bannerSubHeader} escapeHtml={false} />
                        </div>
                        {ctaButtonURL && (
                            <div className={classes.ctaBtnContainer}>
                                <Link
                                    className={classes.ctaBtn}
                                    to={ctaButtonURL}
                                    onClick={() => trackEvent({
                                        eventCategory: trackingEventCategory,
                                        eventAction: trackingEventAction,
                                        eventLabel: trackingEventLabel,
                                    })}
                                >
                                    {`${ctaButton} >`}
                                </Link>
                            </div>
                        )}
                        <div className={classes.tilesterms}>
                            {subscriptionBlockData?.banner_content?.terms?.href.includes('#')
                                ? (
                                    <button type="button" onClick={scrollToDisclaimer}>
                                        {subscriptionBlockData?.banner_content?.terms?.title}
                                    </button>
                                ) : (
                                    <Link to={subscriptionBlockData?.banner_content?.terms?.href}>
                                        {subscriptionBlockData?.banner_content?.terms?.title}
                                    </Link>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <DesktopMobile
                breakpoint={600}
                desktop={() => desktopSubscription()}
                mobile={() => mobileSubscriptionBlock()}
            />
        </>
    );
};

SubscriptionBlock.propTypes = {
    item: shape({
        banner_content: shape({
            cta_button_background_color: string,
            cta_button_text_color: string,
            headline: string,
            headline_font_color: string,
            sub_headline: string,
            sub_headline_font_color: string,
        }),
        desktop_image: shape({
            title: string,
            url: string,
        }),
        link: shape({
            title: string,
            href: string,
        }),
    }),
    scrollToDisclaimer: func,
    trackEvent: func.isRequired,
};
SubscriptionBlock.defaultProps = {
    item: {},
    scrollToDisclaimer: () => {},
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(trackEventActions, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
);

export default enhance(SubscriptionBlock);
