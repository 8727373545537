/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const scrollToRef = (ref) => {
    if (window !== undefined) {
        window.scrollTo({
            top: ref.current.offsetTop - 100,
            left: 0,
            behavior: 'smooth',
        });
    }
};

export default scrollToRef;
