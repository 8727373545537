/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, number, bool, func,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    title: {
        position: 'absolute',
        textAlign: 'center',
        fontSize: '16px',
        color: theme.palette.copyright,
        width: '100%',
        bottom: '7px',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        [theme.breakpoints.down(1024)]: {
            fontSize: '14px',
        },
    },
    shopBtn: {
        display: 'block',
        padding: '0px 8px',
        border: '2px solid',
        width: '100px',
        margin: '0 auto 10px auto',
        fontSize: '14px',
        textTransform: 'uppercase',
        borderRadius: '4px',
    },
    bannerContainer: {
        border: `1px solid ${theme.palette.colorNeutral30}`,
        boxShadow: `0 5px 4px -2px  ${theme.palette.colorNeutral30}`,
        borderRadius: '3px',
        display: 'block',
        margin: '0px 3px',
        '& img': {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            marginBottom: '16px',
        },
    },
    bannerContainerMain: {
        width: '50%',
        height: '100%',
        position: 'relative',
        marginBottom: 16,
    },
});

const MobileBannersFF = ({
    classes,
    title,
    imgUrl,
    imgTitle,
    index,
    showOverlay,
    tileHandler,
    trackEvent,
}) => {
    const mobileBannerTileHandler = (tileTitle, idx) => {
        trackEvent({
            eventCategory: 'Jump Page Test',
            eventAction: `${tileTitle} click`,
            eventLabel: 'Tile click - event label',
        });
        tileHandler(idx);
    };

    return (
        <div className={classes.bannerContainerMain}>
            <div
                role="presentation"
                className={showOverlay ? classes.bannerContainer : classes.bannerContainer}
                onClick={() => {
                    mobileBannerTileHandler(
                        title,
                        index,
                    );
                }}
            >
                <img src={imgUrl} alt={imgTitle} />
                <div className={classes.title}>
                    {title}
                </div>
            </div>
        </div>
    );
};

MobileBannersFF.propTypes = {
    classes: object.isRequired,
    title: string.isRequired,
    imgUrl: string.isRequired,
    imgTitle: string.isRequired,
    index: number.isRequired,
    tileHandler: func.isRequired,
    showOverlay: bool.isRequired,
    trackEvent: func,
};

MobileBannersFF.defaultProps = {
    trackEvent: () => {},
};

export default withStyles(styles)(MobileBannersFF);
