/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, shape, string,  number,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import findCategoryById from '../../../../../../gql/queries/findCategoryById';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { GRAPHQL_ENV } from '../../../../../../gql';
import CarouselBuilder from '../../../../GraphqlCommonComponents/TrendingProducts/CarouselBuilder';
import { getSSRDeviceType } from '../../../../../../../state/ducks/App/App-Selectors';
import { getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useExperimentServiceAttributes from '../../../../../../helpers/experimentService/useExperimentServiceAttributes';

const CategoryProductTiles = ({
    classes, productData, brand, ssrDeviceType, presentationFamily,
}) => {
    const buttonStyle = {
        color: productData?.button_color?.color || '#ffffff',
        backgroundColor: productData?.button_background?.color || '#ed5d80',
    };
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
    const PRODUCTS_QUERY = findCategoryById(isGraphqlTargetingEnabled);
    const variables = {
        brand: brand?.domain,
        environment: GRAPHQL_ENV,
        locale: 'en-us',
        id: productData?.collection_code || '',
        productOptions: {
            pageSize: productData?.number_of_products || 4,
            page: 1,
        },
        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
    };
    const { data, loading, error } = useQuery(PRODUCTS_QUERY, { variables, context });
    if (loading) {
        return null;
    }
    if (error) {
        return null;
    }
    const products = data?.findCategoryById?.products || [];
    const sortPrice = (pData) => pData?.slice()?.sort((a, b) => a.value - b.value);
    const priceLayout = [
        {
            title: 'Mobile',
            viewport: 'Mobile',
            price_layout: 'from $xxx.xx',
            sale_text: '',
        },
    ];
    return (
        <Grid container className={classes.productTileMain}>
            <div className={classes.productBlock}>
                {ssrDeviceType === 'mobile'
                    ? (
                        <CarouselBuilder
                            products={products}
                            brand={brand?.domain}
                            title=""
                            nDisplayProducts={4}
                            isPdpSlider
                            // environment stuff
                            ssrDeviceType={ssrDeviceType}
                            presentationFamily={presentationFamily}
                            templateProductPriceLayout={priceLayout}
                        />
                    )
                    :                (
                        <>
                            <Grid container className={classes.productRow}>
                                {products?.map((product) => {
                                    const imagePath = `${product?.image?.path}${product?.image.name}x.jpg`;
                                    const url = product?.seo?.url;
                                    const lowestPrice = sortPrice(product?.prices);
                                    return  (
                                        <Grid item xs={5} key={product?.id}>
                                            <LinkOrATag href={url}>
                                                <ResponsiveImage path={imagePath} alt={product?.name} className={{}} params={{}} />
                                                <div>
                                                    <span className={classes.productName}>
                                                        {product?.name}
                                                    </span>
                                                    <div>
                                                        <span>from </span>
                                                        <strong>
                                                            {`$${lowestPrice?.[0]?.value}`}
                                                        </strong>
                                                    </div>
                                                </div>
                                            </LinkOrATag>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            {productData?.show_cta_button
                && (
                    <div className={classes.shopButton}>
                        <LinkOrATag style={buttonStyle} href={productData?.link?.href}>{productData?.link?.title}</LinkOrATag>
                    </div>
                )                }
                        </>
                    )                }
            </div>
        </Grid>

    );
};
CategoryProductTiles.propTypes = {
    classes: object.isRequired,
    productData: shape({
        link: shape({
            href: string,
        }),
        collection_code: string.isRequired,
        number_of_products: number.isRequired,
    }).isRequired,
    brand: shape({
        domain: string,
    }).isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
};
const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
    presentationFamily: getPresentationFamily(state),
});
export default  connect(mapStateToProps, null)(CategoryProductTiles);
