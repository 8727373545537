/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { shape, string } from 'prop-types';

const GiftBlockBottom = ({ image: { url = '', title = '' } = '' }) => {
    if (!url || !title) {
        return null;
    }
    return (
        <img src={url} alt={title} style={{ width: '100%', display: 'block' }} />
    );
};

GiftBlockBottom.propTypes = {
    image: shape({
        title: string.isRequired,
        url: string.isRequired,
    }).isRequired,
};

export default GiftBlockBottom;
